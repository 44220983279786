import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Keychain from '../keychain';
import { IS_WEB } from '../../constants/platform';
import { API_URI } from '../../constants/env';
import { AsyncStorage } from '../async-storage';
import jQuery from 'jquery';

/*
"user": {
  "token":"eyJ0eXAiOiJKV...",
  "card":"06028312050701",
  'password':'asdf',
  'webid':'webid',
  "member_number":"F0283LE",
  "status":"Active",
  'first_name': 'First',
  'last_name': 'Last',
  'phone': '1234',
  'owner': 'M',
  'type': 'OTHERS',
  'issued': '01/11/2009',
  'photo': 'base64',
  'signature': 'base64',
  'card_on_start': false,
  "email_wp":"asdf@asdf.com123456",
  "events":"1234",
  "promotions":"1234",
  "notices":"1234",
  'biometrics': false,
  'cart': {items: [{id: , title: , price: , qty: }], coupons: []}
}
*/

interface ICartItem {
  id: string;
  title: string;
  price: string;
  qty: number;
}

interface ICart {
  items: Array<ICartItem>;
  coupons: Array<any>;
}

export interface IUser {
  token: string;
  loggedin: boolean;
  card: string;
  password: string;
  webid: string;
  member_number: string;
  status: string;
  first_name: string;
  last_name: string;
  phone: string;
  owner: string;
  type: string;
  issued: string;
  photo: string;
  signature: string;
  card_on_start: boolean;
  email_wp: string;
  events: string;
  promotions: string;
  notices: string;
  biometrics: boolean;
  cart: ICart;
}

const initialUserState: { data: Partial<IUser> } = { data: {} };

export const user = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    loginUser: (state, action: PayloadAction<IUser>) => {
      const newUser = {
        ...state,
        ...action.payload,
        loggedin: true,
        cart: {
          items: [],
          coupons: [],
        },
        events: action.payload.events,
        promotions: action.payload.promotions,
        notices: action.payload.notices,
      };

      Keychain.setInternetCredentials('kcc', 'user', JSON.stringify(newUser));

      if (IS_WEB) {
        jQuery('body').addClass('member-logged-in');

        var date = new Date();
        date.setTime(date.getTime() + 1000 * 60 * 60 * 24 * 7);
        const expires = '; expires=' + date.toUTCString();
        document.cookie = 'token=' + newUser.token + expires + '; path=/';
      }

      if (!IS_WEB) {
        fcmRegister(newUser.email_wp);
      }

      state.data = newUser;
    },
    fcmUser: (_state, action: PayloadAction<string>) => {
      fcmRegister(action.payload);
    },
    updateUser: (state, action: PayloadAction<Partial<IUser>>) => {
      const newUser = { ...state, ...action.payload };

      newUser.cart && updateCartIcon(newUser.cart);
      Keychain.setInternetCredentials('kcc', 'user', JSON.stringify(newUser));

      state.data = newUser;
    },
    logoutUser: (state) => {
      if (IS_WEB) {
        jQuery('body').removeClass('member-logged-in');
        document.cookie =
          'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
      } else {
        AsyncStorage.getItem('fcmtoken').then(
          (fcmToken) => {
            AsyncStorage.removeItem('fcmtoken');

            Keychain.resetInternetCredentials('kcc');
            if (state.data.biometrics) {
              Keychain.getSupportedBiometryType().then((supported) => {
                if (supported && state.data.loggedin) {
                  Keychain.setInternetCredentials(
                    'biometrics',
                    'user',
                    JSON.stringify(state),
                    {
                      service: 'kcc',
                      accessControl: Keychain.ACCESS_CONTROL.BIOMETRY_ANY,
                      accessible:
                        Keychain.ACCESSIBLE.WHEN_PASSCODE_SET_THIS_DEVICE_ONLY,
                    }
                  );
                }
              });
            }

            if (fcmToken != null) {
              var formData = new FormData();
              formData.append('token', fcmToken);
              formData.append('os', 'Android');

              fetch(API_URI + '/pnfw/unregister/', {
                method: 'POST',
                body: formData,
              });
            }
          },
          () => {
            Keychain.resetInternetCredentials('kcc');
          }
        );
      }

      state.data = { loggedin: false };
    },
    addUserCard: (state, action: PayloadAction<ICartItem>) => {
      const cart: ICart = Object.create(state.data.cart || {});
      const prod = cart.items.find((item) => item.id === action.payload.id);
      if (prod) {
        prod.qty++;
      } else {
        cart.items.push(action.payload);
      }

      updateCartIcon(cart);

      const newUser: Partial<IUser> = { ...state, cart };
      Keychain.setInternetCredentials('kcc', 'user', JSON.stringify(newUser));

      // firebase.analytics?.().logEvent('add_to_cart', {
      //   currency: 'HKD',
      //   value: action.payload.price,
      //   items: [
      //     {
      //       item_id: action.payload.id,
      //       item_name: action.payload.title,
      //       price: action.payload.price,
      //     },
      //   ],
      // });

      state.data = newUser;
    },
  },
});

async function fcmRegister(_email: string) {
  // firebase.messaging().requestPermission();
  // let fcmToken = null;
  // try {
  //   fcmToken = await AsyncStorage.getItem('fcmtoken');
  // } catch (error) {}
  // if (fcmToken == null)
  //   firebase
  //     .messaging()
  //     .getToken()
  //     .then(async token => fcmUpdateToken(token, email));
  // firebase.messaging().onTokenRefresh(token => fcmUpdateToken(token, email));
}

// function fcmUpdateToken(token: string | undefined, email: string) {
//   if (token !== undefined) {
//     var formData = new FormData();
//     formData.append('token', token);
//     formData.append('email', email);
//     formData.append('os', 'Android'); //For iOS to receive FCM, otherwise: Platform == 'ios' ? 'iOS' : 'Android',

//     fetch(API_URI + '/pnfw/register/', {
//       method: 'POST',
//       body: formData,
//     }).then(response => {
//       if (response.status === 200) AsyncStorage.setItem('fcmtoken', token);
//     });
//   }
// }

function updateCartIcon(cart: ICart) {
  var countRegular = cart.items.reduce(
    (prev, cur: any) =>
      !cur.categories || cur.categories[0].slug !== 'wine-delivery'
        ? prev + cur.qty
        : prev,
    0
  );
  var countWine = cart.items.reduce(
    (prev, cur: any) =>
      cur.categories && cur.categories[0].slug === 'wine-delivery'
        ? prev + cur.qty
        : prev,
    0
  );

  if (IS_WEB) {
    jQuery('#header-buttons .cart-contents').remove();
    jQuery('#header-buttons').prepend(`
      <a class="cart-contents" href="/members-area/#/app/shop_cart" title="View your shopping cart">
        <i class="fas fa-shopping-cart"></i>
        <span class="count">${countRegular}</span>
      </a>
    `);

    if (countWine) {
      jQuery('#header-buttons').prepend(`
        <a class="cart-contents" href="/members-area/#/app/shop_cart/wine-delivery" title="View your shopping cart">
          <i class="fas fa-wine-glass-alt"></i>
          <span class="count">${countWine}</span>
        </a>
      `);
    }
  }
}

export const { loginUser, addUserCard, fcmUser, logoutUser, updateUser } =
  user.actions;
