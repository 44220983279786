import {
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { NoticeDetailScreenProps } from './types';
import PageTitle from '../../components/PageTitle';
import { STYLE_VARS } from '../../constants/style-vars';
import { Button } from '../../components/Button';
import RenderHTML from 'react-native-render-html';
import { Footer } from '../../components/Footer';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { INotice } from '../../types/notices.types';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';

const NoticeDetail: FC<NoticeDetailScreenProps> = ({ navigation, route }) => {
  const isDesktop = useIsDesktop();

  const renderNotice = useMemo(() => {
    const notice: INotice = {
      id: 1,
      title: 'New Chief Executive Officer Appointed',
      date: 'Wed 21st August',
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      img: require('../../assets/notices_image/image-1.jpeg'),
    };

    return (
      <>
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 20,
            letterSpacing: 1.7,
            color: COLORS.BLACK,
            textTransform: 'uppercase',
            borderBottomWidth: 1,
            borderBottomColor: COLORS.GREY,
            paddingBottom: 15,
            marginBottom: 25,
            textAlign: isDesktop() ? 'center' : 'left',
          }}
        >
          {notice.title}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 15,
          }}
        >
          <View style={{ width: isDesktop() ? 24 : 29 }} />
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 16,
                color: COLORS.GREY,
                marginBottom: 12,
              }}
            >
              {notice.date}
            </Text>
            <View
              style={{
                width: 58,
                height: 2,
                backgroundColor: COLORS.RED_PRIMARY,
                marginBottom: 10,
              }}
            />
          </View>
          <TouchableOpacity>
            <Image
              source={require('../../assets/share-icon.png')}
              style={[
                {
                  tintColor: COLORS.RED_PRIMARY,
                },
                isDesktop()
                  ? { width: 24, height: 24 }
                  : {
                      width: 29,
                      height: 29,
                    },
              ]}
            />
          </TouchableOpacity>
        </View>
        <Image
          source={notice.img}
          style={{
            width: '100%',
            height: isDesktop() ? 433 : 225,
            borderRadius: 18,
            marginBottom: 20,
          }}
        />
        <Text
          style={{
            fontFamily: FONTS.AvenirRegular,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
          }}
        >
          {notice.content}
        </Text>
      </>
    );
  }, [isDesktop]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Notices" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  paddingBottom: 40,
                }}
              >
                {renderNotice}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'NOTICES'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 40,
          paddingVertical: 40,
          backgroundColor: '#F9F1F1',
        }}
      >
        {renderNotice}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default NoticeDetail;
