import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { DropDownItem } from '../../models/DropDownItem';
import { COLORS, FONTS } from '../../constants';

interface RadioButtonProps {
  options: DropDownItem[];
  selectedOption: string;
  onSelect: (value: string | number) => void;
  radioStyle?: ViewStyle;
  optionStyle?: ViewStyle;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  options,
  selectedOption,
  onSelect,
  radioStyle,
  optionStyle,
}) => {
  return (
    <View style={[styles.container, radioStyle]}>
      {options.map((option) => (
        <TouchableOpacity
          key={option.value}
          style={[styles.optionContainer, optionStyle]}
          onPress={() => onSelect(option.value)}
        >
          <View style={styles.circle}>
            {selectedOption === option.value && (
              <View style={styles.checkedCircle} />
            )}
          </View>
          <Text style={styles.optionText}>{option.label}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 60,
  },
  circle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    backgroundColor: '#F5F5FA',
  },
  checkedCircle: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: COLORS.RED_PRIMARY,
  },
  optionText: {
    fontSize: 16,
    color: '#27272A',
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
  },
});

export default RadioButton;
