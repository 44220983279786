import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRoute {
  name: string;
  params?: any;
}

const initialState: IRoute = {
  name: 'Root',
  params: undefined,
};

export const route = createSlice({
  name: 'route',
  initialState: initialState,
  reducers: {
    updateRoute: (state, action: PayloadAction<IRoute>) => {
      state.name = action.payload.name;
      state.params = action.payload.params;
    },
  },
});

export const { updateRoute } = route.actions;
