import { Text, TouchableOpacity, View } from 'react-native';
import React, { FC } from 'react';
import { Image } from '../components/Image';
import { NavigationService } from '../services/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux';
import { COLORS, FONTS } from '../constants';

const HeaderMobileRight: FC = () => {
  const auth = useSelector((redux: RootState) => redux.authReducer);
  const { itemNum } = useSelector((redux: RootState) => redux.cartReducer);

  if (!auth.loggedin) return <></>;

  return (
    <View
      style={{ flexDirection: 'row', alignItems: 'center', gap: 20 }}
    ></View>
  );
};

export default HeaderMobileRight;
