import React, { FC } from 'react';
import {
  TextStyle,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from 'react-native';
import { authStyles } from '../styles';
import { IAuthProps } from '../types';
import { Text } from '../../../components/Text/Text';
import { TextInput } from '../../../components/TextInput/TextInput';
import { getContentWidth, getHeight } from '../../../constants/platform';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';

export const Password: FC<IAuthProps> = ({ state, setState, onLoginPress }) => {
  const isDesktop = useIsDesktop();

  return (
    <View style={authStyles.container}>
      <Text
        style={[
          authStyles.title,
          { textAlign: isDesktop() ? 'left' : 'center' },
        ]}
      >
        A SECURITY CODE HAS BEEN SENT TO YOUR EMAIL ADDRESS. PLEASE ENTER IT
        BELOW AND SET UP A NEW PASSWORD.
      </Text>
      <TextInput
        style={
          (isDesktop()
            ? STYLE_VARS.textinputweb
            : STYLE_VARS.textinput) as TextStyle
        }
        value={state.code}
        onChangeText={(text) => setState({ code: text })}
        placeholder="SECURITY CODE"
        placeholderTextColor="grey"
      />
      <TextInput
        style={[
          isDesktop() ? STYLE_VARS.textinputweb : STYLE_VARS.textinput,
          authStyles.inputWithNote,
        ]}
        secureTextEntry={true}
        value={state.password}
        onChangeText={(text) => setState({ password: text })}
        onSubmitEditing={onLoginPress}
        placeholder="PASSWORD"
        placeholderTextColor="grey"
      />

      <Text
        style={
          state.password.length >= 8
            ? authStyles.pwdRuleOk
            : authStyles.pwdRuleNot
        }
      >
        {state.password.length >= 8 ? '✓ ' : '✕ '}
        At least 8 characters
      </Text>

      <Text
        style={
          /[A-Z]/.test(state.password)
            ? authStyles.pwdRuleOk
            : authStyles.pwdRuleNot
        }
      >
        {/[A-Z]/.test(state.password) ? '✓ ' : '✕ '}
        At least one capital letter
      </Text>

      <Text
        style={
          /[0-9\W_]/.test(state.password)
            ? authStyles.pwdRuleOk
            : authStyles.pwdRuleNot
        }
      >
        {/[0-9\W_]/.test(state.password) ? '✓ ' : '✕ '}
        Contains a number or a special character
      </Text>

      <Text> </Text>

      <TextInput
        style={[
          isDesktop() ? STYLE_VARS.textinputweb : STYLE_VARS.textinput,
          authStyles.inputWithNote,
        ]}
        secureTextEntry={true}
        value={state.password2}
        onChangeText={(text) => setState({ password2: text })}
        onSubmitEditing={onLoginPress}
        placeholder="CONFIRM PASSWORD"
        placeholderTextColor="grey"
      />

      <Text
        style={
          state.password === state.password2
            ? authStyles.pwdRuleOk
            : authStyles.pwdRuleNot
        }
      >
        {state.password === state.password2 ? '✓ ' : '✕ '}
        Passwords match
      </Text>

      <Text> </Text>

      <View
        style={[
          authStyles.submitwrap,
          { width: isDesktop() ? 300 : undefined },
        ]}
      >
        <Button
          text="SUBMIT"
          style={{
            paddingVertical: 12,
            paddingHorizontal: 24,
            alignSelf: isDesktop() ? 'flex-start' : 'center',
          }}
          onPress={onLoginPress}
          disabled={state.submitDisabled}
        />
      </View>

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({ mode: 'login' });
        }}
      >
        <Text>LOGIN</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({
            mode: 'create',
            mbnum: '',
            email: '',
            login: '',
            password: '',
            password2: '',
          });
        }}
      >
        <Text>SIGN UP</Text>
      </TouchableOpacity>

      {state.submitDisabled && (
        <View
          style={[
            authStyles.activityIndWrap,
            {
              width:
                isDesktop() && getContentWidth() >= 768
                  ? 340
                  : getContentWidth(),
              height:
                isDesktop() && getContentWidth() >= 768 ? 320 : getHeight(),
            },
          ]}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
};
