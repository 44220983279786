import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useRef, useState } from 'react';
import { SidebarProps } from './Sidebar.types';
import { Image } from '../Image';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux';
import { NavigationService } from '../../services/navigation';

const Sidebar: FC<SidebarProps> = ({ data, current, handlePressItem }) => {
  const auth = useSelector((redux: RootState) => redux.authReducer);
  const [hover, setHover] = useState<string>('');
  const handleMouseEnter = (title: string) => {
    // setHover(true);
    setHover(title);
  };

  const handleMouseLeave = () => {
    setHover('');
  };

  return (
    <View>
      {data.map((item, index, arr) => (
        <div
          onMouseEnter={() => {
            handleMouseEnter(item.title);
          }}
          className={current !== index ? 'slidebar-item' : ''}
          onMouseLeave={handleMouseLeave}
          key={index}
        >
          <TouchableOpacity
            key={item.title}
            style={[
              {
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: current === index ? '#FE405D' : undefined,
                paddingTop: 20,
                paddingBottom: 15,
                paddingStart: 25,
              },
            ]}
            onPress={() => {
              if (current !== index) {
                if (item.requiredLogin && !auth.loggedin) {
                  NavigationService.push('Login');
                } else {
                  if (item.onPress) item.onPress();
                }
              }
              if (handlePressItem) handlePressItem();
            }}
          >
            <Image
              source={item.icon}
              style={{
                width: 35,
                height: 32,
                tintColor: 'white',
                resizeMode: 'contain',
              }}
            />
            <Text
              style={{
                color: 'white',
                marginLeft: 20,
                textTransform: 'uppercase',
              }}
            >
              {item.title}
            </Text>
          </TouchableOpacity>
        </div>
      ))}
    </View>
  );
};

export default Sidebar;

const styles = StyleSheet.create({});
