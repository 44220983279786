import React, { FC, useMemo, useRef, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  Animated,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
//import { FontAwesome } from '@expo/vector-icons'; // Assuming you use FontAwesome icons, you may need to install it

const ArrowButton: FC<{ arrow?: 'left' | 'right'; onPress: () => void }> = ({
  arrow = 'left',
  onPress,
}) => {
  const isDesktop = useIsDesktop();
  const [isHover, setHover] = useState<boolean>(false);
  const progress = useRef(new Animated.Value(0)).current;
  const handleMouseEnter = () => {
    setHover(true);
    Animated.timing(progress, {
      toValue: 1,
      duration: 500,
      useNativeDriver: false,
    }).start();
  };

  const handleMouseLeave = () => {
    setHover(false);
    Animated.timing(progress, {
      toValue: 0,
      duration: 0,
      useNativeDriver: false,
    }).start();
  };

  const renderContent = useMemo(() => {
    return (
      <TouchableOpacity
        onPress={() => {
          onPress();
        }}
      >
        <Image
          source={
            arrow == 'left'
              ? require('../../assets/left-arrow.png')
              : require('../../assets/right-arrow.png')
          }
          style={{
            width: 16,
            height: 16,
            tintColor: isDesktop()
              ? isHover
                ? COLORS.RED_PRIMARY
                : COLORS.GREY
              : COLORS.BLACK,
            marginHorizontal: isDesktop() ? 20 : 8,
          }}
        />
      </TouchableOpacity>
    );
  }, [arrow, isDesktop, isHover, onPress]);

  if (isDesktop()) {
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {renderContent}
      </div>
    );
  }

  return <>{renderContent}</>;
};

const MonthSwitcher = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const isDesktop = useIsDesktop();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => {
      const prevMonth = prevDate.getMonth() - 1;
      return new Date(prevDate.setMonth(prevMonth));
    });
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => {
      const nextMonth = prevDate.getMonth() + 1;
      return new Date(prevDate.setMonth(nextMonth));
    });
  };

  return (
    <View
      style={[
        styles.container,
        !isDesktop() && { backgroundColor: COLORS.WHITE },
      ]}
    >
      <ArrowButton
        onPress={() => {
          goToPreviousMonth();
        }}
      />
      <Text
        style={[styles.monthYearText, !isDesktop() && { color: COLORS.BLACK }]}
      >{`${month} ${year}`}</Text>
      <ArrowButton
        onPress={() => {
          goToNextMonth();
        }}
        arrow="right"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: COLORS.LIGHT_GREY,
    borderRadius: 8,
  },
  monthYearText: {
    fontSize: 18,
    color: COLORS.GREY,
    fontFamily: FONTS.AvenirMedium,
  },
});

export default MonthSwitcher;
