import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { AsyncStorage } from '../async-storage';
import { route } from './route';
import { user } from './user';
import { persistStore, persistReducer } from 'redux-persist';
import noticeReducer from './reducers/NoticeSlice';
import promotionReducer from './reducers/PromotionSlice';
import eventReducer from './reducers/EventSlice';
import dateReducer from './reducers/DateSlice';
import weatherReducer from './reducers/WeatherSlice';
import cartReducer from './reducers/CartSlice';
import newsReducer from './reducers/NewsSlice';
import dashboardReducer from './reducers/DashboardSlice';
import eShopCategoryReducer from './reducers/EShopCategorySlice';
import eShopProductReducer from './reducers/EShopProductSlice';
import authReducer from './reducers/AuthSlice';
import firstDownloadSlice from './reducers/FirstDownloadSlice';
import switchECardSlice from './reducers/SwitchECardSlice';

const rootReducer = combineReducers({
  noticeReducer,
  promotionReducer,
  eventReducer,
  dateReducer,
  weatherReducer,
  cartReducer,
  newsReducer,
  dashboardReducer,
  eShopCategoryReducer,
  eShopProductReducer,
  authReducer,
  firstDownloadSlice,
  switchECardSlice,
  user: user.reducer,
  route: route.reducer,
});

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
