import { StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';

export const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: 'white',
    flexDirection: 'row',
    borderColor: COLORS.RED_PRIMARY,
    borderWidth: 1,
    borderRadius: 0,
    paddingTop: 12,
    paddingBottom: 12,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  textStyled: {
    color: COLORS.RED_PRIMARY,
    fontFamily: FONTS.AvenirMedium,
    fontSize: 14,
    fontWeight: '500',
  },
  dropDownContainer: {
    flex: 1,
    position: 'absolute',
    top: 50,
    maxHeight: 150,
    backgroundColor: 'white',
    minHeight: 50,
    width: '100%',
    borderWidth: 1,
    borderColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  headerModalWrapper: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginBottom: 20,
    borderBottomColor: COLORS.DARK_GREY,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemWrapper: {
    paddingVertical: 20,
    borderBottomColor: COLORS.DARK_GREY,
    borderBottomWidth: 0.5,
  },
});
