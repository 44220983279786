import {
  Button,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Category, EShopCategoryProps } from './EshopCategory.type';
import styles from './EshopCategory.style';
import { NavigationService } from '../../../../services/navigation';
import { CATEGORY_TYPE } from '../../constant';
import { FC } from 'react';
import { STYLE_VARS } from '../../../../constants/style-vars';
import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../services/redux';

const categories: Category[] = [
  {
    name: CATEGORY_TYPE.FOOD,
    pathName: '',
  },
  {
    name: CATEGORY_TYPE.BEVERAGES,
    pathName: '',
  },
  {
    name: CATEGORY_TYPE.SPORT_ACCESSORIES,
    pathName: '',
  },
  {
    name: CATEGORY_TYPE.CLOTHING,
    pathName: '',
  },
  {
    name: CATEGORY_TYPE.HEALTH,
    pathName: '',
  },
  {
    name: CATEGORY_TYPE.MERCHANDISE,
    pathName: '',
  },
];

const EShopCategory: FC<EShopCategoryProps> = ({
  onSelectedCategory,
  selected,
}) => {
  const route = useRoute();

  const { categories } = useSelector(
    (state: RootState) => state.eShopCategoryReducer
  );

  return (
    <View style={styles.border}>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal
        style={styles.wrapper}
      >
        <View style={styles.container}>
          {categories.map((category, index) => {
            return (
              <TouchableOpacity
                key={category.id}
                style={{
                  ...styles.itemContainer,
                  backgroundColor:
                    selected === category.slug
                      ? STYLE_VARS.primaryColor
                      : STYLE_VARS.fccGrayBg,
                }}
                onPress={() => {
                  if (route.name === 'EShopCategoryPage') {
                    onSelectedCategory && onSelectedCategory(category.slug);
                    return;
                  }
                }}
              >
                <Text
                  style={{
                    ...styles.itemText,
                    color:
                      selected === category.slug
                        ? STYLE_VARS.fccWhite
                        : STYLE_VARS.primaryColor,
                  }}
                >
                  {category.name}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </ScrollView>
    </View>
  );
};

export default EShopCategory;
