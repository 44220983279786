import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useMemo } from 'react';
import { DayProps } from './Day.types';
import { COLORS, FONTS } from '../../../../constants';
import dayjs from 'dayjs';

const Day: FC<DayProps> = ({
  current,
  date,
  initDate,
  onPress,
  emptyDate = false,
}) => {
  const fullDate = useMemo(() => {
    if (date && current) {
      const day = date < 10 ? `0${date}` : date.toString();
      const fullDate = `${dayjs(current).format('YYYY-MM')}-${day}`;
      return fullDate;
    } else return '';
  }, [date, current]);
  const currentDate = dayjs(new Date()).format('YYYY-MM-DD');

  const handleSelectDate = () => {
    if (onPress) onPress(fullDate);
  };

  if (emptyDate) {
    return (
      <Text style={[styles.dayButton, { backgroundColor: COLORS.WHITE }]} />
    );
  }

  if (initDate && initDate === fullDate) {
    return (
      <TouchableOpacity
        style={[
          styles.dayButton,
          {
            backgroundColor: COLORS.RED_PRIMARY,
          },
        ]}
        onPress={handleSelectDate}
      >
        <Text style={[styles.dayText, { color: COLORS.WHITE }]}>{date}</Text>
      </TouchableOpacity>
    );
  }
  return (
    <TouchableOpacity
      style={[
        styles.dayButton,
        currentDate === fullDate && { backgroundColor: COLORS.WHITE },
      ]}
      onPress={handleSelectDate}
    >
      <Text
        style={[
          styles.dayText,
          currentDate === fullDate && { color: COLORS.RED_PRIMARY },
        ]}
      >
        {date}
      </Text>
    </TouchableOpacity>
  );
};

export default Day;

const styles = StyleSheet.create({
  dayButton: {
    flex: 1,
    height: 33,
    padding: 4,
    borderRadius: 3,
    backgroundColor: COLORS.WHITE,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayText: {
    fontSize: 13,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    color: COLORS.DARKER_GREY,
    textAlign: 'center',
  },
});
