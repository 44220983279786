import React, { FC, useMemo } from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { Footer } from '../../components/Footer';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import ContactUsForm from './components/ContactUsForm';
import { Button } from '../../components/Button';

const ContactUs: FC = () => {
  const isDesktop = useIsDesktop();

  const renderInformation = useMemo(() => {
    const items = [
      {
        icon: require('../../assets/address-white.png'),
        text: 'Nin Wah Road, Cheung Muk Tau North, Sai Kung, New Territories',
      },
      {
        icon: require('../../assets/phone-white.png'),
        text: '(852) 2640 8810',
      },
      {
        icon: require('../../assets/email-white.png'),
        text: 'holidaycentre@helpinghand.org.hk',
      },
      {
        icon: require('../../assets/fax-white.png'),
        text: '(852) 2640 7990',
      },
    ];

    if (isDesktop()) {
      return (
        <View
          style={{
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 60,
            marginBottom: 30,
          }}
        >
          <View
            style={{ flexDirection: 'row', marginBottom: 20, width: '40%' }}
          >
            <Image
              source={require('../../assets/address-white.png')}
              style={styles.infoIcon}
            />
            <Text style={styles.infoText}>
              {'Nin Wah Road, Cheung Muk Tau North, Sai Kung, New Territories'}
            </Text>
          </View>
          <View
            style={{ flexDirection: 'row', marginBottom: 20, width: '40%' }}
          >
            <Image
              source={require('../../assets/phone-white.png')}
              style={styles.infoIcon}
            />
            <Text style={styles.infoText}>{'(852) 2640 8810'}</Text>
          </View>
          <View
            style={{ flexDirection: 'row', marginBottom: 20, width: '40%' }}
          >
            <Image
              source={require('../../assets/email-white.png')}
              style={styles.infoIcon}
            />
            <Text style={styles.infoText}>
              {'holidaycentre@helpinghand.org.hk'}
            </Text>
          </View>
          <View
            style={{ flexDirection: 'row', marginBottom: 20, width: '40%' }}
          >
            <Image
              source={require('../../assets/fax-white.png')}
              style={styles.infoIcon}
            />
            <Text style={styles.infoText}>{'(852) 2640 7990'}</Text>
          </View>
        </View>
      );
    }

    return (
      <View
        style={{
          flexWrap: 'wrap',
          flexDirection: 'column',
          marginBottom: 30,
          alignItems: 'center',
          paddingHorizontal: 10,
        }}
      >
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Image
            source={require('../../assets/address-white.png')}
            style={styles.infoIcon}
          />
          <Text style={styles.infoText}>
            {'Nin Wah Road, Cheung Muk Tau North, Sai Kung, New Territories'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Image
            source={require('../../assets/email-white.png')}
            style={styles.infoIcon}
          />
          <Text style={styles.infoText}>
            {'holidaycentre@helpinghand.org.hk'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Image
            source={require('../../assets/phone-white.png')}
            style={styles.infoIcon}
          />
          <Text style={styles.infoText}>{'(852) 2640 8810'}</Text>
        </View>

        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Image
            source={require('../../assets/fax-white.png')}
            style={styles.infoIcon}
          />
          <Text style={styles.infoText}>{'(852) 2640 7990'}</Text>
        </View>
      </View>
    );
  }, [isDesktop]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Contact Us" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 20,
                    textAlign: 'center',
                    color: COLORS.BLACK,
                    textTransform: 'uppercase',
                    marginBottom: 30,
                  }}
                >
                  {
                    'Helping Hand Cheung Muk Tau Holiday Centre\n for the Elderly'
                  }
                </Text>
                {renderInformation}
                <ContactUsForm />
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'CONTACT US'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: COLORS.LIGHT_RED,
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 20,
            textAlign: 'center',
            color: COLORS.BLACK,
            textTransform: 'uppercase',
            marginBottom: 30,
          }}
        >
          {'Helping Hand Cheung Muk Tau Holiday Centre for the Elderly'}
        </Text>
        {renderInformation}
        <ContactUsForm />
      </View>
    </ScrollView>
  );
};

export default ContactUs;

const styles = StyleSheet.create({
  infoIcon: {
    width: 20,
    height: 20,
    tintColor: COLORS.BLACK,
    resizeMode: 'contain',
    marginRight: 6,
  },
  infoText: {
    fontFamily: FONTS.AvenirRegular,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.BLACK,
  },
});
