import { store } from '../redux/store';
import { logoutUser } from '../redux/user';

import { Alert } from '../alert';

let _headers: HeadersInit & { Authorization?: string } = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Accept: 'application/json',
};

export const get = (
  url: RequestInfo,
  signal?: AbortSignal,
  nocache = false
) => {
  const headers = {
    ..._headers,
    Authorization: `Bearer ${store.getState().user.data.token}`,
  };
  return fetch(url, {
    headers,
    signal,
    cache: nocache ? 'no-cache' : 'default',
  })
    .then(function (response) {
      if (response.status === 403 || response.status === 401) {
        store.dispatch(logoutUser());
      }

      if (!response.ok) {
        throw response;
      }

      if (
        response.headers.get('Content-Type')?.startsWith('application/json')
      ) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then(function (json) {
      return json;
    })
    .catch((error) => {
      if (error.json) {
        error.json().then((err: any) => {
          Alert.alert(err.code, err.message);
        });
      }

      throw error;
    });
};

export const post = (
  url: string,
  body?: Record<string, any> | string,
  asForm?: boolean
) => {
  const headers = Object.create(_headers);

  if (asForm) {
    var formData = new FormData();
    Object.keys(body as Record<string, any>).forEach((key) =>
      formData.append(key, (body as Record<string, any>)[key])
    );
    body = formData;
  } else {
    body = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }

  headers.Authorization = `Bearer ${store.getState().user.data.token}`;

  return fetch(url, {
    method: 'POST',
    headers: asForm ? undefined : headers,
    body: body as BodyInit,
  })
    .then(function (response) {
      if (response.status === 403 || response.status === 401) {
        store.dispatch(logoutUser());
      }

      if (!response.ok) {
        throw response;
      }

      if (
        response.headers.get('Content-Type')?.startsWith('application/json')
      ) {
        return response.json();
      } else {
        return response.text();
      }
    })
    .then(function (json) {
      return json;
    })
    .catch((error) => {
      if (error.json) {
        error.json().then((err: any) => {
          Alert.alert(err.code, err.message);
        });
      }

      throw error;
    });
};
