import { FC, useCallback, useState } from 'react';
import { ProductWooItemTableProps } from './ProductWooItemTable.types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { Button } from '../../../../components/Button';
import { STYLE_VARS } from '../../../../constants/style-vars';
import { styles } from './ProductWooItemTable.styles';
import { COLORS, FONTS } from '../../../../constants';
import { NavigationService } from '../../../../services/navigation';
import RenderHTML from 'react-native-render-html';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/redux';
import { IProduct } from '../../../../models/IProduct';
import {
  addToCart,
  updateCart,
} from '../../../../services/redux/reducers/CartSlice';
import { Alert } from '../../../../services/alert';

const ProductWooItemTable: FC<ProductWooItemTableProps> = ({
  product,
  haveImage = false,
  type,
  categoryName,
}) => {
  const [count, setCount] = useState<number>(1);
  const { items } = useSelector((redux: RootState) => redux.cartReducer);
  const dispatch = useDispatch();

  const handleAddCart = useCallback(() => {
    let isAdded = false;

    if (items.filter((e) => e.id === product.id).length > 0) {
      isAdded = true;
    }

    if (isAdded) {
      const newCartData: IProduct[] = items.map((data) => {
        if (data.id === product.id) {
          return {
            ...data,
            amount: data.amount + count,
          };
        }
        return data;
      });
      dispatch(updateCart(newCartData));
    } else {
      dispatch(
        addToCart({
          id: product.id,
          photo: product.images[0]?.src || '',
          totalAmount: count,
          amount: count,
          price: parseFloat(product.price),
          type: categoryName ?? '',
          productName: product.name,
        })
      );
    }

    Alert.alert('Product added to your cart!', '', [
      {
        text: 'Go to Checkout',
        onPress: () => {},
      },
      {
        text: 'Continue Shopping',
        onPress: () => {},
      },
    ]);
  }, [product, count, items, categoryName, dispatch]);

  const handleChangeAmount = useCallback(
    (type: 'increase' | 'decrease') => {
      if (type === 'decrease' && count > 1) {
        setCount(count - 1);
      }

      if (
        type === 'increase'
        // && product.stock_status !== 'outofstock'
        // && product.stock_quantity > count
      ) {
        setCount(count + 1);
      }
    },
    [count]
  );

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: 20,
        },
        type == 'even' && {
          backgroundColor: '#f9f9f9',
        },
      ]}
    >
      {haveImage && (
        <View
          style={{
            width: '20%',
            paddingHorizontal: 15,
          }}
        >
          <Image
            source={{ uri: product.images[0]?.src }}
            style={{
              width: '100%',
              height: 160,
              borderWidth: 1,
              borderColor: COLORS.DARK_GREY,
            }}
          />
        </View>
      )}
      <View
        style={{
          width: '20%',
          paddingHorizontal: 5,
        }}
      >
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          <TouchableOpacity onPress={() => {}}>
            <Text
              style={[
                styles.title,
                {
                  fontSize: haveImage ? 16 : 18,
                  letterSpacing: 1,
                  marginBottom: 10,
                },
              ]}
            >
              {product.name}
            </Text>
          </TouchableOpacity>
          <View
            style={{
              paddingHorizontal: 10,
            }}
          >
            <Button
              text={'VIEW MORE'}
              style={STYLE_VARS.fullWGreenButton}
              onPress={() => {}}
            />
          </View>
        </View>
      </View>
      <View
        style={{
          width: haveImage ? '25%' : '35%',
        }}
      >
        <Text
          style={[styles.description, { fontSize: haveImage ? 13 : 16 }]}
          numberOfLines={3}
        >
          <RenderHTML
            source={{ html: product.short_description }}
            tagsStyles={{
              p: {
                marginTop: 0,
                fontFamily: FONTS.AvenirMedium,
                color: STYLE_VARS.fccDarkerGrey,
                fontWeight: '500',
                fontSize: haveImage ? 13 : 16,
              },
            }}
          />
        </Text>
      </View>
      <View
        style={{
          width: haveImage ? '10%' : '15%',
        }}
      >
        <Text
          style={[
            styles.price,
            {
              textAlign: 'left',
              fontSize: haveImage ? 16 : 18,
            },
          ]}
        >
          ${product.price}
        </Text>
      </View>
      <View
        style={{
          width: '15%',
          flexDirection: 'column',
          paddingHorizontal: haveImage ? 5 : 0,
        }}
      >
        <View
          style={[
            styles.countWrapper,
            {
              justifyContent: 'center',
              marginBottom: 10,
            },
          ]}
        >
          <View style={styles.count}>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.countBtn}
              onPress={() => {
                handleChangeAmount('decrease');
              }}
            >
              <Text style={styles.countBtnText}>-</Text>
            </TouchableOpacity>
            <Text style={styles.countText}>{count}</Text>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.countBtn}
              onPress={() => {
                handleChangeAmount('increase');
              }}
            >
              <Text style={styles.countBtnText}>+</Text>
            </TouchableOpacity>
          </View>
        </View>
        <Button
          text={'ADD TO CART'}
          style={STYLE_VARS.fullWGreenButton}
          onPress={() => {
            handleAddCart();
          }}
        />
      </View>
    </View>
  );
};

export default ProductWooItemTable;
