import { FC } from 'react';
import { Image, ScrollView, Text, View } from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { IActivities } from '../../types/activities.types';
import ActivityItem from './componentns/ActivityItem';
import { Footer } from '../../components/Footer';
import { useIsDesktop } from '../../hooks/useIsDeskTop';

const activities: IActivities[] = [
  {
    id: 1,
    title: 'Day Camp',
    date: '22 June 2024',
    img: require('../../assets/activities_image/image-1.png'),
    name: 'Capoeira',
    other: 'Every Thursday',
    hour: '10:00 a.m. - 4:15 p.m.',
    place: 'Activity Room 1',
  },
  {
    id: 2,
    title: 'Evening Camp',
    date: '29 June 2024',
    img: require('../../assets/activities_image/image-1.png'),
    name: 'Chess Club',
    other: 'Every Wednesday',
    hour: '3:30 p.m. ~ 5:15 p.m.',
    place: 'Activity Room 2',
  },
  {
    id: 3,
    title: 'Half Day Camp',
    date: '29 June 2024',
    img: require('../../assets/activities_image/image-1.png'),
    name: 'Knitting',
    other: 'Every Wednesday',
    hour: '4:00 p.m. ~ 5:30 p.m.',
    place: 'Activity Room 3',
  },
  {
    id: 1,
    title: 'Overnight Camp',
    date: '29 June 2024',
    img: require('../../assets/activities_image/image-1.png'),
    name: 'Digital Art Class',
    other: 'Every Thursday',
    hour: '10:00 a.m. - 4:15 p.m.',
    place: 'Activity Room 4',
  },
];

const MyActivities: FC = () => {
  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="My Activities" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                }}
              >
                <View style={{ flexDirection: 'column' }}>
                  {activities.map((item, index) => {
                    return (
                      <View
                        style={{
                          marginBottom: 30,
                        }}
                      >
                        <ActivityItem item={item} />
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'MY ACTIVITIES'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 40,
          paddingVertical: 40,
          backgroundColor: '#F9F1F1',
        }}
      >
        <View style={{ flexDirection: 'column' }}>
          {activities.map((item, index) => {
            return (
              <View
                style={{
                  marginBottom: 30,
                }}
              >
                <ActivityItem item={item} />
              </View>
            );
          })}
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default MyActivities;
