import React, { FC, ReactNode, useMemo, useRef, useState } from 'react';
import {
  ImageStyle,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';
import { STYLE_VARS } from '../../constants/style-vars';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { Image } from '../Image';
import { Text } from '../Text';

interface IButton extends TouchableOpacityProps {
  text?: string;
  textStyle?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  arrowStyle?: StyleProp<ImageStyle>;
  className?: string;
  arrowRight?: boolean;
  children?: ReactNode;
  component?: ReactNode;
}

export const Button: FC<IButton> = ({
  text,
  style,
  textStyle,
  arrowStyle,
  className = 'red-button',
  arrowRight = true,
  component,
  children,
  ...props
}) => {
  const isDesktop = useIsDesktop();
  if (isDesktop()) {
    return (
      <div className={className}>
        <TouchableOpacity
          style={[
            className != '' && { backgroundColor: 'transparent' },
            {
              borderRadius: 6,
              alignItems: 'center',
              alignSelf: 'center',
            },
            style,
          ]}
          {...props}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {children ? (
              children
            ) : (
              <Text
                style={[
                  {
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 12,
                  },
                  textStyle,
                ]}
              >
                {text}
              </Text>
            )}
            {arrowRight && (
              <Image
                source={require('../../assets/right-arrow.png')}
                style={[
                  {
                    width: 8,
                    height: 10,
                    resizeMode: 'contain',
                    marginLeft: 10,
                    tintColor: '#fff',
                  },
                  arrowStyle,
                ]}
              />
            )}
          </View>
        </TouchableOpacity>
      </div>
    );
  }

  return (
    <TouchableOpacity
      style={[
        {
          borderRadius: 6,
          alignItems: 'center',
          alignSelf: 'center',
          backgroundColor: STYLE_VARS.primaryColor,
        },
        style,
      ]}
      {...props}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {children ? (
          children
        ) : (
          <Text
            style={[
              {
                color: 'white',
                fontWeight: 'bold',
                fontSize: 12,
              },
              textStyle,
            ]}
          >
            {text}
          </Text>
        )}
        {arrowRight && (
          <Image
            source={require('../../assets/right-arrow.png')}
            style={[
              {
                width: 8,
                height: 10,
                resizeMode: 'contain',
                marginLeft: 10,
                tintColor: '#fff',
              },
              arrowStyle,
            ]}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};
