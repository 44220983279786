import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type FirstDownloadState = {
  isFirstTime?: boolean;
};

const initialState: FirstDownloadState = {
  isFirstTime: true,
};

const firstDownloadSlice = createSlice({
  name: 'firstDownload',
  initialState: initialState,
  reducers: {
    setIsFirstTime: (state, action: PayloadAction<boolean>) => {
      state.isFirstTime = action.payload;
    },
  },
});

export const { setIsFirstTime } = firstDownloadSlice.actions;
export default firstDownloadSlice.reducer;
