import { StyleSheet } from 'react-native';
import { STYLE_VARS } from '../../../../constants/style-vars';

export const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: { flexDirection: 'row', alignItems: 'center' },
  headerCart: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    marginRight: 10,
    tintColor: STYLE_VARS.primaryColor,
  },
  textCart: {
    fontSize: 22,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: STYLE_VARS.primaryColor,
    fontFamily: STYLE_VARS.iowanOldStyleFont,
  },
  searchIcon: {
    width: 20,
    height: 20,
    marginRight: 10,
    resizeMode: 'contain',
    tintColor: STYLE_VARS.primaryColor,
  },
  inputSearchWrapper: {
    width: 80,
  },
  searchText: {
    fontSize: 13,
    fontWeight: '500',
    color: STYLE_VARS.primaryColor,
    fontFamily: STYLE_VARS.avenirMediumFont,
    letterSpacing: 2,
    paddingHorizontal: 6,
    paddingVertical: 1,
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: STYLE_VARS.primaryColor,
  },
});
