import React, { FC } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { MenuStackParamList } from './types';
import {
  Menu,
  Notices,
  NoticeDetail,
  BookingList,
  BookingDetail,
  MyProfile,
  EditProfile,
  MyBookings,
  Messages,
  Home,
  MyActivitiesDetail,
  ContactUs,
} from '../screens';
import EShopSubCategoryPage from '../screens/EShop/EshopSubCategoryPage/EshopSubCategoryPage';
import { MessagesUser } from '../screens/MessagesUser';
import ChangeLanguage from '../screens/ChangeLanguage/ChangeLanguage';
import PushNotification from '../screens/PushNotification/PushNotification';
import DeleteAccount from '../screens/DeleteAccount/DeleteAccount';

const Stack = createNativeStackNavigator<MenuStackParamList>();
const MenuStack: FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        contentStyle: {
          backgroundColor: 'white',
          flex: 1,
        },
        headerShown: false,
      }}
    >
      <Stack.Screen name="Menu" component={Menu} />
      <Stack.Screen name="Notices" component={Notices} />
      <Stack.Screen name="NoticeDetail" component={NoticeDetail} />
      <Stack.Screen
        name="EShopSubCategoryPage"
        component={EShopSubCategoryPage}
      />
      <Stack.Screen name="BookingList" component={BookingList} />
      <Stack.Screen name="BookingDetail" component={BookingDetail} />
      <Stack.Screen name="MyProfile" component={MyProfile} />
      <Stack.Screen name="EditProfile" component={EditProfile} />
      <Stack.Screen name="MyBookings" component={MyBookings} />
      <Stack.Screen name="Messages" component={Messages} />
      <Stack.Screen name="HomePage" component={Home} />
      <Stack.Screen name="MessagesUser" component={MessagesUser} />
      <Stack.Screen name="MyActivitiesDetail" component={MyActivitiesDetail} />
      <Stack.Screen name="ContactUs" component={ContactUs} />
      <Stack.Screen name="ChangeLanguage" component={ChangeLanguage} />
      <Stack.Screen name="PushNotification" component={PushNotification} />
      <Stack.Screen name="DeleteAccount" component={DeleteAccount} />
    </Stack.Navigator>
  );
};

export default MenuStack;
