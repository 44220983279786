import { FC, useMemo, useState } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { ScrollView, Switch, Text, View } from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { STYLE_VARS } from '../../constants/style-vars';
import { Footer } from '../../components/Footer';

const PushNotification: FC = () => {
  const isDesktop = useIsDesktop();
  const [pushNoti, setPushNoti] = useState<boolean>(false);

  const renderNotificationContent = useMemo(() => {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            color: COLORS.LIGHT_BLACK,
            marginRight: 20,
          }}
        >
          Do you want to receive web push notifications?
        </Text>
        <Switch
          trackColor={{
            true: '#34C759',
            false: STYLE_VARS.fccDarkGrey,
          }}
          ios_backgroundColor={STYLE_VARS.fccLightGrey}
          thumbColor={COLORS.WHITE}
          onValueChange={(value: boolean) => {
            setPushNoti(value);
          }}
          value={pushNoti}
        />
      </View>
    );
  }, [pushNoti]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Push Notifications" haveGoBackButton />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  paddingBottom: 5,
                  height: 650,
                }}
              >
                {renderNotificationContent}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'LANGUAGE'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: COLORS.LIGHT_RED,
          height: 800,
        }}
      >
        {renderNotificationContent}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default PushNotification;
