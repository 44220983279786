import { Dimensions, StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { STYLE_VARS } from '../../constants/style-vars';

export const authStyles = StyleSheet.create({
  container: {
    paddingVertical: 20,
  },
  activityIndWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: COLORS.BLACK,
    marginBottom: 40,
    fontFamily: FONTS.Arial,
    fontSize: 30,
    letterSpacing: 1.5,
    fontWeight: '700',
  },
  submitwrap: {
    marginTop: 12,
    marginBottom: 20,
  },
  link: {
    marginTop: 20,
  },
  linkIcon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  linkUnderline: {
    textDecorationLine: 'underline',
    fontSize: 16,
  },
  logoWrap: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 100,
    height: 48,
    marginTop: 20,
  },
  infobanner: {
    flex: 1,
    width: Dimensions.get('window').width,
    height: (Dimensions.get('window').width * 350) / 849,
  },
  name: {
    textAlign: 'center',
    fontSize: 20,
    marginTop: 30,
    marginBottom: 30,
  },
  infoText: {
    textAlign: 'justify',
    fontSize: 16,
  },
  pwdRuleOk: {
    color: 'green',
  },
  pwdRuleNot: {
    color: 'red',
  },
  inputWithNote: {
    marginBottom: 3,
  },
  note: {
    marginTop: 6,
    marginBottom: 16,
    color: STYLE_VARS.primaryColor,
  },
});
