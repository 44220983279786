export function setGenericPassword(username: string, password: string) {
  return setInternetCredentials('keychain', username, password);
}

export function setInternetCredentials(
  service: string,
  username: string,
  password: string
) {
  return new Promise((resolve, reject) => {
    try {
      window.localStorage.setItem(
        service,
        JSON.stringify({
          username,
          password,
        })
      );
      resolve(null);
    } catch (err) {
      reject(err);
    }
  });
}

export function getGenericPassword() {
  return getInternetCredentials('keychain');
}

export function getInternetCredentials(service: string) {
  return new Promise((resolve, reject) => {
    try {
      const value = window.localStorage.getItem(service);
      resolve(JSON.parse(value || ''));
    } catch (err) {
      reject(err);
    }
  });
}

export function resetGenericPassword() {
  return resetInternetCredentials('keychain');
}

export function resetInternetCredentials(service: string) {
  return new Promise((resolve, reject) => {
    try {
      window.localStorage.removeItem(service);
      resolve(null);
    } catch (err) {
      reject(err);
    }
  });
}

export function hasInternetCredentials(service: string) {
  return new Promise((resolve, reject) => {
    try {
      var value = window.localStorage.getItem(service);
      if (value) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (err) {
      reject(err);
    }
  });
}

export const getSupportedBiometryType = () => null;

export enum ACCESSIBLE {
  WHEN_UNLOCKED = 'AccessibleWhenUnlocked',
  AFTER_FIRST_UNLOCK = 'AccessibleAfterFirstUnlock',
  ALWAYS = 'AccessibleAlways',
  WHEN_PASSCODE_SET_THIS_DEVICE_ONLY = 'AccessibleWhenPasscodeSetThisDeviceOnly',
  WHEN_UNLOCKED_THIS_DEVICE_ONLY = 'AccessibleWhenUnlockedThisDeviceOnly',
  AFTER_FIRST_UNLOCK_THIS_DEVICE_ONLY = 'AccessibleAfterFirstUnlockThisDeviceOnly',
}

export enum ACCESS_CONTROL {
  USER_PRESENCE = 'UserPresence',
  BIOMETRY_ANY = 'BiometryAny',
  BIOMETRY_CURRENT_SET = 'BiometryCurrentSet',
  DEVICE_PASSCODE = 'DevicePasscode',
  APPLICATION_PASSWORD = 'ApplicationPassword',
  BIOMETRY_ANY_OR_DEVICE_PASSCODE = 'BiometryAnyOrDevicePasscode',
  BIOMETRY_CURRENT_SET_OR_DEVICE_PASSCODE = 'BiometryCurrentSetOrDevicePasscode',
}
