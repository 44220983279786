import React, { FC } from 'react';
import { Image } from '../components/Image';
import { IS_WEB } from '../constants/platform';
import { addBase64Prefix } from '../services/addBase64Prefix';
import { TouchableOpacity } from 'react-native';
import { NavigationService } from '../services/navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux';
import { logo_hh } from '../assets/static/base64-new';

const HeaderMobileLeft: FC = () => {
  const auth = useSelector((redux: RootState) => redux.authReducer);

  return (
    <TouchableOpacity
      onPress={() => {
        NavigationService.push('Root', {
          screen: 'MenuTab',
          params: {
            screen: 'HomePage',
          },
        });
      }}
    >
      <Image
        source={
          IS_WEB ? addBase64Prefix(logo_hh) : require('../assets/logo.png')
        }
        style={{ width: 50, height: 50, resizeMode: 'contain', marginLeft: 20 }}
      />
    </TouchableOpacity>
  );
};

export default HeaderMobileLeft;
