import { FC, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { COLORS, FONTS } from '../../constants';

const GuestPicker: FC = () => {
  const [elderlyGuest, setElderlyGuest] = useState<number>(0);
  const [disabledGuest, setDisabledGuest] = useState<number>(0);
  const [otherGuest, setOtherGuest] = useState<number>(0);

  return (
    <View style={styles.container}>
      <View style={styles.guestItem}>
        <View style={styles.textLeftWrapper}>
          <Text style={styles.guestType}>Elderly Guest</Text>
          <Text style={styles.guestNote}>Ages 55 years or older</Text>
        </View>
        <View style={styles.buttonWrapper}>
          <TouchableOpacity
            style={[
              styles.button,
              elderlyGuest == 0 && { backgroundColor: COLORS.LIGHT_GREY },
            ]}
            onPress={() => {
              if (elderlyGuest > 0) setElderlyGuest(elderlyGuest - 1);
            }}
            disabled={elderlyGuest == 0}
          >
            <Image
              source={require('../../assets/minus-icon.png')}
              style={[
                {
                  width: 12,
                  height: 2,
                },
                elderlyGuest == 0 && { tintColor: COLORS.LIGHT_LIGHT_GREY },
              ]}
            />
          </TouchableOpacity>
          <View>
            <Text style={styles.numberGuest}>{elderlyGuest}</Text>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              setElderlyGuest(elderlyGuest + 1);
            }}
          >
            <Image
              source={require('../../assets/plus-icon.png')}
              style={{
                width: 12,
                height: 12,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.guestItem}>
        <View style={styles.textLeftWrapper}>
          <Text style={styles.guestType}>Disabled Guest</Text>
          <Text style={styles.guestNote}>Disabled or wheel chair user</Text>
        </View>
        <View style={styles.buttonWrapper}>
          <TouchableOpacity
            style={[
              styles.button,
              disabledGuest == 0 && { backgroundColor: COLORS.LIGHT_GREY },
            ]}
            onPress={() => {
              if (disabledGuest > 0) setDisabledGuest(disabledGuest - 1);
            }}
            disabled={disabledGuest == 0}
          >
            <Image
              source={require('../../assets/minus-icon.png')}
              style={[
                {
                  width: 12,
                  height: 2,
                },
                disabledGuest == 0 && { tintColor: COLORS.LIGHT_LIGHT_GREY },
              ]}
            />
          </TouchableOpacity>
          <View>
            <Text style={styles.numberGuest}>{disabledGuest}</Text>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              setDisabledGuest(disabledGuest + 1);
            }}
          >
            <Image
              source={require('../../assets/plus-icon.png')}
              style={{
                width: 12,
                height: 12,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View style={[styles.guestItem, { marginBottom: 0 }]}>
        <View style={styles.textLeftWrapper}>
          <Text style={styles.guestType}>All Other Guests</Text>
          <Text style={styles.guestNote}>
            Ages 3 years or above, under 55 years old
          </Text>
        </View>
        <View style={styles.buttonWrapper}>
          <TouchableOpacity
            style={[
              styles.button,
              otherGuest == 0 && { backgroundColor: COLORS.LIGHT_GREY },
            ]}
            onPress={() => {
              if (otherGuest > 0) setOtherGuest(otherGuest - 1);
            }}
            disabled={otherGuest == 0}
          >
            <Image
              source={require('../../assets/minus-icon.png')}
              style={[
                {
                  width: 12,
                  height: 2,
                },
                otherGuest == 0 && { tintColor: COLORS.LIGHT_LIGHT_GREY },
              ]}
            />
          </TouchableOpacity>
          <View>
            <Text style={styles.numberGuest}>{otherGuest}</Text>
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              setOtherGuest(otherGuest + 1);
            }}
          >
            <Image
              source={require('../../assets/plus-icon.png')}
              style={{
                width: 12,
                height: 12,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default GuestPicker;

const styles = StyleSheet.create({
  container: {
    padding: 20,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.9,
    shadowRadius: 3,
    elevation: 5,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    width: 300,
    flexDirection: 'column',
  },
  textLeftWrapper: {
    flexDirection: 'column',
    marginEnd: 20,
    width: 120,
  },
  guestType: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 14,
    color: COLORS.BLACK,
  },
  guestNote: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 10,
    color: COLORS.GREY,
  },
  buttonWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    padding: 10,
    width: 28,
    height: 28,
    borderRadius: 20,
    borderColor: COLORS.LIGHT_GREY,
  },
  numberGuest: {
    fontSize: 14,
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    color: COLORS.BLACK,
    marginHorizontal: 20,
  },
  guestItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
});
