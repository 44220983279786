import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import React, { FC, useState } from 'react';
import { Image, useWindowDimensions, View } from 'react-native';
import {
  address_white,
  email_white,
  fax_white,
  logo,
  phone_white,
  search_white,
  // search_white,
} from '../assets/base64';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput/TextInput';
// import { TextInput } from '../components/TextInput/TextInput';
import { MAX_WIDTH } from '../constants/platform';
import { STYLE_VARS } from '../constants/style-vars';
import { addBase64Prefix } from '../services/addBase64Prefix';

const AUTH_CONTACTS_MENU = [
  {
    title: 'LOCATION',
    icon: addBase64Prefix(address_white),
  },
  {
    title: 'FAX',
    icon: addBase64Prefix(fax_white),
    size: 26,
  },
  {
    title: 'EMAIL',
    icon: addBase64Prefix(email_white),
    size: 26,
  },
  {
    title: '(852) 3473 7000',
    icon: addBase64Prefix(phone_white),
    size: 26,
  },
];

const AUTH_HEADER_MENU = [
  'ABOUT US',
  'SPORTS & RECREATION',
  'LEISURE',
  'NEWS & EVENTS',
  'DINING',
  'KCC MEMBERSHIP',
  'CONTACT US',
];

export const MenuItem: FC<{
  title: string;
  icon: string;
  size: number;
  hasDropDown?: boolean;
}> = ({ title, icon, size, hasDropDown = true }) => {
  const [isHover, setHover] = useState(false);
  const { width } = useWindowDimensions();

  return (
    <div
      style={{ display: 'flex', cursor: 'pointer' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: 15,
          marginLeft: 15,
          marginBottom: 2,
        }}
      >
        <Image source={{ uri: icon }} style={{ width: size, height: size }} />

        <Text style={{ color: 'white', fontSize: 12, marginTop: 2 }}>
          {title}
        </Text>

        {isHover && (
          <View
            style={{
              position: 'absolute',
              left: -20,
              right: -20,
              bottom: -4,
              height: 2,
              backgroundColor: '#d2232a',
            }}
          />
        )}
      </View>

      {hasDropDown && isHover && (
        <>
          <View
            style={{
              position: 'absolute',
              width,
              left: 0,
              top: 86,
              height: 300,
              backgroundColor: '#ffffff',
            }}
          />
        </>
      )}
    </div>
  );
};

export const AuthNavHeader: FC<NativeStackHeaderProps> = () => {
  const { width } = useWindowDimensions();
  return (
    <View style={{ backgroundColor: STYLE_VARS.primaryColor }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          maxWidth: MAX_WIDTH,
          width: '100%',
          alignSelf: 'center',
          padding: '4px 8px',
        }}
      >
        <Image
          source={{ uri: addBase64Prefix(logo) }}
          style={{ width: 75, height: 75 }}
        />

        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingRight: 15,
              paddingBottom: 15,
            }}
          >
            {AUTH_CONTACTS_MENU.map((item, index, arr) => (
              <View
                key={index}
                style={{
                  flexDirection: 'row',
                  paddingHorizontal: 15,
                  borderRightColor: '#ffffff',
                  borderRightWidth: arr.length - 1 === index ? 0 : 1,
                }}
              >
                <Image
                  source={{ uri: item.icon }}
                  style={{ width: 20, height: 20 }}
                />
                <Text style={{ color: '#ffffff', marginLeft: 8 }}>
                  {item.title}
                </Text>
              </View>
            ))}

            <TextInput
              placeholder="SEARCH"
              placeholderTextColor="#ffffff"
              style={{
                color: '#ffffff',
                borderBottomColor: 'white',
                borderBottomWidth: 1,
                padding: 4,
                width: 70,
                marginRight: 4,
              }}
            />

            <Image
              source={{ uri: addBase64Prefix(search_white) }}
              style={{ width: 14, height: 14 }}
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 4,
            }}
          >
            {AUTH_HEADER_MENU.map((item, index, arr) =>
              width < 1100 && arr.length - 1 === index ? null : (
                <Text
                  key={index}
                  style={{
                    color: '#ffffff',
                    paddingHorizontal: 15,
                    fontSize: 16,
                    letterSpacing: 0.8,
                  }}
                >
                  {item}
                </Text>
              )
            )}
          </View>
        </View>
      </View>
    </View>
  );
};
