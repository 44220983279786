import { FC } from 'react';
import { IDish } from '../../../types/dishes.types';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { NavigationService } from '../../../services/navigation';
import { COLORS, FONTS } from '../../../constants';
import { Button } from '../../../components/Button';

interface ExtraDishItemProps {
  item: IDish;
}

const ExtraDishItem: FC<ExtraDishItemProps> = ({ item }) => {
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <TouchableOpacity
        style={{
          marginBottom: 7,
        }}
        onPress={() => {}}
      >
        <Image
          source={item.img}
          style={[
            {
              width: '100%',
              borderRadius: 18,
              height: 170,
            },
          ]}
        />
      </TouchableOpacity>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '800',
          fontSize: 19,
          color: COLORS.BLACK,
          marginBottom: 8,
        }}
      >
        {item.title}
      </Text>
      <View
        style={{
          width: 60,
          height: 2,
          marginBottom: 8,
          backgroundColor: COLORS.RED_PRIMARY,
        }}
      />
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontSize: 16,
          fontWeight: '500',
          color: COLORS.GREY,
          marginBottom: 10,
        }}
      >
        {item.desc}
      </Text>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '800',
          fontSize: 19,
          color: COLORS.RED_PRIMARY,
          marginBottom: 8,
        }}
      >
        HKD ${item.price}
      </Text>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <Button
          style={{
            paddingHorizontal: 20,
            paddingVertical: 10,
            backgroundColor: COLORS.RED_PRIMARY,
            borderRadius: 16,
          }}
          className=""
          text="Add to Cart"
          arrowRight={false}
          textStyle={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.WHITE,
          }}
        />
      </View>
    </View>
  );
};

export default ExtraDishItem;
