import { FC } from 'react';
import { Image, Text, View } from 'react-native';
import { FONTS } from '../../../constants';
import { Button } from '../../../components/Button';
import { STYLE_VARS } from '../../../constants/style-vars';

export const CorporateEvent: FC = () => {
  return (
    <View
      style={{
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Text
        style={{
          textTransform: 'uppercase',
          fontFamily: FONTS.AvenirMedium,
          fontSize: 20,
          letterSpacing: 0.7,
          textAlign: 'center',
          marginBottom: 8,
        }}
      >
        BOOK A CORPORATE EVENT
      </Text>
      <View
        style={{
          overflow: 'hidden',
          borderRadius: 12,
          marginBottom: 10,
        }}
      >
        <Image
          source={require('../../../assets/corporate-event-image.png')}
          style={{
            height: 150,
            width: '100%',
          }}
        />
      </View>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontSize: 15,
          lineHeight: 20,
          marginBottom: 20,
        }}
      >
        Lorem ipsum dolor sit amet,on setur adipiscing elit.
      </Text>
      <View style={{ alignSelf: 'center', marginBottom: 20 }}>
        <Button
          text="READ MORE"
          style={STYLE_VARS.redButton}
          textStyle={{
            fontSize: 13,
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
          }}
          onPress={() => {}}
        />
      </View>
    </View>
  );
};
