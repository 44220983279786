import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EShopSubCategoryPageProps } from './EshopSubCategoryPage.types';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { EShopLayout } from '../../../WebLayout';
import {
  Image,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { STYLE_VARS } from '../../../constants/style-vars';
import { COLORS, FONTS } from '../../../constants';
import { EshopCategory, EshopHeader, ProductItem } from '../components';
import { Button } from '../../../components/Button';
import { Category, Product } from '../../../types/woocommerce.types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../services/redux';
import { wooCommerceApi } from '../../../services/api/wooCommerceApi';
import { Loading } from '../../../components';
import ProductWooItem from '../components/ProductWooItem';
import { decode } from 'html-entities';
import styles from './EshopSubCategoryPage.style';
import ProductWooItemList from '../components/ProductWooItemList';
import { Footer } from '../../../components/Footer';
import { NavigationService } from '../../../services/navigation';
import ProductWooItemTable from '../components/ProductWooItemTable';
import {
  ProductFilter,
  initProductsFilter,
  setLoadingProducts,
  setProduct,
  setProductsFilter,
  setTotalProduct,
} from '../../../services/redux/reducers/EShopProductSlice';
import { debounce } from 'lodash';
import { IS_WEB } from '../../../constants/platform';
import { addBase64Prefix } from '../../../services/addBase64Prefix';
import { reset_icon } from '../../../assets/base64';

interface SortItemProps {
  id: number;
  name: string;
  order: string;
  orderBy: string;
}

const EShopSubCategoryPage: FC<EShopSubCategoryPageProps> = ({
  navigation,
  route,
}) => {
  const sortItems: SortItemProps[] = [
    {
      id: 1,
      name: 'Price: High > Low',
      order: 'desc',
      orderBy: 'price',
    },
    {
      id: 2,
      name: 'Price: Low > High',
      order: 'asc',
      orderBy: 'price',
    },
    {
      id: 3,
      name: 'A - Z',
      order: 'asc',
      orderBy: 'title',
    },
    {
      id: 4,
      name: 'Z - A',
      order: 'desc',
      orderBy: 'title',
    },
  ];
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const categorySlug = route.params.categoryName;
  const subCategorySlug = route.params.name;

  const { products, productsFilter, isLoadingProducts, totalProducts } =
    useSelector((state: RootState) => state.eShopProductReducer);
  const { categories } = useSelector(
    (state: RootState) => state.eShopCategoryReducer
  );

  const [subCategory, setSubCategory] = useState<Category>();
  const [isLoadingSubCategory, setIsLoadingSubCategory] =
    useState<boolean>(true);
  const [childCategories, setChildCategories] = useState<Category[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(true);
  const [isHaveNextPage, setIsHaveNextPage] = useState<boolean>(false);
  const [toggleSort, setToggleSort] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<SortItemProps>(sortItems[0]);
  const [toggleFilter, setToggleFilter] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<Category[]>([]);
  const [openCategoryFilter, setOpenCategoryFilter] = useState<boolean>(false);
  const searchRef = useRef<TextInput>(null);

  const category = useMemo(() => {
    const result = categories.find((item) => item.slug === categorySlug);
    return result;
  }, [categories, categorySlug]);

  const onSelectedCategory = (category: string) => {
    navigation.setParams({ name: category });
  };

  const getSubCategory = useCallback(async () => {
    try {
      setIsLoadingSubCategory(true);
      const res = await wooCommerceApi.get('products/categories', {
        per_page: 1,
        parent: category?.id,
        slug: subCategorySlug,
      });
      if (res.data[0]) {
        setSubCategory(res.data[0]);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoadingSubCategory(false);
    }
  }, [category, subCategorySlug]);

  useEffect(() => {
    if (category) {
      getSubCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChildCategories = useCallback(async () => {
    try {
      setIsLoadingCategories(true);
      const res = await wooCommerceApi.get('products/categories', {
        per_page: 20,
        parent: subCategory?.id,
      });
      setChildCategories(res.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoadingCategories(false);
    }
  }, [subCategory]);

  const getProducts = useCallback(
    async (props: ProductFilter) => {
      try {
        dispatch(setLoadingProducts(true));
        const res = await wooCommerceApi.get('products', props);
        dispatch(setProduct(res.data));
        dispatch(setTotalProduct(res.headers['x-wp-total']));
      } catch (error: any) {
        console.log(error);
      } finally {
        dispatch(setLoadingProducts(false));
      }
    },
    [dispatch]
  );

  const handleLoadMore = useCallback(async () => {
    if (products.length < totalProducts) {
      dispatch(
        setProductsFilter({
          ...productsFilter,
          page: Math.ceil(products.length / 12) + 1,
        })
      );
      try {
        dispatch(setLoadingProducts(true));
        const res = await wooCommerceApi.get('products', {
          ...productsFilter,
          page: Math.ceil(products.length / 12) + 1,
        });
        dispatch(setProduct([...products, ...res.data]));
        dispatch(setTotalProduct(res.headers['x-wp-total']));
      } catch (error: any) {
        console.log(error);
      } finally {
        dispatch(setLoadingProducts(false));
      }
    }
  }, [products, dispatch, productsFilter, totalProducts]);

  const handleSelectSort = useCallback(
    async (sortItem: SortItemProps) => {
      setSelectedSort(sortItem);
      dispatch(setProduct([]));
      dispatch(
        setProductsFilter({
          ...productsFilter,
          order: sortItem.order,
          orderby: sortItem.orderBy,
          page: 1,
        })
      );
      getProducts({
        ...productsFilter,
        order: sortItem.order,
        orderby: sortItem.orderBy,
        page: 1,
      });
    },
    [setSelectedSort, productsFilter, dispatch, getProducts]
  );

  const handleSelectFilter = useCallback(
    (selected: Category) => {
      let arr: Category[] = [];
      if (selectedFilters.includes(selected)) {
        arr = selectedFilters.filter((filter) => filter.id !== selected.id);
      } else {
        arr = [...selectedFilters, selected];
      }
      setSelectedFilters(arr);

      if (arr.length === 0 && subCategory) {
        arr = [subCategory];
      }
      const slugArray = arr.map((obj) => obj.id);
      const resultString = slugArray.join(',');

      if (resultString !== productsFilter.category) {
        dispatch(setProduct([]));
        getProducts({
          ...productsFilter,
          category: resultString,
          page: 1,
        });
        dispatch(
          setProductsFilter({
            ...productsFilter,
            category: resultString,
            page: 1,
          })
        );
      }
    },
    [
      selectedFilters,
      subCategory,
      dispatch,
      getProducts,
      productsFilter,
      setSelectedFilters,
    ]
  );

  const handleSearch = useCallback(
    (text: string) => {
      if (subCategory) {
        dispatch(setProduct([]));
        getProducts({
          ...productsFilter,
          page: 1,
          search: text,
        });
        dispatch(
          setProductsFilter({
            ...productsFilter,
            page: 1,
            search: text,
          })
        );
      }
    },
    [productsFilter, dispatch, getProducts, subCategory]
  );

  const handleFilterReset = useCallback(() => {
    dispatch(setProduct([]));
    setSelectedFilters([]);
    getProducts({
      ...productsFilter,
      category: subCategory?.id,
      page: 1,
    });
    dispatch(
      setProductsFilter({
        ...productsFilter,
        category: subCategory?.id,
        page: 1,
      })
    );
  }, [getProducts, productsFilter, dispatch, subCategory]);

  const debouncedSearch = debounce(handleSearch, 500);

  useEffect(() => {
    if (subCategory) {
      dispatch(setProduct([]));
      setSelectedFilters([]);
      searchRef.current?.clear();
      setSelectedSort({
        id: 1,
        name: 'Price: High > Low',
        order: 'desc',
        orderBy: 'price',
      });
      getChildCategories();
      dispatch(
        setProductsFilter({
          ...initProductsFilter,
          category: subCategory.id,
        })
      );
      getProducts({
        ...initProductsFilter,
        category: subCategory.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory]);

  if (isDesktop()) {
    return (
      <>
        {category && (
          <EShopLayout
            title={decode(subCategory?.name) ?? ''}
            icon={subCategory?.image?.src ?? ''}
            current={category.id}
            haveFilters={true}
            havePromotion={false}
            haveGoBackButton={true}
            childCategories={childCategories}
            category={category}
            handleSearch={debouncedSearch}
            searchRef={searchRef}
            handleSelectFilter={handleSelectFilter}
            selectedFilters={selectedFilters}
            handleFilterReset={handleFilterReset}
          >
            {isLoadingCategories === true ? (
              <Loading />
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexWrap: 'nowrap',
                    marginBottom: 30,
                  }}
                >
                  {childCategories.map((item, index) => (
                    <View>
                      <TouchableOpacity
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <View
                          style={{
                            width: 80,
                            height: 80,
                            borderRadius: 50,
                            borderWidth: 2,
                            borderColor: STYLE_VARS.primaryColor,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Image
                            source={{ uri: item.image.src ?? '' }}
                            style={{
                              height: 50,
                              width: 50,
                              resizeMode: 'contain',
                            }}
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: 'center',
                            marginTop: 25,
                            fontFamily: FONTS.AvenirMedium,
                            fontWeight: '500',
                            fontSize: 15,
                            textTransform: 'uppercase',
                            color: STYLE_VARS.fccDarkerGrey,
                            width: 110,
                          }}
                        >
                          {item.name}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: 20,
                    zIndex: 2,
                  }}
                >
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        setToggleSort(!toggleSort);
                      }}
                      style={[
                        {
                          flexDirection: 'row',
                          alignItems: 'center',
                          paddingVertical: 5,
                          paddingHorizontal: 5,
                        },
                        toggleSort && {
                          backgroundColor: COLORS.GREEN,
                        },
                      ]}
                    >
                      <Image
                        source={require('../../../assets/sort.png')}
                        style={[
                          {
                            width: 13,
                            height: 13,
                            marginRight: 5,
                          },
                          toggleSort && {
                            tintColor: COLORS.WHITE,
                          },
                        ]}
                      />
                      <Text
                        style={[
                          {
                            fontFamily: FONTS.IowanOldStyleBTRoman,
                            fontSize: 15,
                            color: COLORS.GREEN,
                            fontWeight: '500',
                            letterSpacing: 1,
                          },
                          toggleSort && { color: COLORS.WHITE },
                        ]}
                      >
                        {'SORT'}
                      </Text>
                    </TouchableOpacity>
                    {toggleSort && (
                      <View
                        style={{
                          position: 'absolute',
                          top: 25,
                          right: 0,
                          backgroundColor: COLORS.GREEN,
                          paddingHorizontal: 20,
                          paddingVertical: 20,
                          width: 180,
                        }}
                      >
                        {sortItems.map((sortItem) => (
                          <TouchableOpacity
                            key={sortItem.name}
                            style={[
                              styles.selectWrapper,
                              { alignSelf: 'flex-start' },
                            ]}
                            onPress={() => {
                              handleSelectSort(sortItem);
                            }}
                          >
                            <View style={styles.selectedBox}>
                              {selectedSort &&
                                selectedSort.id === sortItem.id && (
                                  <View
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      borderColor: COLORS.WHITE,
                                      borderWidth: 4,
                                    }}
                                  ></View>
                                )}
                            </View>
                            <Text style={styles.selectedSubText}>
                              {sortItem.name}
                            </Text>
                          </TouchableOpacity>
                        ))}
                      </View>
                    )}
                  </View>
                </View>
                {products.length > 0 ? (
                  <View>
                    {['merchandise', 'health', 'sporting-accessories'].includes(
                      categorySlug
                    ) ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                          marginBottom: 20,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: FONTS.AvenirMedium,
                            textTransform: 'uppercase',
                            color: COLORS.GREEN,
                            fontSize: 25,
                          }}
                        >
                          Feature {subCategory?.name}
                        </Text>
                      </View>
                    ) : (
                      <></>
                    )}
                    {[
                      'beverages',
                      'merchandise',
                      'clothing',
                      'sporting-accessories',
                    ].includes(categorySlug) ? (
                      <View>
                        {categorySlug === 'clothing' ? (
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingVertical: 20,
                              borderTopColor: COLORS.DARK_GREY,
                              borderTopWidth: 1,
                            }}
                          >
                            <View
                              style={{
                                width: '20%',
                                paddingHorizontal: 5,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: FONTS.AvenirMedium,
                                  fontSize: 15,
                                  textTransform: 'uppercase',
                                  color: COLORS.GREEN,
                                }}
                              >
                                Name
                              </Text>
                            </View>
                            <View
                              style={{
                                width: '35%',
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: FONTS.AvenirMedium,
                                  fontSize: 15,
                                  textTransform: 'uppercase',
                                  color: COLORS.GREEN,
                                }}
                              >
                                Description
                              </Text>
                            </View>
                            <View
                              style={{
                                width: '15%',
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: FONTS.AvenirMedium,
                                  fontSize: 15,
                                  textTransform: 'uppercase',
                                  color: COLORS.GREEN,
                                }}
                              >
                                Price
                              </Text>
                            </View>
                            <View
                              style={{
                                width: '15%',
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: FONTS.AvenirMedium,
                                  fontSize: 15,
                                  textTransform: 'uppercase',
                                  color: COLORS.GREEN,
                                }}
                              >
                                Quantity
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}
                        <View
                          style={{
                            flexDirection: 'column',
                          }}
                        >
                          {products.map((item, index) => {
                            if (
                              ['beverages', 'clothing'].includes(categorySlug)
                            ) {
                              return (
                                <ProductWooItemTable
                                  product={item}
                                  key={index}
                                  type={index % 2 == 0 ? 'even' : 'obb'}
                                  haveImage={categorySlug === 'beverages'}
                                  categoryName={category?.name}
                                />
                              );
                            }

                            return (
                              <ProductWooItemList
                                product={item}
                                key={item.id}
                                categoryName={category?.name}
                              />
                            );
                          })}
                        </View>
                      </View>
                    ) : (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          flexWrap: 'wrap',
                          gap: 20,
                        }}
                      >
                        {products.map((item, index) => (
                          <View
                            key={index}
                            style={{
                              flex: 0.3333333333,
                              minWidth: 250,
                            }}
                          >
                            <ProductWooItem
                              product={item}
                              type="grid"
                              key={item.id}
                              categoryName={subCategory?.name}
                            />
                          </View>
                        ))}
                      </View>
                    )}
                  </View>
                ) : (
                  <></>
                )}
                {isLoadingProducts === true ? (
                  <View
                    style={{
                      marginTop: 30,
                    }}
                  >
                    <Loading />
                  </View>
                ) : (
                  <>
                    {products.length === 0 && (
                      <View>
                        <Text
                          style={{
                            color: COLORS.GREEN,
                            fontSize: 18,
                            fontFamily: FONTS.IowanOldStyleBTRoman,
                            textTransform: 'uppercase',
                            textAlign: 'center',
                          }}
                        >
                          THERE ARE CURRENTLY NO PRODUCTS
                        </Text>
                      </View>
                    )}
                    {products.length < totalProducts && (
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'center',
                          marginTop: 30,
                          marginBottom: 50,
                        }}
                      >
                        <Button
                          text={'VIEW MORE'}
                          style={STYLE_VARS.blueButton}
                          onPress={() => {
                            handleLoadMore();
                          }}
                        />
                      </View>
                    )}
                  </>
                )}
              </View>
            )}
          </EShopLayout>
        )}
      </>
    );
  }

  if (category) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View>
          <EshopHeader handleSearch={debouncedSearch} searchRef={searchRef} />
          <EshopCategory
            onSelectedCategory={onSelectedCategory}
            selected={categorySlug}
          />
          {isLoadingSubCategory === true ? (
            <View
              style={{
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <Loading />
            </View>
          ) : (
            <View style={styles.wrapper}>
              <View style={styles.menuHeaderContainer}>
                <View style={styles.menuHeader}>
                  <Image
                    style={styles.menuIcon}
                    source={{ uri: subCategory?.image?.src }}
                  />
                  <Text style={styles.menuHeaderText}>
                    {decode(subCategory?.name)}
                  </Text>
                </View>
                <View style={styles.menuHeaderOption}>
                  <TouchableOpacity
                    onPress={() => {
                      setToggleSort(!toggleSort);
                      setToggleFilter(false);
                    }}
                    style={[
                      {
                        backgroundColor: toggleSort
                          ? COLORS.GREEN
                          : COLORS.WHITE,
                      },
                    ]}
                  >
                    <Image
                      style={[
                        styles.optionIcon,
                        toggleSort && {
                          tintColor: COLORS.WHITE,
                        },
                      ]}
                      source={require('../../../assets/sort.png')}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setToggleFilter(!toggleFilter);
                      setToggleSort(false);
                    }}
                    style={{
                      backgroundColor: toggleFilter
                        ? COLORS.GREEN
                        : COLORS.WHITE,
                    }}
                  >
                    <Image
                      style={[
                        styles.optionIcon,
                        toggleFilter && {
                          tintColor: COLORS.WHITE,
                        },
                      ]}
                      source={require('../../../assets/filter-button.png')}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              {isLoadingCategories === true ? (
                <View
                  style={{
                    paddingVertical: 20,
                  }}
                >
                  <Loading />
                </View>
              ) : (
                <View style={[styles.menuCategoriesWrapper, { zIndex: 1 }]}>
                  {toggleFilter === true && (
                    <View
                      style={{
                        position: 'absolute',
                        top: -1,
                        right: 0,
                        backgroundColor: COLORS.GREEN,
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                        width: 170,
                        zIndex: 2,
                      }}
                    >
                      <View>
                        <TouchableOpacity
                          onPress={() => {
                            setOpenCategoryFilter(!openCategoryFilter);
                          }}
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: FONTS.AvenirMedium,
                              textTransform: 'uppercase',
                              fontWeight: '500',
                              color: COLORS.WHITE,
                              letterSpacing: 1,
                              fontSize: 14,
                            }}
                          >
                            {'Category'}
                          </Text>
                          <Image
                            source={
                              openCategoryFilter == true
                                ? require('../../../assets/up-arrow.png')
                                : require('../../../assets/down-arrow.png')
                            }
                            style={{
                              width: 13,
                              height: 6,
                              tintColor: COLORS.WHITE,
                            }}
                          />
                        </TouchableOpacity>
                        {openCategoryFilter === true && (
                          <View
                            style={{
                              flexDirection: 'column',
                              marginTop: 7,
                            }}
                          >
                            {childCategories?.map((item, index) => (
                              <TouchableOpacity
                                style={[
                                  styles.selectWrapper,
                                  { alignSelf: 'flex-start' },
                                ]}
                                key={item.id}
                                onPress={() => {
                                  handleSelectFilter(item);
                                }}
                              >
                                <View style={styles.selectedBox}>
                                  {selectedFilters.find(
                                    (e) => e.id === item.id
                                  ) && (
                                    <View
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        borderColor: COLORS.WHITE,
                                        borderWidth: 4,
                                      }}
                                    ></View>
                                  )}
                                </View>
                                <Text style={styles.selectedSubText}>
                                  {decode(item.name)}
                                </Text>
                              </TouchableOpacity>
                            ))}
                          </View>
                        )}
                      </View>
                      <TouchableOpacity
                        style={[
                          {
                            marginTop: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            alignSelf: 'flex-start',
                          },
                        ]}
                        onPress={handleFilterReset}
                      >
                        <Image
                          source={
                            IS_WEB
                              ? addBase64Prefix(reset_icon)
                              : require('../../../assets/reset-icon.png')
                          }
                          style={{
                            width: 14,
                            height: 14,
                            marginRight: 5,
                            tintColor: COLORS.WHITE,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: 14,
                            fontFamily: FONTS.AvenirMedium,
                            color: COLORS.WHITE,
                            fontWeight: '500',
                            textTransform: 'uppercase',
                          }}
                        >
                          RESET FILTER
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                  {toggleSort === true && (
                    <View
                      style={{
                        position: 'absolute',
                        top: -1,
                        right: 0,
                        backgroundColor: COLORS.GREEN,
                        paddingHorizontal: 10,
                        paddingVertical: 10,
                        width: 170,
                        zIndex: 2,
                      }}
                    >
                      {sortItems.map((sortItem) => (
                        <TouchableOpacity
                          key={sortItem.name}
                          style={[
                            styles.selectWrapper,
                            { alignSelf: 'flex-start' },
                          ]}
                          onPress={() => {
                            handleSelectSort(sortItem);
                          }}
                        >
                          <View style={styles.selectedBox}>
                            {selectedSort &&
                              selectedSort.id === sortItem.id && (
                                <View
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    borderColor: COLORS.WHITE,
                                    borderWidth: 4,
                                  }}
                                ></View>
                              )}
                          </View>
                          <Text style={styles.selectedSubText}>
                            {sortItem.name}
                          </Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  )}
                  {childCategories.map((childCategory, index) => (
                    <View style={styles.menuCategoryItem} key={index}>
                      <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => {
                          handleSelectFilter(childCategory);
                        }}
                      >
                        <View
                          style={[
                            styles.menuCategoryIconWrapper,
                            {
                              backgroundColor:
                                selectedFilters.find(
                                  (e) => e.id === childCategory.id
                                ) && COLORS.GREEN,
                            },
                          ]}
                        >
                          <Image
                            style={[
                              styles.menuCategoryIcon,
                              {
                                tintColor:
                                  selectedFilters.find(
                                    (e) => e.id === childCategory.id
                                  ) && COLORS.WHITE,
                              },
                            ]}
                            source={{ uri: childCategory.image.src }}
                          />
                        </View>
                        <View style={styles.menuCategoryNameWrapper}>
                          <Text style={styles.menuCategoryName}>
                            {decode(childCategory.name)}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  ))}
                </View>
              )}
              {products.length > 0 && category ? (
                <View>
                  {['merchandise', 'clothing', 'sporting-accessories'].includes(
                    category.slug
                  ) ? (
                    <View
                      style={{
                        flexDirection: 'column',
                        paddingHorizontal: 5,
                      }}
                    >
                      {products.map((item, index) => (
                        <ProductWooItemList
                          product={item}
                          key={index}
                          haveIcon={categorySlug === 'sporting-accessories'}
                          categoryName={category?.name}
                        />
                      ))}
                    </View>
                  ) : (
                    <View style={styles.productsWrapper}>
                      {products.map((item, index) => (
                        <View key={index} style={{ width: '50%', padding: 5 }}>
                          <ProductWooItem
                            product={item}
                            type="grid"
                            key={item.id}
                            categoryName={category?.name}
                          />
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              ) : (
                <></>
              )}
              {isLoadingProducts === true ? (
                <View
                  style={{
                    marginTop: 30,
                  }}
                >
                  <Loading />
                </View>
              ) : (
                <>
                  {products.length === 0 && (
                    <View>
                      <Text
                        style={{
                          color: COLORS.GREEN,
                          fontSize: 18,
                          fontFamily: FONTS.IowanOldStyleBTRoman,
                          textTransform: 'uppercase',
                          textAlign: 'center',
                        }}
                      >
                        THERE ARE CURRENTLY NO PRODUCTS
                      </Text>
                    </View>
                  )}
                  {products.length < totalProducts && (
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 20,
                        marginBottom: 30,
                      }}
                    >
                      <View style={styles.loadMoreCtn}>
                        <Button
                          style={STYLE_VARS.blueButton}
                          text="LOAD MORE"
                          onPress={() => {
                            handleLoadMore();
                          }}
                        />
                      </View>
                    </View>
                  )}
                </>
              )}
            </View>
          )}
          <Footer />
        </View>
      </ScrollView>
    );
  }
  return <></>;
};

export default EShopSubCategoryPage;
