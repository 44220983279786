import { FC, useCallback, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { Footer } from '../../components/Footer';
import CreateAccountForm from './components/CreateAccountForm';
import { ICreateAccountState } from './types';
import VerifyCode from './components/VerifyCode';

const initialCreateAccountState: ICreateAccountState = {
  mode: 'form',
  email: '',
  mobile: '',
};

const CreateAnAccount: FC = () => {
  const [state, setState] = useState(initialCreateAccountState);

  const updateState = useCallback(
    (state: Partial<ICreateAccountState>) =>
      setState((prevState) => ({ ...prevState, ...state })),
    [setState]
  );

  return (
    <ScrollView>
      {state.mode === 'form' && (
        <CreateAccountForm state={state} setState={updateState} />
      )}
      {(state.mode === 'email' || state.mode === 'mobile') && (
        <VerifyCode state={state} setState={updateState} />
      )}
      <Footer />
    </ScrollView>
  );
};

export default CreateAnAccount;

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 15,
    borderRadius: 13,
    width: 300,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
