import { FC, useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';

interface ITableItem {
  product: string;
  desc: string;
  price: string;
}

const ProductTable: FC = () => {
  const isDesktop = useIsDesktop();

  const roomItems = useMemo(() => {
    return [
      {
        product: '4 Person Room',
        desc: 'No. Of People: 3 people',
        price: '$580',
      },
    ];
  }, []);

  const mealItems = useMemo(() => {
    return [
      {
        product: 'Breakfast',
        desc: 'Standard',
        price: 'Free',
      },
      {
        product: 'Lunch',
        desc: 'Standard',
        price: 'Free',
      },
      {
        product: 'Lunch',
        desc: 'Sweet & Sour Pork',
        price: '$79',
      },
      {
        product: 'Dinner',
        desc: 'BBQ Pork',
        price: 'Free',
      },
      {
        product: 'Dinner',
        desc: 'Chow Mein',
        price: '$29',
      },
    ];
  }, []);

  const activityItems = useMemo(() => {
    return [
      {
        product: 'FREE Italian Wine Tasting',
        desc: '23 Mar 2024  05:30 PM - 08:30 PM',
        price: 'Free',
      },
      {
        product: 'Easter Buffet',
        desc: '23 Mar 2024  12:00 PM - 03:00PM',
        price: 'Free',
      },
      {
        product: 'Easter Fun Crafts',
        desc: '23 Mar 2024 02:00 PM - 03:25 PM',
        price: 'Free',
      },
    ];
  }, []);

  const renderTableItem = useCallback((items: ITableItem[]) => {
    return (
      <View style={{ flexDirection: 'column' }}>
        {items.map((item) => {
          return (
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <View style={{ width: '30%' }}>
                <Text style={styles.tableContentText}>{item.product}</Text>
              </View>
              <View style={{ width: '45%' }}>
                <Text style={styles.tableContentText}>{item.desc}</Text>
              </View>
              <View style={{ width: '25%' }}>
                <Text style={styles.tableContentText}>{item.price}</Text>
              </View>
            </View>
          );
        })}
      </View>
    );
  }, []);

  return (
    <View
      style={{
        backgroundColor: '#f9f2f1',
        padding: 16,
        borderRadius: 18,
        flexDirection: 'column',
        marginBottom: 25,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          paddingBottom: 10,
          borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
          borderBottomWidth: 1,
          marginBottom: 8,
        }}
      >
        <View style={{ width: '30%' }}>
          <Text style={styles.tableHeaderText}>Product</Text>
        </View>
        <View style={{ width: '45%' }}>
          <Text style={styles.tableHeaderText}>Description</Text>
        </View>
        <View
          style={[
            {
              width: '25%',
            },
            isDesktop()
              ? {
                  flexDirection: 'row',
                  alignItems: 'center',
                }
              : {
                  flexDirection: 'column',
                },
          ]}
        >
          <Text style={styles.tableHeaderText}>Price</Text>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 12,
              fontWeight: '500',
              marginLeft: 3,
            }}
          >
            {'(HKD)'}
          </Text>
        </View>
      </View>
      <View
        style={{
          borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
          borderBottomWidth: 1,
          marginBottom: 10,
        }}
      >
        <View style={{ marginBottom: 10 }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.tableHeaderText}>Rooms</Text>
          </View>
          {renderTableItem(roomItems)}
        </View>
        <View style={{ marginBottom: 10 }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.tableHeaderText}>Meals</Text>
          </View>
          {renderTableItem(mealItems)}
        </View>
        <View>
          <View style={{ marginBottom: 8 }}>
            <Text style={styles.tableHeaderText}>Included Activities</Text>
          </View>
          {renderTableItem(activityItems)}
        </View>
      </View>
      <View style={{ flexDirection: 'row', marginBottom: 20 }}>
        <View style={{ width: '30%' }}>
          <Text style={[styles.tableContentText, { color: COLORS.BLACK }]}>
            Total Price:
          </Text>
        </View>
        <View style={{ width: '45%' }}>
          <Text style={[styles.tableContentText, { color: COLORS.BLACK }]}>
            Subsidised booking rate applied
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={[styles.tableContentText, { color: COLORS.BLACK }]}>
            $688
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ProductTable;

const styles = StyleSheet.create({
  tableHeaderText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.BLACK,
  },
  tableContentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
