import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { PAGE_ITEMS } from '../../constants';
import { IEvent, IEventCategory } from '../../types';
import { EventFilter } from '../redux/reducers/EventSlice';
import instance from './axios';

type EventsCategoryProps = {
  search?: string;
  parent?: number;
};

const ENDPOINTS = {
  GETALLEVENTS: '/member-area-events',
  EVENTS: '/events',
  EVENTS_CATEGORY: '/event-categories',
};

const getAllEvents = () => {
  return instance.get(ENDPOINTS.GETALLEVENTS);
};

const getEvents = ({ limit = PAGE_ITEMS, page = 1, ...props }: EventFilter) => {
  return instance.get<{
    total: number;
    last_page: number;
    events: IEvent[];
  }>(ENDPOINTS.EVENTS, {
    params: {
      limit,
      page,
      ...props,
    },
  });
};

const getEvent = (id: number) => {
  return instance.get<IEvent>(ENDPOINTS.EVENTS + '/' + id);
};

const getEventCalendarFile = (id: number) => {
  return instance.get<Blob>(ENDPOINTS.EVENTS + '/' + id + '/calendar', {
    responseType: 'blob',
  });
};

const getEventsCategory = ({ search, parent }: EventsCategoryProps) => {
  return instance.get<IEventCategory[]>(ENDPOINTS.EVENTS_CATEGORY, {
    params: { search, parent },
  });
};

const postSignUpEvent = (id: number, data: IFormData) => {
  return instance.post<any>(`${ENDPOINTS.EVENTS}/${id}/signup`, data);
};

const EventsApi = {
  getAllEvents,
  getEvents,
  getEventsCategory,
  getEvent,
  getEventCalendarFile,
  postSignUpEvent,
};

export default EventsApi;
