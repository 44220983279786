import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAGE_ITEMS } from '../../../constants';
import { INew } from '../../../types';

export type NewsFilter = {
  page?: number;
  limit?: number;
  search?: string;
  cats?: string;
};

interface NewsState {
  newsData: INew[];
  newsFilter: NewsFilter;
  isLoading: boolean;
  error: string;
  total: number;
}

const initialState: NewsState = {
  newsData: [],
  isLoading: false,
  error: '',
  newsFilter: {
    page: 1,
    limit: 6,
    cats: '',
  },
  total: 0,
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsFilter: (state, action: PayloadAction<NewsFilter>) => {
      state.newsFilter = action.payload;
    },
    setNews: (state, action: PayloadAction<INew[]>) => {
      state.newsData = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoadingNews: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearNewsFilter: (state, action: PayloadAction) => {
      state.newsData = [];
      state.total = 0;
      state.newsFilter = {
        page: 1,
        limit: 6,
        cats: '',
      };
    },
  },
  extraReducers: {},
});

export const {
  setNewsFilter,
  clearNewsFilter,
  setNews,
  setTotal,
  setLoadingNews,
} = newsSlice.actions;

export default newsSlice.reducer;