import { FC } from 'react';
import { Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';

interface ReceiveMessageProps {
  content: string;
  time: string;
}

const ReceiveMessage: FC<ReceiveMessageProps> = ({ content, time }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingVertical: 15,
      }}
    >
      <View style={{ position: 'relative' }}>
        <View
          style={{
            width: 0,
            height: 0,
            borderLeftWidth: 40,
            borderRightWidth: 0,
            borderTopWidth: 20,
            borderLeftColor: 'transparent',
            borderRightColor: 'transparent',
            borderTopColor: 'white',
            position: 'absolute',
            bottom: 0,
          }}
        ></View>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 8,
            color: COLORS.GREY,
            bottom: 0,
            right: 10,
            position: 'absolute',
            zIndex: 4,
          }}
        >
          {time}
        </Text>
        <View
          style={{
            backgroundColor: COLORS.WHITE,
            padding: 10,
            borderRadius: 16,
            marginLeft: 20,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 16,
              color: COLORS.GREY,
              maxWidth: 300,
            }}
          >
            {content}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ReceiveMessage;
