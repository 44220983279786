import { FC, useMemo } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import {
  Image,
  KeyboardAvoidingView,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { TextInput } from '../../components/TextInput/TextInput';
import ReceiveMessage from './components/ReceiveMessage';
import SendMessage from './components/SendMessage';
import { Footer } from '../../components/Footer';
import { NavigationService } from '../../services/navigation';

const MessagesUser: FC = () => {
  const isDesktop = useIsDesktop();
  const { height: screenHeight } = useWindowDimensions();

  const renderChatbox = useMemo(() => {
    const messages = [
      {
        type: 'receive',
        content: 'Hey! What’s the update?',
        time: '11:02AM',
      },
      {
        type: 'send',
        content: 'Yeah, will be up in a minute.',
        time: '11:03AM',
      },
      {
        type: 'send',
        content: 'I’m nervous',
        time: '11:03AM',
      },
      {
        type: 'receive',
        content: 'Trust you girl,you will be fine',
        time: '11:03AM',
      },
      {
        type: 'send',
        content: 'Thank you',
        time: '11:04AM',
      },
      {
        type: 'send',
        content: 'Nice performance today, dear!',
        time: '1:35PM',
      },
    ];

    return (
      <ScrollView
        style={{
          height: isDesktop() ? 450 : screenHeight - 212,
          flexDirection: 'column',
          marginBottom: 25,
        }}
      >
        {messages.map((message, index) => (
          <>
            {message.type == 'receive' ? (
              <ReceiveMessage content={message.content} time={message.time} />
            ) : (
              <SendMessage content={message.content} time={message.time} />
            )}
          </>
        ))}
      </ScrollView>
    );
  }, [screenHeight, isDesktop]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    NavigationService.push('Root', {
                      screen: 'Messages',
                    });
                  }}
                >
                  <Image
                    source={require('../../assets/arrow-left.png')}
                    style={{
                      width: 30,
                      height: 30,
                      tintColor: '#575757',
                      marginRight: 8,
                    }}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    flexDirection: 'row',
                    gap: 15,
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../assets/users/image-1.png')}
                    style={{
                      width: 55,
                      height: 55,
                      borderRadius: 55,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '800',
                        fontSize: 19,
                        letterSpacing: 1,
                        color: COLORS.BLACK,
                      }}
                    >
                      Dianne
                    </Text>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        fontSize: 16,
                        color: COLORS.GREY,
                      }}
                    >
                      Online
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  borderRadius: 18,
                  backgroundColor: COLORS.LIGHT_RED,
                  paddingHorizontal: 30,
                  paddingVertical: 40,
                  flexDirection: 'column',
                }}
              >
                {renderChatbox}
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 20,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      padding: 10,
                      backgroundColor: COLORS.WHITE,
                      borderWidth: 0.5,
                      borderColor: '#00000080',
                      borderRadius: 5,
                      width: '80%',
                      flex: 1,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        marginRight: 10,
                      }}
                    >
                      <Image
                        source={require('../../assets/camera.png')}
                        style={{
                          width: 30,
                          height: 25,
                          resizeMode: 'contain',
                        }}
                      />
                    </TouchableOpacity>
                    <TextInput
                      placeholder="Type your messages"
                      placeholderTextColor={COLORS.GREY}
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 16,
                        flex: 1,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      paddingRight: 50,
                    }}
                  >
                    <TouchableOpacity>
                      <Image
                        source={require('../../assets/send-icon.png')}
                        style={{ width: 30, height: 30, resizeMode: 'contain' }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }
  return (
    <View>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          paddingVertical: 8,
          paddingHorizontal: 15,
          justifyContent: 'flex-start',
          elevation: 4,
          shadowColor: '#0C0C0D1A',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 1,
          shadowRadius: 4,
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          style={{
            marginRight: 10,
          }}
          onPress={() => {
            NavigationService.push('Root', {
              screen: 'Messages',
            });
          }}
        >
          <Image
            source={require('../../assets/arrow-left.png')}
            style={{
              width: 30,
              height: 30,
              tintColor: COLORS.BLACK,
            }}
          />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: 'row',
            gap: 15,
            alignItems: 'center',
          }}
        >
          <Image
            source={require('../../assets/users/image-1.png')}
            style={{
              width: 55,
              height: 55,
              borderRadius: 55,
            }}
          />
          <View>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '800',
                fontSize: 19,
                letterSpacing: 1,
                color: COLORS.BLACK,
              }}
            >
              Dianne
            </Text>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 16,
                color: COLORS.GREY,
              }}
            >
              Online
            </Text>
          </View>
        </View>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        automaticallyAdjustKeyboardInsets={true}
      >
        <View
          style={{
            backgroundColor: COLORS.LIGHT_RED,
            paddingHorizontal: 10,
            flexDirection: 'column',
          }}
        >
          {renderChatbox}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 20,
              paddingBottom: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                padding: 10,
                backgroundColor: COLORS.WHITE,
                borderWidth: 0.5,
                borderColor: '#00000080',
                borderRadius: 5,
                width: '80%',
                flex: 1,
              }}
            >
              <TouchableOpacity
                style={{
                  marginRight: 10,
                }}
              >
                <Image
                  source={require('../../assets/camera.png')}
                  style={{
                    width: 30,
                    height: 25,
                    resizeMode: 'contain',
                  }}
                />
              </TouchableOpacity>
              <TextInput
                placeholder="Type your messages"
                placeholderTextColor={COLORS.GREY}
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  flex: 1,
                }}
              />
            </View>
            <View
              style={{
                paddingRight: 10,
              }}
            >
              <TouchableOpacity>
                <Image
                  source={require('../../assets/send-icon.png')}
                  style={{ width: 30, height: 30, resizeMode: 'contain' }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default MessagesUser;
