import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Category } from "../../../types/woocommerce.types";

interface eShopCategoryState {
  categories: Category[];
  isLoading: boolean;
  error: string;
}

const initialState: eShopCategoryState = {
  categories: [],
  isLoading: false,
  error: '',
};

export const eShopCategorySlice = createSlice({
  name: 'e-shop-category',
  initialState,
  reducers: {
    setEShopCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setLoadingEShopCategories: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setEShopCategories,
  setLoadingEShopCategories,
} = eShopCategorySlice.actions;

export default eShopCategorySlice.reducer;