import { PAGE_ITEMS } from '../../constants';
import { API_URI } from '../../constants/env';
import { IPromotion, IPromotionCategory } from '../../types/promotions.types';
import { PromotionFilter } from '../redux/reducers/PromotionSlice';
import instance from './axios';

type PromotionsCategoryProps = {
  search?: string;
  parent?: number;
};

const ENDPOINTS = {
  GETALLPROMOTIONS: '/member-area-promotions',
  PROMOTIONS: '/promotions',
  PROMOTION_CATEGORIES: 'promotion-categories',
};

const getAllPromotions = () => {
  return instance.get<IPromotion[]>(ENDPOINTS.GETALLPROMOTIONS, {
    baseURL: API_URI + '/wp-json/my/v1',
  });
};

const getPromotions = ({ limit = PAGE_ITEMS, page = 1, ...props}: PromotionFilter) => {
  return instance.get<{
    total: number;
    last_page: number;
    promotions: IPromotion[];
  }>(ENDPOINTS.PROMOTIONS, {
    params: {
      limit,
      page,
      ...props,
    },
  });
};

const getPromotion = (id: number) => {
  return instance.get<IPromotion>(ENDPOINTS.PROMOTIONS + '/' + id);
};

const getPromotionCategories = ({ search, parent }: PromotionsCategoryProps) => {
  return instance.get<IPromotionCategory[]>(ENDPOINTS.PROMOTION_CATEGORIES, {
    baseURL: API_URI + '/wp-json/kcc/v1',
    params: { search, parent },
  });
};

const PromotionsApi = {
  getAllPromotions,
  getPromotions,
  getPromotion,
  getPromotionCategories,
};

export default PromotionsApi;
