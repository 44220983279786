import { Text, View, TouchableOpacity, ScrollView, Modal } from 'react-native';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { DropDownProps } from './DropDown.types';
import { Image } from '../Image';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { DropDownItem } from '../../models/DropDownItem';
import { styles } from './DropDown.styled';
import { COLORS } from '../../constants';
import { IS_IOS, IS_WEB } from '../../constants/platform';
import { addBase64Prefix } from '../../services/addBase64Prefix';
import { close_icon } from '../../assets/base64';

const DropDown: FC<DropDownProps> = ({
  placeholder = 'Select Item',
  items,
  title = 'Please select item',
  dropdownStyle,
  setValues,
  initValue,
  style,
  textStyle,
  customDownButton,
  customUpButton,
  buttonStyle,
  dropDownContainerStyled,
  dropDownContainerTextStyle,
  zIndex = 10,
  ...props
}) => {
  const isDesktop = useIsDesktop();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<DropDownItem>();

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
  }, [setValue, initValue]);

  const renderDropDown = useMemo(() => {
    return (
      <TouchableOpacity
        style={[styles.dropdown, dropdownStyle]}
        onPress={() => {
          setOpen(!open);
        }}
      >
        <View style={{ marginRight: 10 }}>
          <Text style={[styles.textStyled, textStyle]}>
            {value?.label || placeholder}
          </Text>
        </View>
        <Image
          source={
            open == true
              ? customUpButton
                ? customUpButton
                : require('../../assets/up-button.png')
              : customDownButton
              ? customDownButton
              : require('../../assets/down-button.png')
          }
          style={[
            {
              width: 15,
              height: 8,
            },
            buttonStyle,
          ]}
        />
      </TouchableOpacity>
    );
  }, [
    open,
    placeholder,
    value,
    dropdownStyle,
    customDownButton,
    customUpButton,
    buttonStyle,
    textStyle,
  ]);

  const renderPicker = useMemo(() => {
    if (isDesktop()) {
      if (open) {
        return (
          <View style={[styles.dropDownContainer, dropDownContainerStyled]}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              {items.map((item) => (
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                  }}
                  key={item.label}
                  onPress={() => {
                    setValue(item);
                    if (setValues) setValues(item);
                    setOpen(false);
                  }}
                >
                  <Text style={[styles.textStyled, dropDownContainerTextStyle]}>
                    {item.label}
                  </Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        );
      }
    } else {
      return (
        <Modal animationType="slide" visible={open}>
          <View
            style={[styles.headerModalWrapper, IS_IOS && { paddingTop: 60 }]}
          >
            <Text style={[styles.textStyled, { fontSize: 20 }]}>{title}</Text>
            <TouchableOpacity
              onPress={() => {
                setOpen(false);
              }}
            >
              <Image
                source={
                  IS_WEB
                    ? addBase64Prefix(close_icon)
                    : require('../../assets/close-icon.png')
                }
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.RED_PRIMARY,
                }}
              />
            </TouchableOpacity>
          </View>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
          >
            {items.map((item) => (
              <TouchableOpacity
                style={styles.itemWrapper}
                key={item.label}
                onPress={() => {
                  setValue(item);
                  if (setValues) setValues(item);
                  setOpen(false);
                }}
              >
                <Text style={styles.textStyled}>{item.label}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </Modal>
      );
    }
  }, [
    open,
    items,
    isDesktop,
    title,
    setValues,
    dropDownContainerStyled,
    dropDownContainerTextStyle,
  ]);

  return (
    <View
      style={[
        {
          zIndex,
        },
        style,
      ]}
    >
      {renderDropDown}
      {renderPicker}
    </View>
  );
};

export default DropDown;
