import { useCallback } from 'react';
import { useWindowDimensions } from 'react-native';
import { MIN_DESKTOP_WIDTH } from '../constants/platform';

export const useIsDesktop = () => {
  const { width, scale } = useWindowDimensions();
  // Dimensions.get('window');
  // console.log('SFFEDF', scale);
  return useCallback(
    () => width > MIN_DESKTOP_WIDTH,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width > MIN_DESKTOP_WIDTH]
  );
};
