import { IFormData } from '../../components/FormHelper/FormHelper.types';
import { IBookedEvent } from '../../types';
import { IStatement } from '../../types/auth.types';
import { IBookedDining } from '../../types/dining.types';
import { IBookedSport } from '../../types/sports.types';
import instance from './axios';

type BaseResponse = {
  code: string;
  message: string;
  data?: {
    status: number;
  };
};

const ENDPOINTS = {
  REGISTER: '/auth/register',
  REGISTER_VALIDATION: '/auth/register-validation',
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_USERNAME: '/auth/forgot-username',
  PROFILE: '/account/profile',
  RESET_PASSWORD: '/auth/reset-password',
  ACCOUNT_STATEMENT: '/account/my-account',
  MY_BOOKING: 'account/my-booking',
  CANCEL_BOOKING: '/account/cancel-booking',
  DELETE_ACCOUNT: '/account/me',
  ECARD_QRCODE: 'account/ecard-qrcode',
};

export type GetMyAccountProps = {
  year: number;
  month: number;
};

export type GetMyBookingProps = {
  type: 'upcoming' | 'past';
};

export type CancelBookingProps = {
  id: number | string;
  type: string;
};

const register = (data: IFormData) => {
  return instance.post<BaseResponse>(ENDPOINTS.REGISTER, data);
};

const registerValidation = (data: IFormData) => {
  return instance.post<BaseResponse>(ENDPOINTS.REGISTER_VALIDATION, data);
};

const login = (data: IFormData) => {
  return instance.post<any>(ENDPOINTS.LOGIN, data);
};

const forgotPassword = (data: IFormData) => {
  return instance.post<BaseResponse>(ENDPOINTS.FORGOT_PASSWORD, data);
};

const resetPassword = (data: IFormData) => {
  return instance.post<BaseResponse>(ENDPOINTS.RESET_PASSWORD, data);
};

const deleteAccount = () => {
  return instance.post<BaseResponse>(ENDPOINTS.DELETE_ACCOUNT);
};

const getProfile = () => {
  return instance.get(ENDPOINTS.PROFILE);
};

const updateProfile = (data: IFormData) => {
  return instance.post<BaseResponse>(ENDPOINTS.PROFILE, data);
};

const forgotUsername = (data: IFormData) => {
  return instance.post<BaseResponse>(ENDPOINTS.FORGOT_USERNAME, data);
};

const getMyAccount = ({ year, month }: GetMyAccountProps) => {
  return instance.get<{
    user_login: number;
    user_nicename: number;
    user_email: number;
    display_name: number;
    mbnum: number;
    avatar: number;
    bbalance: number;
    statements: IStatement[];
  }>(ENDPOINTS.ACCOUNT_STATEMENT, {
    params: { year, month },
  });
};

const getMyBooking = ({ type }: GetMyBookingProps) => {
  return instance.get<{
    sports: IBookedSport[];
    dining: IBookedDining[];
    private: IBookedDining[];
    event: IBookedEvent[];
  }>(ENDPOINTS.MY_BOOKING, {
    params: { type },
  });
};

const cancelBooking = (data: IFormData) => {
  return instance.post<any>(ENDPOINTS.CANCEL_BOOKING, data);
};

const getECardQRCode = () => {
  return instance.get(ENDPOINTS.ECARD_QRCODE);
};

export const AuthApi = {
  register,
  registerValidation,
  login,
  forgotPassword,
  forgotUsername,
  getProfile,
  updateProfile,
  resetPassword,
  getMyAccount,
  getMyBooking,
  cancelBooking,
  deleteAccount,
  getECardQRCode,
};
