import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import React, { FC, useState } from 'react';
import { STYLE_VARS } from '../../constants/style-vars';
import { Image } from '../Image';
import dayjs from 'dayjs';
import { COLORS } from '../../constants';
import { Calendar } from '../Calendar';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { TextInput } from '../TextInput/TextInput';

export interface InputDatePickerProps {
  date: string;
  placeholder?: string;
  onChangeDate: (date: string) => void;
  inputStyle?: ViewStyle;
  style?: ViewStyle;
}

const InputDatePicker: FC<InputDatePickerProps> = ({
  date,
  placeholder,
  //haveCalendar = false,
  onChangeDate,
  inputStyle,
  style,
}) => {
  const isDesktop = useIsDesktop();

  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  return (
    <View style={{ position: 'relative', zIndex: 1 }}>
      <TouchableOpacity
        onPress={() => {
          setShowCalendar(!showCalendar);
        }}
        style={[
          {
            zIndex: 1,
          },
          style,
        ]}
      >
        <TextInput
          style={[
            {
              width: isDesktop() ? 400 : '100%',
              borderWidth: 1,
              borderColor: '#ccc',
              borderStyle: 'solid',
              paddingHorizontal: 16,
              letterSpacing: 1.2,
              borderRadius: 6,
              fontSize: 16,
              height: 50,
            },
            inputStyle,
          ]}
          editable={false}
          placeholder={placeholder ? placeholder : ''}
          value={date.length == 0 ? '' : dayjs(date).format('DD/MM/YYYY')}
          placeholderTextColor={COLORS.GREY}
        />
        <Image
          source={require('../../assets/calendar-icon.png')}
          style={{
            position: 'absolute',
            width: 23,
            height: 23,
            right: 12,
            top: -37,
          }}
        />
      </TouchableOpacity>
      {showCalendar && (
        <View
          style={{
            zIndex: 100000,
            width: 300,
            position: 'absolute',
            backgroundColor: COLORS.WHITE,
            right: 0,
            top: 50,
            shadowColor: '#000000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.5,
            shadowRadius: 3,
            elevation: 3,
            padding: 20,
            borderRadius: 8,
          }}
        >
          <Calendar
            onChangeDate={onChangeDate}
            initDate={date}
            setShowCalendar={setShowCalendar}
            showCalendar={showCalendar}
          />
        </View>
      )}
    </View>
  );
};

export default InputDatePicker;

const styles = StyleSheet.create({
  arrowButton: {
    padding: 3,
  },
  arrowStyle: {
    height: 13,
    width: 10,
    resizeMode: 'contain',
    tintColor: STYLE_VARS.primaryColor,
  },
});
