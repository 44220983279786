import {
  CommonActions,
  NavigationContainerRef,
  StackActions,
} from '@react-navigation/native';
import { RootStackParamList } from '../../navigation/types';

type RouteParams<T extends keyof RootStackParamList> = RootStackParamList[T];

class NavigationService {
  private static navigationRef: React.RefObject<
    NavigationContainerRef<RootStackParamList>
  >;

  static initialize(
    ref: React.RefObject<NavigationContainerRef<RootStackParamList>>
  ) {
    NavigationService.navigationRef = ref;
  }

  static navigate<T extends keyof RootStackParamList>(
    routeName: keyof RootStackParamList,
    params?: RouteParams<T>
  ) {
    if (
      NavigationService.navigationRef &&
      NavigationService.navigationRef.current
    ) {
      NavigationService.navigationRef.current.navigate(
        routeName as never,
        params as never
      );
    }
  }

  static push<T extends keyof RootStackParamList>(
    routeName: keyof RootStackParamList,
    params?: RouteParams<T>
  ) {
    if (
      NavigationService.navigationRef &&
      NavigationService.navigationRef.current
    ) {
      NavigationService.navigationRef.current.dispatch(
        StackActions.push(routeName, params)
      );
    }
  }

  static replace(routeName: string, params?: object) {
    if (
      NavigationService.navigationRef &&
      NavigationService.navigationRef.current
    ) {
      NavigationService.navigationRef.current.dispatch(
        StackActions.replace(routeName, params)
      );
    }
  }

  static goBack() {
    NavigationService.navigationRef.current?.dispatch(CommonActions.goBack());
  }

  static pop() {
    if (
      NavigationService.navigationRef &&
      NavigationService.navigationRef.current
    ) {
      NavigationService.navigationRef.current.dispatch(StackActions.pop());
    }
  }

  static canGoBack() {
    if (
      NavigationService.navigationRef &&
      NavigationService.navigationRef.current
    ) {
      const navigation = NavigationService.navigationRef.current;
      const canGoBack = navigation.canGoBack();
      return canGoBack;
    }

    return false;
  }

  static reset(routeName: keyof RootStackParamList) {
    NavigationService.navigationRef.current?.dispatch(
      StackActions.replace(routeName)
    );
  }
}

export default NavigationService;
