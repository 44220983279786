import React, { FC, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as Keychain from '../../services/keychain';
import { IS_ANDROID, IS_WEB, MAX_WIDTH } from '../../constants/platform';
import { RootState } from '../../services/redux/store';
import { loginUser, updateUser } from '../../services/redux/user';
import { post } from '../../services/api/http';
import { API_URI } from '../../constants/env';
import { AuthProps, IAuthState } from './types';
import { Login } from './components/Login';
import { LostPassword } from './components/LostPassword';
import { CreateAccount } from './components/CreateAccount';
import { Password } from './components/Password';
import { ForgotLogin } from './components/ForgotLogin';
import { Alert } from '../../services/alert';
import { ScrollView, View } from 'react-native';
import { Footer } from '../../components/Footer';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { AuthApi } from '../../services/api/auth.api';
import { logout, setCredential } from '../../services/redux/reducers/AuthSlice';
import { NavigationService } from '../../services/navigation';
import { updateCart } from '../../services/redux/reducers/CartSlice';
import { setHeaderConfigAxios } from '../../services/api/axios';
import { setIsShowStart } from '../../services/redux/reducers/SwitchECardSlice';

const initialAuthState: IAuthState = {
  submitDisabled: false,
  mode: 'login',
  login: '',
  email: '',
  mbnum: '',
  code: '',
  password: '',
  password2: '',
  codeEmail: '',
};

export const Auth: FC<AuthProps> = ({ navigation, route }) => {
  const [state, setState] = useState(initialAuthState);
  const dispatch = useDispatch();
  const { fcmToken } = useSelector((redux: RootState) => redux.authReducer);
  useEffect(() => {
    // if (user.token) {
    //   post(API_URI + '/wp-json/echo/v1/logout');
    // }
    dispatch(logout());
    dispatch(setIsShowStart(false));

    // Keychain.hasInternetCredentials('biometrics').then((has) =>
    //   setState((prevState) => ({ ...prevState, hasBiometrics: has as boolean }))
    // );
  }, [dispatch]);

  const updateState = useCallback(
    (state: Partial<IAuthState>) =>
      setState((prevState) => ({ ...prevState, ...state })),
    [setState]
  );

  const onLoginPress = useCallback(async () => {
    switch (state.mode) {
      case 'login':
        if (state.login && state.password) {
          updateState({ submitDisabled: true });
          setHeaderConfigAxios();
          var loginData: { [key: string]: any } = {
            username: state.login,
            password: state.password,
          };
          if (!IS_WEB) {
            loginData.device_token = fcmToken;
            loginData.os = 'Android';
          }
          AuthApi.login(loginData)
            .then(async (authData) => {
              if (authData.data.data && authData.data.data.status === 403) {
                setHeaderConfigAxios();
                updateState({ submitDisabled: false });
                Alert.alert('Error', authData.data.message);
              } else {
                setHeaderConfigAxios(authData.data.token);
                AuthApi.getProfile()
                  .then((res) => {
                    updateState({ submitDisabled: false });
                    dispatch(
                      setCredential({
                        token: authData.data.token,
                        info: {
                          ...res.data,
                          id: res.data.ID,
                        },
                      })
                    );
                    dispatch(updateCart([]));
                    NavigationService.push('Root', {
                      screen: 'HomeTab',
                    });
                  })
                  .catch((error) => {
                    setHeaderConfigAxios();
                    updateState({ submitDisabled: false });
                    Alert.alert('Error', error.message);
                  });
              }
              // if (this.props.route === 'login') this.props.replaceRoute('home');

              // if (IS_IOS && user.biometrics === undefined) {
              //   Alert.alert('Biometrics', 'Enable a faster biometrics login?', [
              //     {
              //       text: 'Enable',
              //       onPress: () => dispatch(updateUser({ biometrics: true })),
              //     },
              //     { text: 'Not Now', onPress: () => {} },
              //   ]);
              // }
              //dispatch(loginUser({ ...authData, password: state.password }));
            })
            .catch((error) => {
              updateState({ submitDisabled: false });
              Alert.alert('Error', error.message);
            });
        }
        break;
      case 'lost':
        if (state.login !== '') {
          updateState({ submitDisabled: true });

          AuthApi.forgotPassword({ login: state.login })
            .then((value) => {
              updateState({
                submitDisabled: false,
                mode: 'resetPassword',
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.message);
              updateState({ submitDisabled: false });
            });
        }
        break;
      case 'create':
        if (!state.mbnum || state.mbnum === '') {
          Alert.alert('Error', 'Member Number is empty');
        } else if (!state.email || state.email === '') {
          Alert.alert('Error', 'E-mail is empty');
        } else if (!state.login || state.login === '') {
          Alert.alert('Error', 'Desired Login is empty');
        } else {
          updateState({ submitDisabled: true });
          AuthApi.register({
            mbnum: state.mbnum,
            email: state.email,
            login: state.login,
          })
            .then((value) => {
              updateState({
                submitDisabled: false,
                mode: 'password',
                //codeEmail: value.email,
              });
              // }
            })
            .catch((error) => {
              Alert.alert('Error', error.message);
              updateState({ submitDisabled: false });
            });
        }
        break;
      case 'resetPassword':
        if (!state.password || state.password === '') {
          Alert.alert('Error', 'Password is empty');
        } else if (state.password !== state.password2) {
          Alert.alert('Error', "Passwords don't match");
        } else if (state.password.length < 8) {
          Alert.alert('Error', 'Password must be at least 8 characters long');
        } else if (!/[A-Z]/.test(state.password)) {
          Alert.alert(
            'Error',
            'At least one capital letter is required in the password'
          );
        } else if (!/[0-9\W_]/.test(state.password)) {
          Alert.alert(
            'Error',
            'At least one number or special character is required in the password'
          );
        } else {
          updateState({ submitDisabled: true });
          AuthApi.resetPassword({
            login: state.login,
            code: state.code,
            password: state.password,
          })
            .then((value) => {
              Alert.alert(
                'Success',
                'The password has been successfully updated. You may now log in.'
              );
              updateState({
                submitDisabled: false,
                mode: 'login',
                code: '',
                password: '',
                password2: '',
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.message);
              updateState({ submitDisabled: false });
            });
        }
        break;
      case 'password':
        if (!state.password || state.password === '') {
          Alert.alert('Error', 'Password is empty');
        } else if (state.password !== state.password2) {
          Alert.alert('Error', "Passwords don't match");
        } else if (state.password.length < 8) {
          Alert.alert('Error', 'Password must be at least 8 characters long');
        } else if (!/[A-Z]/.test(state.password)) {
          Alert.alert(
            'Error',
            'At least one capital letter is required in the password'
          );
        } else if (!/[0-9\W_]/.test(state.password)) {
          Alert.alert(
            'Error',
            'At least one number or special character is required in the password'
          );
        } else {
          updateState({ submitDisabled: true });
          AuthApi.registerValidation({
            login: state.login,
            code: state.code,
            password: state.password,
          })
            .then((value) => {
              Alert.alert(
                'Success',
                'The password has been successfully updated. You may now log in.'
              );
              updateState({
                submitDisabled: false,
                mode: 'login',
                code: '',
                password: '',
                password2: '',
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.message);
              updateState({ submitDisabled: false });
            });
        }
        break;
      case 'forgotLogin':
        if (state.mbnum !== '' && state.email !== '') {
          updateState({ submitDisabled: true });

          AuthApi.forgotUsername({
            mbnum: state.mbnum,
            email: state.email,
          })
            .then((value) => {
              Alert.alert(
                'Success',
                'Please check your email for the next step.'
              );
              updateState({
                submitDisabled: false,
                mode: 'login',
                code: '',
                password: '',
                password2: '',
              });
            })
            .catch((error) => {
              Alert.alert('Error', error.message);
              updateState({ submitDisabled: false });
            });
        }
        break;
    }
  }, [
    dispatch,
    state.code,
    state.email,
    state.login,
    state.mbnum,
    state.mode,
    state.password,
    state.password2,
    updateState,
    //user.biometrics,
    fcmToken,
  ]);

  const onBiometricPress = useCallback(async () => {
    Keychain.getInternetCredentials('biometrics')
      .then((credentials) => {
        if (!credentials) {
          throw new Error('Biometric login is not available');
        }

        updateState({ submitDisabled: true });

        var data = JSON.parse(credentials.password);

        if (!data.password) {
          throw new Error('Biometric login is not available');
        }

        post(API_URI + '/wp-json/kcc/v1/login', {
          username: data.card,
          password: data.password,
        }).then((authData) => {
          if (authData.data && authData.data.status === 403) {
            throw new Error(authData.message);
          }

          dispatch(loginUser(authData));
        });
      })
      .catch((err) => {
        updateState({ submitDisabled: false });
        Alert.alert('Error', '' + err);
      });
  }, [dispatch, updateState]);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (route?.params?.screen) {
      updateState({ mode: route.params.screen });
    }
  }, [route.params, updateState]);

  return (
    <ScrollView>
      <View
        style={[
          {
            paddingTop: isDesktop() ? 40 : 0,
            paddingLeft: 20,
            paddingRight: 20,
            paddingHorizontal: isDesktop() ? '5%' : 0,
            minHeight: 700,
          },
          isDesktop() && {
            maxWidth: MAX_WIDTH,
            width: '100%',
            alignSelf: 'center',
          },
        ]}
      >
        {state.mode === 'login' && (
          <Login
            state={state}
            setState={updateState}
            onLoginPress={onLoginPress}
            onBiometricPress={onBiometricPress}
          />
        )}
        {state.mode === 'lost' && (
          <LostPassword
            state={state}
            setState={updateState}
            onLoginPress={onLoginPress}
          />
        )}
        {state.mode === 'resetPassword' && (
          <Password
            state={state}
            setState={updateState}
            onLoginPress={onLoginPress}
          />
        )}
        {state.mode === 'create' && (
          <CreateAccount
            state={state}
            setState={updateState}
            onLoginPress={onLoginPress}
          />
        )}
        {state.mode === 'password' && (
          <Password
            state={state}
            setState={updateState}
            onLoginPress={onLoginPress}
          />
        )}
        {state.mode === 'forgotLogin' && (
          <ForgotLogin
            state={state}
            setState={updateState}
            onLoginPress={onLoginPress}
          />
        )}
      </View>
      <Footer />
    </ScrollView>
  );
};
