import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDashboardData } from "../../../types/dashboard.types";


interface DashboardState {
  data: IDashboardData;
  isLoading: boolean;
  error: string;
}

const initialState: DashboardState = {
  data: {},
  isLoading: false,
  error: '',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboard: (state, action: PayloadAction<IDashboardData>) => {
      state.data = action.payload;
    },
    setLoadingDashboard: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setDashboard,
  setLoadingDashboard,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;