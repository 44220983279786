import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { FC } from 'react';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
import { NavigationService } from '../../../services/navigation';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { IS_WEB } from '../../../constants/platform';
import { INotice } from '../../../types/notices.types';
import { htmlDecode } from '../../../services/utils/htmlDecode';
import dayjs from 'dayjs';

interface NoticeItemProps {
  item: INotice;
}
const lorem =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
const NoticeItem: FC<NoticeItemProps> = ({ item }) => {
  const isDesktop = useIsDesktop();

  return (
    <View>
      <Text
        style={{
          fontFamily: FONTS.Arial,
          fontWeight: '700',
          fontSize: 20,
          letterSpacing: 1.7,
          color: COLORS.BLACK,
          textTransform: 'uppercase',
          borderBottomWidth: 1,
          borderBottomColor: COLORS.GREY,
          paddingBottom: 15,
          marginBottom: 25,
        }}
        onPress={() => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'NoticeDetail',
            },
          });
        }}
      >
        {item.title}
      </Text>
      <View style={{ flexDirection: 'row', gap: 15 }}>
        <Image
          source={item.img}
          style={[
            isDesktop()
              ? {
                  width: 175,
                  height: 175,
                  borderRadius: 18,
                }
              : {
                  width: 130,
                  height: 130,
                  borderRadius: 18,
                },
          ]}
        />
        <View
          style={{
            flex: 1,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 16,
              color: COLORS.GREY,
              marginBottom: 10,
            }}
          >
            {item.date}
          </Text>
          <View
            style={{
              width: 58,
              height: 2,
              backgroundColor: COLORS.RED_PRIMARY,
              marginBottom: 10,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: isDesktop() ? 16 : 10,
              color: COLORS.GREY,
              marginBottom: isDesktop() ? 30 : 8,
            }}
          >
            {item.content}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              gap: isDesktop() ? 15 : 8,
              alignItems: 'center',
            }}
          >
            <Button
              text="Read more"
              className=""
              style={{
                borderRadius: 16,
                paddingHorizontal: isDesktop() ? 70 : 20,
                paddingVertical: isDesktop() ? 10 : 8,
                backgroundColor: COLORS.RED_PRIMARY,
              }}
              textStyle={{
                textTransform: 'uppercase',
                fontFamily: FONTS.AvenirMedium,
                fontWeight: isDesktop() ? '800' : '500',
                fontSize: isDesktop() ? 19 : 16,
                textAlign: 'center',
                color: COLORS.WHITE,
                letterSpacing: 2,
              }}
              arrowRight={false}
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'NoticeDetail',
                  },
                });
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default NoticeItem;

const styles = StyleSheet.create({});
