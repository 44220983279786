import { IEvent } from './../../../models/IEvents';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProduct } from '../../../models/IProduct';

interface CartState {
  items: IProduct[];
  itemNum: number;
}

const initialState: CartState = {
  items: [],
  itemNum: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<IProduct>) => {
      const currentAmout: number = state.itemNum ? state.itemNum : 0;
      state.items = [...state.items, action.payload];
      state.itemNum = currentAmout + action.payload.amount;
    },
    updateCart: (state, action: PayloadAction<IProduct[]>) => {
      state.items = action.payload;
      state.itemNum = action.payload.reduce((previousItem, item) => {
        return previousItem + item.amount;
      }, 0);
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      const result = state.items.filter((item) => item.id !== action.payload);
      state.items = result;
      state.itemNum = result.reduce((previousItem, item) => {
        return previousItem + item.amount;
      }, 0);
    },
  },
});

export const { addToCart, deleteItem, updateCart } = cartSlice.actions;
export default cartSlice.reducer;
