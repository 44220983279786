import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Linking,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import { MenuItem, MenuProps } from './types';
import { menuStyled as styled } from './styled';
import UserMenu from './components/UserMenu';
import { Footer } from '../../components/Footer';
import MenuCollapse from './components/MenuCollapse';
import { TextInput } from '../../components/TextInput/TextInput';
import { STYLE_VARS } from '../../constants/style-vars';
import { Image } from '../../components/Image';
import { Button } from '../../components/Button';
import { menuItems } from './constants';
import { wooCommerceApi } from '../../services/api/wooCommerceApi';
import { useDispatch } from 'react-redux';
import { setEShopCategories } from '../../services/redux/reducers/EShopCategorySlice';
import { COLORS, FONTS } from '../../constants';
import { upperCase } from 'lodash';
import ECardMember from '../../components/ECardMember/ECardMember';
import { NavigationService } from '../../services/navigation';

const Menu: FC<MenuProps> = ({ navigation }) => {
  const renderMyAccountButtons = useCallback(() => {
    const myAccounts = [
      {
        icon: require('../../assets/messages-1.png'),
        title: 'Messages',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'Messages',
          });
        },
      },
      {
        icon: require('../../assets/booked.png'),
        title: 'Make A Booking',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        },
      },
      {
        icon: require('../../assets/messages-icon.png'),
        title: 'Camps',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        },
      },
      {
        icon: require('../../assets/mountain.png'),
        title: 'My Activities',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MyActivities',
          });
        },
      },
      {
        icon: require('../../assets/lunch.png'),
        title: 'Meals',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MyActivities',
          });
        },
      },
      {
        icon: require('../../assets/user-circle.png'),
        title: 'My Account',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyProfile',
            },
          });
        },
      },
      {
        icon: require('../../assets/letter-round.png'),
        title: 'Contact Us',
        onPress: () => {},
      },
      {
        icon: require('../../assets/logout-icon.png'),
        title: 'Log Out',
        onPress: () => {},
      },
    ];

    return (
      <View
        style={{
          flexDirection: 'column',
          paddingLeft: 20,
        }}
      >
        {myAccounts.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={item.onPress}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 8,
              }}
            >
              <Image
                source={item.icon}
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 15,
                  tintColor: '#505050',
                  resizeMode: 'contain',
                }}
              />
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 20,
                  color: COLORS.GREY,
                  letterSpacing: 0.5,
                }}
              >
                {item.title}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, []);
  const { height: screenHeight } = useWindowDimensions();

  return (
    <ScrollView>
      <View
        style={{
          flexDirection: 'row',
          paddingVertical: 20,
          paddingHorizontal: 20,
          justifyContent: 'space-between',
          elevation: 4,
          shadowColor: '#0C0C0D1A',
          shadowOffset: { width: 0, height: 4 },
          shadowOpacity: 1,
          shadowRadius: 4,
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 20,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
            }}
          >
            WELCOME,{' '}
          </Text>
          LING CHENG
        </Text>
        <TouchableOpacity>
          <Image
            source={require('../../assets/logout-icon.png')}
            style={{ width: 24, height: 24 }}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          paddingHorizontal: 40,
          paddingVertical: 40,
          backgroundColor: COLORS.LIGHT_RED,
          height: screenHeight,
        }}
      >
        <View
          style={{
            paddingBottom: 15,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.BLACK,
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
            }}
          >
            MENU
          </Text>
        </View>
        {renderMyAccountButtons()}
      </View>
      <Footer />
    </ScrollView>
  );
};
export default Menu;
