import { StyleSheet } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { STYLE_VARS } from '../../constants/style-vars';

export const homeStyles = StyleSheet.create({
  menuuserwrap: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 20,
    alignItems: 'center',
  },
  menuuserinfo: {
    flex: 1,
    alignItems: 'flex-start',
  },
  welcomeText: {
    fontSize: 20,
    fontFamily: FONTS.Arial,
    fontWeight: '700',
    letterSpacing: 2,
    color: COLORS.BLACK,
  },
  userName: {
    fontSize: 20,
    color: COLORS.BLACK,
    fontFamily: FONTS.AvenirMedium,
  },
  avatar: {
    width: 50,
    height: 59,
  },
  notices: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  noticesWeb: {
    marginTop: 30,
  },
  swiperWrap: {},
  slideContainer: {},
  row: {
    flexDirection: 'row',
  },
  li: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  eventImage: {
    height: 80,
  },
  promotions: {
    marginVertical: 50,
  },
  promotion_image: {
    width: '100%',
    height: 180,
  },
  more: {
    color: STYLE_VARS.fccBlue,
    fontWeight: 'bold',
  },
  dot: {
    width: 8,
    height: 8,
    backgroundColor: STYLE_VARS.fccBlue,
    borderRadius: 4,
    marginRight: 10,
  },
  events: {
    marginBottom: 50,
  },
  news: {
    padding: 20,
    backgroundColor: STYLE_VARS.fccLightGrey,
    marginBottom: 50,
  },
  newsLinks: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingTop: 15,
    paddingBottom: 20,
  },
  newsActive: {
    textDecorationColor: STYLE_VARS.fccBlue,
    textDecorationStyle: 'solid',
    textDecorationLine: 'underline',
    opacity: 1,
  },
  newsDot: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
});
