import React, { FC } from 'react';
import { Image, View } from 'react-native';
import { Text } from '../../../components/Text/Text';
import { COLORS, FONTS } from '../../../constants';

export const Notices: FC = () => {
  return (
    <>
      <View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <Image
            source={require('../../../assets/notice-icon.png')}
            style={{
              width: 30,
              height: 21,
              marginRight: 15,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 20,
              color: COLORS.BLACK,
            }}
            numberOfLines={1}
          >
            Thanks for your meal order Order #56789
          </Text>
        </View>
      </View>
    </>
  );
};
