import { FC, useCallback, useState } from 'react';
import { ProductWooItemListProps } from './ProductWooItemList.type';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { STYLE_VARS } from '../../../../constants/style-vars';
import { Button } from '../../../../components/Button';
import { styles } from './ProductWooItemList.styles';
import { useIsDesktop } from '../../../../hooks/useIsDeskTop';
import { COLORS, FONTS } from '../../../../constants';
import RenderHTML from 'react-native-render-html';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/redux';
import { IProduct } from '../../../../models/IProduct';
import {
  addToCart,
  updateCart,
} from '../../../../services/redux/reducers/CartSlice';
import { NavigationService } from '../../../../services/navigation';
import { Alert } from '../../../../services/alert';

const ProductWooItemList: FC<ProductWooItemListProps> = ({
  product,
  haveIcon = false,
  categoryName,
}) => {
  const isDesktop = useIsDesktop();
  const [count, setCount] = useState<number>(1);
  const { items } = useSelector((redux: RootState) => redux.cartReducer);
  const dispatch = useDispatch();

  const handleAddCart = useCallback(() => {
    let isAdded = false;

    if (items.filter((e) => e.id === product.id).length > 0) {
      isAdded = true;
    }

    if (isAdded) {
      const newCartData: IProduct[] = items.map((data) => {
        if (data.id === product.id) {
          return {
            ...data,
            amount: data.amount + count,
          };
        }
        return data;
      });
      dispatch(updateCart(newCartData));
    } else {
      dispatch(
        addToCart({
          id: product.id,
          photo: product.images[0]?.src ?? '',
          totalAmount: count,
          amount: count,
          price: parseFloat(product.price),
          type: categoryName ?? '',
          productName: product.name,
        })
      );
    }

    Alert.alert('Product added to your cart!', '', [
      {
        text: 'Go to Checkout',
        onPress: () => {},
      },
      {
        text: 'Continue Shopping',
        onPress: () => {},
      },
    ]);
  }, [product, count, items, categoryName, dispatch]);

  const handleChangeAmount = useCallback(
    (type: 'increase' | 'decrease') => {
      console.log(product.id);
      if (type === 'decrease' && count > 1) {
        setCount(count - 1);
      }

      if (
        type === 'increase'
        // && product.stock_status !== 'outofstock'
        // && product.stock_quantity > count
      ) {
        setCount(count + 1);
      }
    },
    [count, product]
  );

  if (isDesktop()) {
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingVertical: 20,
            borderTopColor: COLORS.DARK_GREY,
            borderTopWidth: 1,
          },
        ]}
      >
        <View
          style={{
            width: '20%',
          }}
        >
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <TouchableOpacity onPress={() => {}}>
              <Text
                style={[
                  styles.title,
                  {
                    fontSize: 18,
                    letterSpacing: 1,
                    marginBottom: 10,
                  },
                ]}
              >
                {/* {(haveIcon && icon)
                  ? (
                    <View>
                      <Image
                        style={styles.titleIcon}
                        source={icon}
                      />
                    </View>
                  )
                  : <></>
                } */}
                {product.name}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            width: '25%',
          }}
        >
          <Text
            style={[
              styles.description,
              {
                fontSize: 16,
              },
            ]}
            numberOfLines={2}
          >
            <RenderHTML
              source={{ html: product.short_description }}
              tagsStyles={{
                p: {
                  marginTop: 0,
                  textTransform: 'uppercase',
                  fontFamily: FONTS.AvenirMedium,
                  color: STYLE_VARS.fccDarkerGrey,
                  fontWeight: '500',
                  fontSize: 14,
                  marginBottom: 0,
                },
              }}
            />
          </Text>
        </View>
        <View
          style={{
            width: '10%',
          }}
        >
          <Text
            style={[
              styles.price,
              {
                textAlign: 'left',
                fontSize: 20,
              },
            ]}
          >
            ${product.price}
          </Text>
        </View>
        <View
          style={{
            width: '15%',
            flexDirection: 'column',
          }}
        >
          <View
            style={[
              styles.countWrapper,
              {
                justifyContent: 'center',
                marginBottom: 10,
              },
            ]}
          >
            <View style={styles.count}>
              <TouchableOpacity
                activeOpacity={1}
                style={styles.countBtn}
                onPress={() => {
                  handleChangeAmount('decrease');
                }}
              >
                <Text style={styles.countBtnText}>-</Text>
              </TouchableOpacity>
              <Text style={styles.countText}>{count}</Text>
              <TouchableOpacity
                activeOpacity={1}
                style={styles.countBtn}
                onPress={() => {
                  handleChangeAmount('increase');
                }}
              >
                <Text style={styles.countBtnText}>+</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '15%',
          }}
        >
          <Button
            text={'ADD TO CART'}
            style={STYLE_VARS.fullWGreenButton}
            onPress={() => {
              handleAddCart();
            }}
          />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.wrapper}>
      <View style={styles.titleWrapper}>
        <View style={{ width: '45%', flexDirection: 'row' }}>
          <Text style={styles.title}>
            {/* {(haveIcon && icon)
              ? (
                <View>
                  <Image
                    style={styles.titleIcon}
                    source={icon}
                  />
                </View>
              )
              : <></>
            } */}
            {product.name}
          </Text>
        </View>
        <View style={{ width: '30%' }}>
          <Text style={styles.price}>${product.price}</Text>
        </View>
      </View>
      <View style={{ marginBottom: 10 }}>
        <Text style={styles.description} numberOfLines={2}>
          <RenderHTML
            source={{ html: product.short_description }}
            tagsStyles={{
              p: {
                marginTop: 0,
                marginBottom: 0,
                textTransform: 'uppercase',
                fontFamily: FONTS.AvenirMedium,
                color: STYLE_VARS.fccDarkerGrey,
                fontWeight: '500',
                fontSize: 11,
              },
            }}
          />
        </Text>
      </View>
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <View style={styles.countWrapper}>
          <View style={styles.count}>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.countBtn}
              onPress={() => {
                handleChangeAmount('decrease');
              }}
            >
              <Text style={styles.countBtnText}>-</Text>
            </TouchableOpacity>
            <Text style={styles.countText}>{count}</Text>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.countBtn}
              onPress={() => {
                handleChangeAmount('increase');
              }}
            >
              <Text style={styles.countBtnText}>+</Text>
            </TouchableOpacity>
          </View>
          <View style={{ width: '55%', flexDirection: 'row' }}>
            <Button
              text="ADD TO CART"
              style={STYLE_VARS.fullWGreenButton}
              onPress={() => {
                handleAddCart();
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default ProductWooItemList;
