import { IWeather } from '../../../models/IWeather';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchWeather } from './ActionCreators';
import { IS_WEB } from '../../../constants/platform';
import { addBase64Prefix } from '../../addBase64Prefix';
import { weather } from '../../../assets/base64';

interface DateState {
  weatherData: IWeather;
  icon?: any;
  isLoading: boolean;
  error: string;
}

const initialState: DateState = {
  icon: IS_WEB
    ? addBase64Prefix(weather)
    : require('../../../assets/sunny-icon.png'),
  weatherData: {},
  isLoading: false,
  error: '',
};

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWeather.fulfilled.type]: (state, action: PayloadAction<IWeather>) => {
      state.isLoading = false;
      state.error = '';
      state.weatherData = action.payload;
      switch (action.payload.current_weather?.weathercode) {
        case 0:
        case 1:
          require('../../../assets/sunny-icon.png');
          break;
        case 2:
        case 3:
          state.icon = IS_WEB
            ? addBase64Prefix(weather)
            : require('../../../assets/weather.png');
        default:
          state.icon = IS_WEB
            ? addBase64Prefix(weather)
            : require('../../../assets/weather.png');
          break;
      }
    },
    [fetchWeather.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchWeather.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default weatherSlice.reducer;
