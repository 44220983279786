import { IDate } from '../../../models/IDate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDate } from './ActionCreators';

interface DateState {
  dateData: IDate;
  isLoading: boolean;
  error: string;
}

const initialState: DateState = {
  dateData: {},
  isLoading: false,
  error: '',
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDate.fulfilled.type]: (state, action: PayloadAction<IDate>) => {
      state.isLoading = false;
      state.error = '';
      state.dateData = action.payload;
    },
    [fetchDate.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchDate.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default dateSlice.reducer;
