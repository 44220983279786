import { Dimensions, StyleSheet } from 'react-native';
import { STYLE_VARS } from '../../../constants/style-vars';
import { COLORS, FONTS } from '../../../constants';

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: 10,
    paddingVertical: 30,
  },
  menuHeaderContainer: {
    position: 'relative',
  },
  menuHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    paddingHorizontal: 60,
    position: 'relative',
  },
  menuIcon: {
    width: 28,
    height: 28,
  },
  menuHeaderText: {
    fontSize: 22,
    textAlign: 'center',
    letterSpacing: 2,
    fontFamily: STYLE_VARS.iowanOldStyleFont,
    color: STYLE_VARS.primaryColor,
    textTransform: 'uppercase',
  },
  menuHeaderOption: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  optionIcon: {
    margin: 5,
    width: 12,
    height: 16,
  },
  menuCategoriesWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingVertical: 30,
  },
  menuCategoryItem: {
    width: '25%',
    paddingBottom: 15,
  },
  menuCategoryIconWrapper: {
    borderWidth: 2,
    borderRadius: 50,
    borderColor: STYLE_VARS.primaryColor,
    width: 60,
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuCategoryIcon: {
    resizeMode: 'contain',
    width: 30,
    height: 30,
  },
  menuCategoryNameWrapper: {
    flexGrow: 1,
    marginTop: 8,
  },
  menuCategoryName: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: FONTS.AvenirMedium,
    color: STYLE_VARS.fccDarkerGrey,
    fontSize: 12,
  },
  productsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    // gap: 10,
  },
  productContainer: {
    flexBasis: '50%',
    marginBottom: 20,
  },
  loadMoreCtn: {
    alignItems: 'center',
    marginBottom: 50,
    marginTop: 30,
  },
  loadMoreBtn: {
    backgroundColor: STYLE_VARS.primaryColor,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 10,
    gap: 10,
    borderRadius: 10,
  },
  loadMoreText: {
    fontFamily: FONTS.AvenirMedium,
    fontSize: 15,
    fontWeight: '800',
    textTransform: 'uppercase',
    color: STYLE_VARS.fccWhite,
  },
  selectWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  selectedBox: {
    width: 18,
    height: 18,
    borderWidth: 2,
    borderColor: COLORS.WHITE,
    borderRadius: 2,
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  selectedSubText: {
    fontSize: 12,
    fontFamily: FONTS.AvenirMedium,
    color: COLORS.WHITE,
    fontWeight: '500',
    textTransform: 'uppercase',
  },
});

export default styles;
