import React, { FC, useState } from 'react';
import {
  Button as RNButton,
  TextStyle,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Image,
} from 'react-native';
import { authStyles } from '../styles';
import { IAuthProps } from '../types';
import { Text } from '../../../components/Text/Text';
import { TextInput } from '../../../components/TextInput/TextInput';
import {
  getContentWidth,
  getHeight,
  IS_IOS,
  IS_WEB,
} from '../../../constants/platform';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { COLORS } from '../../../constants';
import { addBase64Prefix } from '../../../services/addBase64Prefix';
import { eye, hidden_eye } from '../../../assets/base64';

export const Login: FC<IAuthProps> = ({
  state,
  setState,
  onLoginPress,
  onBiometricPress,
}) => {
  const isDesktop = useIsDesktop();
  const [isHidePassword, setIsHidePassword] = useState<boolean>(true);

  return (
    <View style={authStyles.container}>
      <Text
        style={[
          authStyles.title,
          { textAlign: isDesktop() ? 'left' : 'center' },
        ]}
      >
        MEMBER LOGIN
      </Text>
      <TextInput
        style={
          (isDesktop()
            ? STYLE_VARS.textinputweb
            : STYLE_VARS.textinput) as TextStyle
        }
        value={state.login}
        onChangeText={(text) => setState({ login: text })}
        placeholder="USERNAME"
        placeholderTextColor="grey"
      />
      <View>
        <TextInput
          style={
            (isDesktop()
              ? STYLE_VARS.textinputweb
              : STYLE_VARS.textinput) as TextStyle
          }
          secureTextEntry={isHidePassword}
          value={state.password}
          onChangeText={(text) => setState({ password: text })}
          onSubmitEditing={onLoginPress}
          placeholder="PASSWORD"
          placeholderTextColor="grey"
        />
        <TouchableOpacity
          style={[
            {
              position: 'absolute',
              right: 14,
              top: 15,
            },
            isDesktop() && {
              left: 466,
              top: 14,
            },
          ]}
          onPress={() => {
            setIsHidePassword(!isHidePassword);
          }}
        >
          <Image
            style={{
              width: 20,
              height: 20,
              tintColor: COLORS.GREEN,
            }}
            source={
              isHidePassword
                ? IS_WEB
                  ? addBase64Prefix(hidden_eye)
                  : require('../../../assets/hidden-eye.png')
                : IS_WEB
                ? addBase64Prefix(eye)
                : require('../../../assets/eye.png')
            }
          />
        </TouchableOpacity>
      </View>
      <View
        style={[
          authStyles.submitwrap,
          { width: isDesktop() ? 300 : undefined },
        ]}
      >
        <Button
          text="SUBMIT"
          style={{
            paddingVertical: 12,
            paddingHorizontal: 24,
            alignSelf: isDesktop() ? 'flex-start' : 'center',
          }}
          onPress={onLoginPress}
          disabled={state.submitDisabled}
        />
      </View>

      {/* {IS_IOS && (
        <View
          style={[
            authStyles.submitwrap,
            { width: isDesktop() ? 300 : undefined },
          ]}
        >
          <RNButton
            disabled={state.submitDisabled || !state.hasBiometrics}
            title="Biometric Login"
            color={STYLE_VARS.primaryColor}
            onPress={onBiometricPress}
          />
        </View>
      )} */}

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({
            mode: 'create',
            mbnum: '',
            email: '',
            login: '',
            password: '',
            password2: '',
          });
        }}
      >
        <Text>SIGN UP</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({ mode: 'lost' });
        }}
      >
        <Text>FORGOT PASSWORD</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({ mode: 'forgotLogin' });
        }}
      >
        <Text>FORGOT USERNAME</Text>
      </TouchableOpacity>

      {state.submitDisabled && (
        <View
          style={[
            authStyles.activityIndWrap,
            {
              width:
                isDesktop() && getContentWidth() >= 768
                  ? 340
                  : getContentWidth(),
              height:
                isDesktop() && getContentWidth() >= 768 ? 320 : getHeight(),
            },
          ]}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
};
