import { StyleSheet } from 'react-native';
import { FONTS } from './fonts';
import { STYLE_VARS } from './style-vars';

export const SHARE_STYLE = StyleSheet.create({
  titleText: {
    fontSize: 24,
    letterSpacing: 2,
    fontFamily: FONTS.IowanOldStyleBTRoman,
    color: STYLE_VARS.textColor,
    textTransform: 'uppercase',
  },
});
