import { Dimensions, StyleSheet } from 'react-native';
import { STYLE_VARS } from '../../../../constants/style-vars';
import { COLORS, FONTS } from '../../../../constants';

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    width: '100%',
    padding: 10,
    borderWidth: 1,
    borderColor: STYLE_VARS.fccGrayBorder,
  },
  imageWrapper: {
    width: '100%',
    height: 100,
  },
  image: {
    flex: 1,
    width: '100%',
    resizeMode: 'cover',
    height: 100,
  },
  imageList: {
    width: '30%',
    height: 100,
    borderWidth: 1,
    borderColor: STYLE_VARS.fccGrayBorder,
    resizeMode: 'contain',
  },
  contentContainer: {
    flexDirection: 'row',
    gap: 10,
  },
  detailContainer: {
    flex: 1,
    // backgroundColor: 'red',
  },
  name: {
    marginTop: 10,
    fontFamily: FONTS.AvenirMedium,
    fontSize: 13,
    textAlign: 'center',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: STYLE_VARS.primaryColor,
  },
  desc: {
    textTransform: 'uppercase',
    marginBottom: 10,
    fontFamily: FONTS.AvenirMedium,
    fontSize: 13,
  },
  wrp: {
    paddingTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  countContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 10,
  },
  countBtn: {
    borderWidth: 1,
    borderColor: STYLE_VARS.fccDarkGrey,
    width: 20,
    height: 20,
  },
  conntBtnText: {
    fontSize: 14,
    color: COLORS.BLACK,
    textAlign: 'center',
  },
  countNumber: {
    fontSize: 12,
    color: COLORS.BLACK,
    fontFamily: STYLE_VARS.avenirMediumFont,
  },
  priceText: {
    fontFamily: STYLE_VARS.avenirMediumFont,
    fontWeight: '500',
    letterSpacing: 1,
    fontSize: 12,
    color: STYLE_VARS.primaryColor,
    flex: 1,
  },
  buttonCtn: {
    alignItems: 'center',
  },
  button: {
    flexDirection: 'row',
    backgroundColor: STYLE_VARS.primaryColor,
    paddingHorizontal: 10,
    paddingVertical: 10,
    alignItems: 'center',
    gap: 8,
    borderRadius: 10,
  },
  buttonText: {
    fontFamily: STYLE_VARS.avenirMediumFont,
    fontSize: 13,
    color: STYLE_VARS.fccWhite,
    fontWeight: '800',
    textTransform: 'uppercase',
  },
});

export default styles;
