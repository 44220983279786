import { FC } from 'react';
import { IActivities } from '../../../types/activities.types';
import { Image, Text, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { NavigationService } from '../../../services/navigation';

interface ActivityItemProps {
  item: IActivities;
}

const ActivityItem: FC<ActivityItemProps> = ({ item }) => {
  return (
    <View>
      <Text
        style={{
          fontFamily: FONTS.Arial,
          fontWeight: '700',
          fontSize: 20,
          letterSpacing: 1.7,
          color: COLORS.BLACK,
          textTransform: 'uppercase',
          borderBottomWidth: 1,
          borderBottomColor: COLORS.GREY,
          paddingBottom: 15,
          marginBottom: 15,
        }}
        onPress={() => {
          NavigationService.push('Root', {
            screen: 'MenuTab',
            params: {
              screen: 'MyActivitiesDetail',
            },
          });
        }}
      >
        {item.title}
      </Text>
      <Text
        style={{
          fontFamily: FONTS.AvenirMedium,
          fontWeight: '500',
          fontSize: 16,
          color: COLORS.GREY,
          marginBottom: 10,
        }}
      >
        {item.date}
      </Text>
      <View style={{ flexDirection: 'row', gap: 15 }}>
        <Image
          source={item.img}
          style={{
            width: 175,
            height: 175,
            borderRadius: 18,
          }}
        />
        <View
          style={{
            flex: 1,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '800',
              fontSize: 19,
              letterSpacing: 1,
              marginBottom: 10,
            }}
          >
            {item.name}
          </Text>
          <View
            style={{
              width: 58,
              height: 2,
              backgroundColor: COLORS.RED_PRIMARY,
              marginBottom: 10,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 14,
              color: COLORS.GREY,
              letterSpacing: 1,
              marginBottom: 10,
            }}
          >
            {item.other}
          </Text>
          <View
            style={{
              borderRadius: 18,
              paddingVertical: 2,
              paddingHorizontal: 5,
              backgroundColor: COLORS.SWEET_RED,
              flexDirection: 'row',
              width: 82,
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 12,
                textAlign: 'center',
                color: COLORS.WHITE,
              }}
            >
              {item.hour}
            </Text>
          </View>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 14,
              color: COLORS.GREY,
              letterSpacing: 1,
              marginBottom: 10,
            }}
          >
            {item.place}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default ActivityItem;
