import { FC } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Footer } from '../../components/Footer';
import PageTitle from '../../components/PageTitle';
import { Text } from '../../components/Text';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import BookingCollapse from './components/BookingCollapse';
import { MyBookingsProps } from './types';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import { COLORS, FONTS } from '../../constants';

const MyBookings: FC<MyBookingsProps> = ({ navigation }) => {
  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="MY BOOKINGS" haveGoBackButton={true} />
              <View
                style={{
                  flexDirection: 'column',
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  borderRadius: 18,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    paddingHorizontal: 5,
                    backgroundColor: COLORS.RED_PRIMARY,
                    paddingVertical: 13,
                    borderTopLeftRadius: 18,
                    borderTopRightRadius: 18,
                  }}
                >
                  <View
                    style={{
                      width: '25%',
                    }}
                  >
                    <Text style={styles.tableHeaderText}>Date</Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                    }}
                  >
                    <Text style={styles.tableHeaderText}>Order#</Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                    }}
                  >
                    <Text style={styles.tableHeaderText}>Total</Text>
                  </View>
                  <View
                    style={{
                      width: '25%',
                    }}
                  >
                    <Text style={styles.tableHeaderText}>Status</Text>
                  </View>
                </View>
                <View
                  style={{
                    padding: 10,
                  }}
                >
                  <View style={{ marginBottom: 10 }}>
                    <BookingCollapse />
                  </View>
                  <View>
                    <BookingCollapse />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'MY BOOKINGS'} haveGoBackButton={true} />
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: COLORS.RED_PRIMARY,
          paddingVertical: 10,
          paddingHorizontal: 10,
        }}
      >
        <View
          style={{
            width: '25%',
          }}
        >
          <Text style={[styles.tableHeaderText, { textAlign: 'left' }]}>
            Date
          </Text>
        </View>
        <View
          style={{
            width: '25%',
          }}
        >
          <Text style={styles.tableHeaderText}>Order#</Text>
        </View>
        <View
          style={{
            width: '25%',
          }}
        >
          <Text style={styles.tableHeaderText}>Total</Text>
        </View>
        <View
          style={{
            width: '25%',
          }}
        >
          <Text style={styles.tableHeaderText}>Status</Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'column',
          paddingHorizontal: 5,
          paddingTop: 15,
        }}
      >
        <View style={{ marginBottom: 15 }}>
          <BookingCollapse />
        </View>
        <View style={{ marginBottom: 15 }}>
          <BookingCollapse />
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default MyBookings;

const styles = StyleSheet.create({
  tableHeaderText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 19,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    color: COLORS.WHITE,
    textAlign: 'center',
  },
});
