import {
  events,
  events_white,
  e_shop,
  private_function,
  restaurant_reservation,
} from '../../assets/base64';
import { IS_WEB } from '../../constants/platform';
import { addBase64Prefix } from '../../services/addBase64Prefix';
import { NavigationService } from '../../services/navigation';

export const DASHBOARD_MENU = [
  {
    title: 'NOTICES \n',
    icon: require('../../assets/notices.png'),
    onPress: () => {},
  },
  {
    title: 'MY ACCOUNT\n',
    icon: require('../../assets/my-account.png'),
    onPress: () => {
      NavigationService.push<'Root'>('Root', {
        screen: 'MyAccountTab',
      });
    },
    requiredLogin: true,
  },
  {
    title: 'MY BOOKINGS\n',
    icon: require('../../assets/my-bookings.png'),
    onPress: () => {
      NavigationService.push<'Root'>('Root', {
        screen: 'MyBookingsTab',
      });
    },
    requiredLogin: true,
  },
  {
    title: 'MY PROFILE\n',
    icon: require('../../assets/my-profile.png'),
    onPress: () => {
      NavigationService.push<'Root'>('Root', {
        screen: 'MyAccountTab',
      });
    },
    requiredLogin: true,
  },
  {
    title: 'SERVICES & FORMS\n',
    icon: require('../../assets/services-forms.png'),
    onPress: () => {
      NavigationService.push('ServiceForm');
    },
    requiredLogin: true,
  },
];

export const DASHBOARD_DESTOP_MENU = [
  {
    title: 'MAKE BOOKING',
    icon: require('../../assets/events.png'),
    onPress: () => {
      NavigationService.push('Root', {
        screen: 'BookingList',
      });
    },
  },
  {
    title: 'MESSAGES',
    icon: IS_WEB
      ? addBase64Prefix(events_white)
      : require('../../assets/events.png'),
    onPress: () => {
      NavigationService.push('Root', {
        screen: 'Messages',
      });
    },
  },
  {
    title: 'ACTIVITIES',
    icon: IS_WEB
      ? addBase64Prefix(restaurant_reservation)
      : require('../../assets/restaurant-reservation.png'),
    onPress: () => {
      NavigationService.push('Root', {
        screen: 'MyActivities',
      });
    },
  },
  {
    title: 'MY ACCOUNT',
    icon: IS_WEB
      ? addBase64Prefix(private_function)
      : require('../../assets/private-function.png'),
    onPress: () => {
      NavigationService.push('Root', {
        screen: 'MenuTab',
        params: {
          screen: 'MyProfile',
        },
      });
    },
  },
];
