import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchEvents } from './ActionCreators';
import { IEvent } from '../../../types';
import { PAGE_ITEMS } from '../../../constants';

export type EventFilter = {
  page?: number;
  limit?: number;
  search?: string;
  cats?: string;
  view?: 'calendar' | 'past' | 'upcoming';
  calendarView?: 'day' | 'week' | 'month';
  date?: string;
  direction?: boolean;
};
interface EventState {
  events: IEvent[];
  isLoading: boolean;
  eventFilter: EventFilter;
  error: string;
  total: number;
}

const initialState: EventState = {
  events: [],
  isLoading: false,
  error: '',
  eventFilter: {
    page: 1,
    limit: PAGE_ITEMS,
    cats: '',
  },
  total: 0,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventFilter: (state, action: PayloadAction<EventFilter>) => {
      state.eventFilter = action.payload;
    },
    setEvents: (state, action: PayloadAction<IEvent[]>) => {
      state.events = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoadingEvent: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearEventFilter: (state, action: PayloadAction) => {
      state.events = [];
      state.total = 0;
      state.eventFilter = {
        page: 1,
        limit: PAGE_ITEMS,
        cats: '',
      };
    },
  },
  extraReducers: {
    [fetchEvents.fulfilled.type]: (state, action: PayloadAction<IEvent[]>) => {
      state.isLoading = false;
      state.error = '';
      state.events = action.payload;
    },
    [fetchEvents.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchEvents.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  setEventFilter,
  clearEventFilter,
  setEvents,
  setTotal,
  setLoadingEvent,
} = eventSlice.actions;
export default eventSlice.reducer;
