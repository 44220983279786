import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useCallback, useState } from 'react';
import { ProductWooItemProps } from './ProductWooItem.type';
import styles from './ProductWooItem.style';
import { Button } from '../../../../components/Button';
import { STYLE_VARS } from '../../../../constants/style-vars';
import { NavigationService } from '../../../../services/navigation';
import { COLORS, FONTS } from '../../../../constants';
import { useIsDesktop } from '../../../../hooks/useIsDeskTop';
import { htmlDecode } from '../../../../services/utils/htmlDecode';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../services/redux';
import { IProduct } from '../../../../models/IProduct';
import {
  addToCart,
  updateCart,
} from '../../../../services/redux/reducers/CartSlice';
import RenderHTML from 'react-native-render-html';
import { Alert } from '../../../../services/alert';

const ProductWooItem: FC<ProductWooItemProps> = ({
  type,
  product,
  categoryName,
}) => {
  const [count, setCount] = useState<number>(1);
  const isDesktop = useIsDesktop();
  const { items } = useSelector((redux: RootState) => redux.cartReducer);
  const dispatch = useDispatch();

  const handleAddCart = useCallback(() => {
    let isAdded = false;

    if (items.filter((e) => e.id === product.id).length > 0) {
      isAdded = true;
    }

    if (isAdded) {
      const newCartData: IProduct[] = items.map((data) => {
        if (data.id === product.id) {
          return {
            ...data,
            amount: data.amount + count,
          };
        }
        return data;
      });
      dispatch(updateCart(newCartData));
    } else {
      dispatch(
        addToCart({
          id: product.id,
          photo: product.images[0]?.src ?? '',
          totalAmount: count,
          amount: count,
          price: parseFloat(product.price),
          type: categoryName ?? '',
          productName: product.name,
        })
      );
    }

    Alert.alert('Product added to your cart!', '', [
      {
        text: 'Go to Checkout',
        onPress: () => {},
      },
      {
        text: 'Continue Shopping',
        onPress: () => {},
      },
    ]);
  }, [product, count, items, categoryName, dispatch]);

  const handleChangeAmount = useCallback(
    (type: 'increase' | 'decrease') => {
      if (type === 'decrease' && count > 1) {
        setCount(count - 1);
      }

      if (
        type === 'increase'
        // && product.stock_status !== 'outofstock'
        // && product.stock_quantity > count
      ) {
        setCount(count + 1);
      }
    },
    [count]
  );

  if (isDesktop()) {
    return (
      <View
        style={{
          flex: 1,
          width: '100%',
          padding: 20,
          borderWidth: 1,
          borderColor: STYLE_VARS.fccGrayBorder,
        }}
      >
        <TouchableOpacity
          onPress={() => {}}
          style={{
            width: '100%',
            height: 180,
          }}
        >
          <Image
            style={{ ...styles.image, backgroundColor: COLORS.WHITE }}
            source={{ uri: product.images[0]?.src }}
          />
        </TouchableOpacity>
        <View>
          <Text
            style={{
              marginTop: 10,
              fontFamily: FONTS.AvenirMedium,
              fontSize: 15,
              textAlign: 'left',
              fontWeight: '500',
              textTransform: 'uppercase',
              letterSpacing: 1,
              color: STYLE_VARS.primaryColor,
              marginBottom: 10,
            }}
          >
            {product.name}
          </Text>
          <View
            style={{
              height: 60,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                textTransform: 'uppercase',
                letterSpacing: 1,
                color: COLORS.DARKER_GREY,
                fontSize: 12,
                marginBottom: 30,
              }}
              numberOfLines={2}
            >
              {htmlDecode(product.short_description)}
            </Text>
          </View>
          <View
            style={{
              paddingTop: 10,
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <View style={styles.countContainer}>
              <TouchableOpacity
                onPress={() => {
                  handleChangeAmount('decrease');
                }}
                activeOpacity={1}
                style={{
                  borderWidth: 1,
                  borderColor: STYLE_VARS.fccDarkGrey,
                  width: 25,
                  height: 25,
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: COLORS.BLACK,
                    textAlign: 'center',
                  }}
                >
                  -
                </Text>
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 14,
                  color: COLORS.BLACK,
                  fontFamily: STYLE_VARS.avenirMediumFont,
                  paddingHorizontal: 15,
                }}
              >
                {count}
              </Text>
              <TouchableOpacity
                activeOpacity={1}
                style={{
                  borderWidth: 1,
                  borderColor: STYLE_VARS.fccDarkGrey,
                  width: 25,
                  height: 25,
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
                onPress={() => handleChangeAmount('increase')}
              >
                <Text
                  style={{
                    fontSize: 16,
                    color: COLORS.BLACK,
                    textAlign: 'center',
                  }}
                >
                  +
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                fontFamily: STYLE_VARS.avenirMediumFont,
                fontWeight: '500',
                letterSpacing: 1,
                fontSize: 15,
                color: STYLE_VARS.primaryColor,
                flex: 1,
                textAlign: 'right',
              }}
            >
              {/* {`$${Math.round(price * 100) / 100}`} */}
              {`$${product.price}`}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '45%' }}>
              <Button
                style={STYLE_VARS.unActiveButton}
                text="VIEW MORE"
                arrowStyle={{ tintColor: STYLE_VARS.primaryColor }}
                textStyle={{ color: STYLE_VARS.textColor, fontSize: 10 }}
                className={''}
                onPress={() => {}}
              />
            </View>
            <View style={{ width: '45%' }}>
              <Button
                style={[
                  STYLE_VARS.fullWGreenButton,
                  { borderWidth: 0.5, borderColor: COLORS.GREEN },
                ]}
                text="ADD TO CART"
                textStyle={{ fontSize: 10 }}
                className={''}
                onPress={() => {
                  handleAddCart();
                }}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.wrapper}>
      <View
        style={
          type === 'list'
            ? styles.contentContainer
            : {
                flex: 1,
              }
        }
      >
        {type === 'grid' ? (
          <TouchableOpacity
            style={{
              ...styles.imageWrapper,
              backgroundColor: COLORS.WHITE,
            }}
            onPress={() => {}}
          >
            <Image
              style={{ ...styles.image, backgroundColor: COLORS.WHITE }}
              source={{ uri: product.images[0]?.src }}
            />
          </TouchableOpacity>
        ) : (
          <Image
            style={styles.imageList}
            source={{ uri: product.images[0]?.src }}
          />
        )}
        <View style={styles.detailContainer}>
          <TouchableOpacity onPress={() => {}}>
            <Text
              style={{
                ...styles.name,
                ...(type === 'list' && {
                  textAlign: 'left',
                  marginBottom: 10,
                }),
              }}
            >
              {product.name}
            </Text>
          </TouchableOpacity>
          {type === 'list' && (
            <View style={styles.desc}>
              <RenderHTML
                source={{ html: product.short_description }}
                tagsStyles={{
                  p: {
                    marginTop: 0,
                    color: STYLE_VARS.fccDarkerGrey,
                    fontWeight: '500',
                    marginBottom: 0,
                    textTransform: 'uppercase',
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 13,
                  },
                }}
              />
            </View>
          )}
          {type === 'list' && (
            <Text style={styles.priceText}>{`$ ${product.price}`}</Text>
          )}
        </View>
      </View>
      <View
        style={
          type === 'list' && {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 10,
            gap: 30,
          }
        }
      >
        <View style={type !== 'list' ? styles.wrp : { width: '30%' }}>
          <View style={styles.countContainer}>
            <TouchableOpacity
              onPress={() => {
                if (count > 1) {
                  setCount(count - 1);
                }
              }}
              activeOpacity={1}
              style={styles.countBtn}
            >
              <Text style={styles.conntBtnText}>-</Text>
            </TouchableOpacity>
            <Text
              style={{
                ...styles.countNumber,
                paddingHorizontal: type !== 'list' ? 10 : 20,
              }}
            >
              {count}
            </Text>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.countBtn}
              onPress={() => setCount(count + 1)}
            >
              <Text style={styles.conntBtnText}>+</Text>
            </TouchableOpacity>
          </View>
          {type !== 'list' && (
            <Text style={[styles.priceText, { textAlign: 'right' }]}>
              {/* {`$${Math.round(price * 100) / 100}`} */}
              {`$${product.price}`}
            </Text>
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: type !== 'list' ? 15 : undefined,
            flex: 1,
          }}
        >
          <Button
            style={STYLE_VARS.fullWGreenButton}
            text="ADD TO CART"
            onPress={() => {
              handleAddCart();
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default ProductWooItem;
