export const COLORS = {
  GREEN: '#016623',
  LIGHT_GREEN: '#029636',
  DARK_GREEN: '#2a642c',
  LIGHT_YELLOW: '#F1C400',
  LIGHT_GREY: '#F5F5F5',
  DARK_GREY: '#adb0b1',
  DARKER_GREY: '#4a4b4c',
  RED: '#d12128',
  RED_PRIMARY: '#eb0013',
  SWEET_RED: '#fe405d',
  LIGHT_RED: '#F9F1F1',
  WHITE: 'white',
  BLACK: 'black',
  TRANSPARENT: 'transparent',
  GREY: '#898989',
  LIGHT_LIGHT_GREY: '#D9D9D9',
  LIGHT_BLACK: '#27272A',
  LGREY: '#DDDDE3',
};
