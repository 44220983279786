import { Dimensions, StyleSheet } from 'react-native';
import { STYLE_VARS } from '../../../../constants/style-vars';

const styles = StyleSheet.create({
  border: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderBottomColor: STYLE_VARS.fccGrayBorder,
    borderTopColor: STYLE_VARS.fccGrayBorder,
  },
  wrapper: {
    overflow: 'scroll',
    width: Dimensions.get('window').width,
    backgroundColor: STYLE_VARS.fccGrayBg,
    flexGrow: 0,
  },
  container: { flexDirection: 'row' },
  itemContainer: { paddingHorizontal: 12, paddingVertical: 10 },
  itemText: {
    fontFamily: STYLE_VARS.avenirMediumFont,
    fontSize: 12,
    textTransform: 'uppercase',
    letterSpacing: 2,
    color: STYLE_VARS.primaryColor,
  },
});

export default styles;
