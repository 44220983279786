import { Platform } from 'react-native';
import { FONTS } from './fonts';
import { COLORS } from './colors';

const KCC_GREEN = '#016623';
const KCC_LIGHT_GREEN = '#029636';
const KCC_BG_GREEN = '#2a642c';
const KCC_BORDER_GREEN = '#2a632c';
const KCC_TEXT_GREEN = '#346232';
const KCC_BG_HOVER = '#029636';
// const FCC_BLUE = '#002D62';
const FCC_YELLOW = '#F1C400';
const FCC_LIGHT_GREY = '#f6f7f8';
const FCC_DARK_GREY = '#adb0b1';
const FCC_DARKER_GREY = '#4a4b4c';
const KCC_DANGER = '#c13733';
const KCC_RED = '#d12128';

//font
const KCC_ARIAL_FONT = 'Arial';
const KCC_AVENIR_BLACK_FONT = 'AvenirBlack';
const KCC_AVENIR_MEDIUM_FONT = 'Avenir-Medium';
const KCC_IOWAN_OLDSTYLE_FONT = FONTS.IowanOldStyleBTRoman;
const FCC_GRAY_BG = '#f9f9f9';
const FCC_GRAY_BORDER = '#d0d7de';
const FCC_WHITE = '#ffffff';
const GRAY_TRANSPARENT = 'rgba(0, 0, 0, 0.5)';

export const STYLE_VARS = {
  Const: {
    navBarHeight: 56,
  },
  primaryColor: KCC_GREEN,
  lightGreen: KCC_LIGHT_GREEN,
  secondaryColor: FCC_YELLOW,
  textColor: KCC_GREEN,
  fccBlue: KCC_GREEN,
  fccYellow: FCC_YELLOW,
  fccLightGrey: FCC_LIGHT_GREY,
  fccDarkGrey: FCC_DARK_GREY,
  fccGrayBg: FCC_GRAY_BG,
  fccGrayBorder: FCC_GRAY_BORDER,
  fccWhite: FCC_WHITE,
  fccDarkerGrey: FCC_DARKER_GREY,
  kccBgGreen: KCC_BG_GREEN,
  kccBorderGreen: KCC_BORDER_GREEN,
  kccTextGreen: KCC_TEXT_GREEN,
  kccHoverBg: KCC_BG_HOVER,
  kccDanger: KCC_DANGER,
  kccRed: KCC_RED,
  kccGrayTransparent: GRAY_TRANSPARENT,
  arialFont: KCC_ARIAL_FONT,
  avenirBlackFont: KCC_AVENIR_BLACK_FONT,
  avenirMediumFont: KCC_AVENIR_MEDIUM_FONT,
  iowanOldStyleFont: KCC_IOWAN_OLDSTYLE_FONT,
  container: {
    padding: 15,
    paddingTop: 0,
  },
  row: {
    flexDirection: 'row',
  },
  yellowButton: {
    backgroundColor: FCC_YELLOW,
    color: KCC_GREEN,
    paddingTop: 7,
    paddingRight: 18,
    paddingBottom: 7,
    paddingLeft: 18,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  blueButton: {
    backgroundColor: KCC_GREEN,
    color: 'white',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderWidth: 1,
    borderColor: 'white',
  },
  redButton: {
    backgroundColor: COLORS.RED_PRIMARY,
    color: 'white',
    paddingVertical: 13,
    paddingHorizontal: 40,
    borderWidth: 1,
    borderColor: 'white',
  },
  fullWGreenButton: {
    backgroundColor: KCC_GREEN,
    color: 'white',
    flex: 1,
    paddingVertical: 10,
  },
  unActiveButton: {
    backgroundColor: 'white',
    color: KCC_GREEN,
    paddingVertical: 10,
    borderWidth: 0.5,
    borderColor: KCC_GREEN,
    flex: 1,
  },
  whiteButton: {
    backgroundColor: 'white',
    color: KCC_GREEN,
    paddingTop: 7,
    paddingRight: 18,
    paddingBottom: 7,
    paddingLeft: 18,
  },
  dangerButton: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: KCC_DANGER,
    color: 'white',
  },
  activityIndWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    //backgroundColor: 'rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inlineContainer: {},
  screen: {
    maxWidth: '100%',
  },
  userName: {
    color: KCC_GREEN,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  userType: {
    fontSize: 12,
    color: KCC_GREEN,
    backgroundColor: FCC_YELLOW,
    borderRadius: 5,
    paddingTop: 4,
    paddingRight: 5,
    paddingBottom: 4,
    paddingLeft: 5,
  },
  userNumber: {
    color: KCC_GREEN,
    fontWeight: 'bold',
    fontSize: 12,
    paddingTop: 3,
    paddingBottom: 3,
  },
  userStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    color: KCC_GREEN,
    fontWeight: 'bold',
    fontSize: 12,
  },
  userStatusDot: {
    fontSize: 50,
    lineHeight: 10,
    verticalAlign: 'middle',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 5,
    marginLeft: 15,
  },
  headerIcon: {
    width: 32,
    height: 32,
  },
  headerText: {
    fontSize: 23,
    fontStyle: 'italic',
    fontFamily: 'Georgia',
    color: KCC_GREEN,
    marginLeft: 15,
  },
  modalContainer: {
    flex: 1,
    marginTop: 7,
    marginBottom: 5,
    padding: 15,
    backgroundColor: 'white',
  },
  dateFilter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  arrow: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  arrowimage: {
    width: 9,
    height: 14,
  },
  dow: {
    color: '#00471A',
    fontFamily: FONTS.IowanOldStyleBTRoman,
    fontSize: 17,
    marginLeft: 3,
    marginRight: 5,
  },
  day: {
    color: '#00471A',
    fontFamily: FONTS.IowanOldStyleBTRoman,
    fontSize: 17,
    marginLeft: 3,
    marginRight: 3,
  },
  month: {
    color: '#00471A',
    fontFamily: FONTS.IowanOldStyleBTRoman,
    fontSize: 17,
    marginLeft: 3,
    marginRight: 3,
  },
  calendar: {
    marginLeft: 5,
  },
  calendarContainer: {
    top: Platform.OS !== 'web' ? 60 : 90,
    position: 'absolute',
    backgroundColor: '#EEEEEE',
  },
  calendarimage: {
    width: 27,
    height: 27,
  },
  sectionheader: {
    color: '#fff',
    backgroundColor: '#BAA76E',
    padding: 1,
    paddingLeft: 15,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 15,
  },
  paddings: 32,
  iteminfo: {
    color: '#004718',
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 15,
    marginBottom: 5,
  },
  filterlist: {
    color: 'white',
    marginLeft: 15,
    flex: 1,
  },
  filterctrlbtn: {
    flexDirection: 'row',
    marginRight: 15,
  },
  filtertext: {
    color: KCC_GREEN,
    marginLeft: 8,
    marginRight: 8,
    marginTop: 2,
    borderBottomWidth: 2,
    borderBottomColor: FCC_YELLOW,
  },
  filtericon: {
    width: 20,
    height: 20,
  },
  filterctrl: {
    color: 'white',
    textDecorationLine: 'underline',
  },
  filterWrap: {
    flexDirection: 'row',
    margin: 15,
    marginBottom: 0,
  },
  filterName: {
    flex: 1,
    color: '#757575',
  },
  filterbuttons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 15,
  },
  filtersTitle: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 18,
    color: KCC_GREEN,
  },
  filterWidth: 340,
  filterMaxHeight: 500,
  required: {
    color: FCC_YELLOW,
    fontSize: 30,
    marginRight: 10,
    lineHeight: 10,
    verticalAlign: 'middle',
  },
  underlineTitle: {
    fontWeight: 'bold',
    fontFamily: 'Georgia',
    fontSize: 16,
    marginTop: 15,
  },
  underline: {
    width: 20,
    height: 2,
    backgroundColor: FCC_YELLOW,
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    borderWidth: 1,
    borderColor: KCC_GREEN,
    borderRadius: 0,
    padding: 3,
  },
  picker: {
    borderWidth: 1,
    borderColor: KCC_GREEN,
    borderRadius: 0,
  },
  preview: {
    flex: 1,
    paddingRight: 15,
  },
  preview_content: {
    flex: 1,
    paddingTop: 16,
    paddingRight: 15,
    paddingBottom: 20,
    paddingLeft: 15,
  },
  preview_image: {
    height: 180,
  },
  preview_title: {
    fontSize: 18,
    fontStyle: 'italic',
    fontFamily: 'Georgia',
    color: KCC_GREEN,
  },
  preview_hr: {
    width: 45,
    height: 4,
    backgroundColor: FCC_YELLOW,
    marginTop: 11,
    marginBottom: 11,
  },
  preview_date: {
    color: FCC_DARK_GREY,
    fontSize: 11,
  },
  preview_desc: {
    marginTop: 10,
  },
  preview_actions: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  preview_readmore: {
    marginTop: 15,
    marginRight: 15,
  },
  preview_signup: {
    marginTop: 15,
    marginRight: 15,
  },
  textinput: {
    fontSize: 16,
    marginBottom: 10,
    height: 50,
    width: '100%',
    color: COLORS.BLACK,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    paddingHorizontal: 16,
  },
  textinputweb: {
    marginBottom: 20,
    width: 400,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderStyle: 'solid',
    padding: 14,
    letterSpacing: 1.2,
    borderRadius: 6,
    fontSize: 16,
  },
  textInputMini: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderStyle: 'solid',
    paddingHorizontal: 2,
    letterSpacing: 1.2,
    borderRadius: 4,
    fontSize: 16,
    paddingVertical: 5,
  },
  browse: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    marginBottom: 15,
    zIndex: 10,
  },
};
