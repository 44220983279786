import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC } from 'react';
import { PagePathProps } from './PagePath.types';
import { STYLE_VARS } from '../../constants/style-vars';
import { FONTS } from '../../constants';

const PagePath: FC<PagePathProps> = ({pathSteps, ...props }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
      }}
      {...props}
    >
      {pathSteps?.map((path, index) => {
        if (index === pathSteps.length - 1) {
          return (
            <Text style={styles.text} key={index}>
              {' '}
              {path.name}{' '}
            </Text>
          );
        } else {
          return (
            <TouchableOpacity key={index} onPress={() => {
              if (path.onPress) {
                path.onPress();
              }
            }}>
              <Text style={styles.text}> {path.name} &gt;</Text>
            </TouchableOpacity>
          );
        }
      })}
    </View>
  );
};

export default PagePath;

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontFamily: FONTS.IowanOldStyleBTRoman,
    color: STYLE_VARS.primaryColor,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});
