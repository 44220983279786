import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Product } from "../../../types/woocommerce.types";

export const initProductsFilter = {
  page: 1,
  per_page: 12,
  status: 'publish',
  order: 'desc',
  orderby: 'price',
};

export type ProductFilter = {
  page?: number;
  per_page?: number;
  search?: string;
  status?: string,
  order?: string;
  orderby?: string;
  category?: string | number;
};

interface eShopProductState {
  products: Product[];
  productsFilter: ProductFilter;
  isLoadingProducts: boolean;
  error: string;
  totalProducts: number;
}

const initialState: eShopProductState = {
  products: [],
  productsFilter: initProductsFilter,
  isLoadingProducts: false,
  error: '',
  totalProducts: 0,
};

export const eShopProductSlice = createSlice({
  name: 'e-shop-product',
  initialState,
  reducers: {
    setProductsFilter: (state, action: PayloadAction<ProductFilter>) => {
      state.productsFilter = action.payload;
    },
    setProduct: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setTotalProduct: (state, action: PayloadAction<number>) => {
      state.totalProducts = action.payload;
    },
    setLoadingProducts: (state, action: PayloadAction<boolean>) => {
      state.isLoadingProducts = action.payload;
    },
    clearProductsFilter: (state, action: PayloadAction) => {
      state.products = [];
      state.totalProducts = 0;
      state.productsFilter = initProductsFilter;
    },
  },
});

export const {
  setProductsFilter,
  setProduct,
  setTotalProduct,
  setLoadingProducts,
  clearProductsFilter,
} = eShopProductSlice.actions;

export default eShopProductSlice.reducer;