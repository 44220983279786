/* eslint-disable react/no-unstable-nested-components */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
} from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import * as Keychain from '../services/keychain';
import { IS_WEB } from '../constants/platform';
import { RootState } from '../services/redux/store';
import { fcmUser, logoutUser, updateUser } from '../services/redux/user';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { RootStackParamList } from './types';
import { STYLE_VARS } from '../constants/style-vars';
import { post } from '../services/api/http';
import { API_URI } from '../constants/env';
import { Auth } from '../screens/Auth/Auth';
import NavHeader from './header';
import { AuthNavHeader } from './auth-header';
import { useIsDesktop } from '../hooks/useIsDeskTop';
import { NavigationService } from '../services/navigation';
import BottomTabs from './bottom-tabs';
import HeaderMobileLeft from './header-mobile-left';
import HeaderMobileRight from './header-mobile-right';
import { Alert, BackHandler, View } from 'react-native';
import { updateRoute } from '../services/redux/route';
import { Notices, NoticeDetail, ContactUs, Home, Messages } from '../screens';
import EShopSubCategoryPage from '../screens/EShop/EshopSubCategoryPage/EshopSubCategoryPage';
import NavHeaderDesktop from './header-desktop';
import { COLORS } from '../constants';
import IntroDesktop from '../screens/IntroDesktop/IntroDesktop';
import CreateAnAccount from '../screens/CreateAnAccount/CreateAnAccount';
import BookingList from '../screens/BookingList/BookingList';
import BookingDetail from '../screens/BookingDetail/BookingDetail';
import OrderConfirmation from '../screens/OrderConfirmation/OrderConfirmation';
import { MyProfile } from '../screens/MyProfile';
import { EditProfile } from '../screens/EditProfile';
import MyActivitiesDetail from '../screens/MyActivitiesDetail/MyActivitiesDetail';
import ChangeLanguage from '../screens/ChangeLanguage/ChangeLanguage';
import PushNotification from '../screens/PushNotification/PushNotification';
import DeleteAccount from '../screens/DeleteAccount/DeleteAccount';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [API_URI],
  config: {
    screens: {
      IntroDesktop: 'members-area/intro',
      Login: 'members-area/auth',
      CreateAnAccount: 'members-area/auth/create',
      BookingDetail: 'members-area/bookings/detail',
      OrderConfirmation: 'members-area/bookings/confirmation',
      MessagesUser: 'members-area/messages-user',
      Root: {
        path: 'members-area',
        screens: {
          MyAccountTab: 'my-account',
          MyBookingsTab: 'my-account/bookings',
          MyActivities: 'my-activities',
          Messages: 'messages',
          BookingList: 'bookings',
          MenuTab: {
            screens: {
              HomePage: 'home',
              Menu: 'menu',
              Notices: 'notices',
              NoticeDetail: 'notices-detail',
              EShopSubCategoryPage: 'e-shop/categories/:categoryName/:name',
              MyBookings: 'my-bookings',
              MyActivitiesDetail: 'my-activities-detail',
              ContactUs: 'contact-us',
              MyProfile: 'profile',
              EditProfile: 'edit-profile',
              ChangeLanguage: 'language',
              PushNotification: 'push-notification',
              DeleteAccount: 'account/delete',
            },
          },
        },
      },
      ServiceForm: 'members-area/club-services/services-forms',
    },
  },
};

const Stack = createNativeStackNavigator<RootStackParamList>();

export const RootNavigator: FC = () => {
  // Init navigation services
  const navigationRef =
    useRef<NavigationContainerRef<RootStackParamList>>(null);
  NavigationService.initialize(navigationRef);
  //
  const dispatch = useDispatch();
  const auth = useSelector((redux: RootState) => redux.authReducer);
  const curRoute = useSelector((redux: RootState) => redux.route);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    // Custom BackHandler listener
    const handleBackPress = () => {
      if (
        curRoute.name === 'Root' &&
        (curRoute.params === undefined ||
          curRoute.params?.params?.screen === 'Menu' ||
          curRoute.params?.screen === 'MyAccountTab')
      ) {
        if (NavigationService.canGoBack()) {
          NavigationService.goBack();
        } else {
          Alert.alert('Exit', 'You want to exit app?', [
            {
              text: 'Cancel',
              onPress: () => {},
            },
            {
              text: 'Ok',
              onPress: () => {
                BackHandler.exitApp();
              },
            },
          ]);
        }
        return true;
      } else {
        NavigationService.pop();
        return true;
      }
    };
    BackHandler.addEventListener('hardwareBackPress', handleBackPress);
    // Remove listener
    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handleBackPress);
    };
  }, [curRoute]);

  return (
    <NavigationContainer
      linking={linking}
      ref={navigationRef}
      onStateChange={(state) => {
        const data = {
          name: state ? state.routes[state.routes.length - 1].name : '',
          params: state
            ? state.routes[state.routes.length - 1].params || undefined
            : undefined,
        };
        dispatch(updateRoute({ name: data.name, params: data.params }));
      }}
    >
      <View style={{ flex: 1 }}>
        {isDesktop() && curRoute.name != 'IntroDesktop' && <NavHeaderDesktop />}
        <Stack.Navigator
          screenOptions={{
            contentStyle: {
              backgroundColor: 'white',
              flex: 1,
            },
            headerShown: !isDesktop(),
          }}
          initialRouteName={
            !auth.loggedin ? (!IS_WEB ? 'IntroSlider' : 'IntroDesktop') : 'Home'
          }
        >
          <Stack.Screen
            name="Home"
            component={Home}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="IntroDesktop"
            component={IntroDesktop}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="CreateAnAccount"
            component={CreateAnAccount}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="BookingList"
            component={BookingList}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="BookingDetail"
            component={BookingDetail}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="OrderConfirmation"
            component={OrderConfirmation}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="MyProfile"
            component={MyProfile}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="EditProfile"
            component={EditProfile}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="Messages"
            component={Messages}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />

          <Stack.Screen
            name="MyActivitiesDetail"
            component={MyActivitiesDetail}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="ChangeLanguage"
            component={ChangeLanguage}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="PushNotification"
            component={PushNotification}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          <Stack.Screen
            name="DeleteAccount"
            component={DeleteAccount}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: COLORS.WHITE },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          {/* {!auth.loggedin ? ( */}
          <Stack.Screen
            name="Login"
            component={Auth}
            options={{
              header: isDesktop()
                ? (props) => <AuthNavHeader {...props} />
                : undefined,
              headerStyle: { backgroundColor: STYLE_VARS.primaryColor },
              headerTitle: '',
              headerLeft: HeaderMobileLeft,
              headerRight: HeaderMobileRight,
            }}
          />
          {/* ) : ( */}
          <>
            <Stack.Screen
              name="EShopSubCategoryPage"
              component={EShopSubCategoryPage}
              options={{
                headerStyle: { backgroundColor: STYLE_VARS.primaryColor },
                headerTitle: '',
                headerLeft: HeaderMobileLeft,
                headerRight: HeaderMobileRight,
              }}
            />
            <Stack.Screen
              name="ContactUs"
              component={ContactUs}
              options={{
                headerStyle: { backgroundColor: STYLE_VARS.primaryColor },
                headerTitle: '',
                headerLeft: HeaderMobileLeft,
                headerRight: HeaderMobileRight,
              }}
            />
          </>
          {/* )} */}
          {/* {!IS_WEB && !auth.loggedin && ( */}
          <>
            {/* Disable on website */}
            {/* <Stack.Screen
              name="IntroSlider"
              component={IntroSlider}
              options={{
                headerShown: false,
              }}
            /> */}
          </>
          {/* )} */}
          <Stack.Group navigationKey={auth.loggedin ? 'member' : 'guest'}>
            <Stack.Screen
              name="Root"
              component={BottomTabs}
              options={{
                contentStyle: { flex: 1, backgroundColor: 'white' },
                headerStyle: { backgroundColor: COLORS.WHITE },
                headerTitle: '',
                headerLeft: HeaderMobileLeft,
                headerRight: HeaderMobileRight,
              }}
            />
            <Stack.Screen
              name="Notices"
              component={Notices}
              options={{
                headerStyle: { backgroundColor: STYLE_VARS.primaryColor },
                headerTitle: '',
                headerLeft: HeaderMobileLeft,
                headerRight: HeaderMobileRight,
              }}
            />
            <Stack.Screen
              name="NoticeDetail"
              component={NoticeDetail}
              options={{
                headerStyle: { backgroundColor: STYLE_VARS.primaryColor },
                headerTitle: '',
                headerLeft: HeaderMobileLeft,
                headerRight: HeaderMobileRight,
              }}
            />
          </Stack.Group>
        </Stack.Navigator>
      </View>
    </NavigationContainer>
  );
};
