import { ScrollView, StyleSheet, View } from 'react-native';
import React, { FC } from 'react';
import PageTitle from '../../components/PageTitle';
import NoticeItem from './components/NoticeItem';
import { Footer } from '../../components/Footer';
import { COLORS } from '../../constants';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { INotice } from '../../types/notices.types';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';

const notices: INotice[] = [
  {
    id: 1,
    title: 'New Chief Executive Officer Appointed',
    date: '26 June 2024',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut...',
    img: require('../../assets/notices_image/image-1.jpeg'),
  },
  {
    id: 2,
    title: 'Amway Hong Kong Health care talk',
    date: '26 June 2024',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut...',
    img: require('../../assets/notices_image/image-2.jpeg'),
  },
  {
    id: 1,
    title: 'Buy A Cookie To Support The Elderly',
    date: '26 June 2024',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut...',
    img: require('../../assets/notices_image/image-3.png'),
  },
];

const Notices: FC = () => {
  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Notices" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  paddingBottom: 5,
                }}
              >
                <View style={{ flexDirection: 'column' }}>
                  {notices.map((item, index) => {
                    return (
                      <View
                        style={{
                          marginBottom: 30,
                        }}
                      >
                        <NoticeItem item={item} />
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'NOTICES'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 40,
          paddingVertical: 40,
          backgroundColor: '#F9F1F1',
        }}
      >
        <View style={{ flexDirection: 'column' }}>
          {notices.map((item, index) => {
            return (
              <View
                style={{
                  marginBottom: 30,
                }}
              >
                <NoticeItem item={item} />
              </View>
            );
          })}
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default Notices;

const styles = StyleSheet.create({});
