import { FC, useState } from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import dayjs from 'dayjs';
import { Calendar } from '../../../components/Calendar';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { TextInput } from '../../../components/TextInput/TextInput';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
type Step1Props = {
  onSubmit?: () => void;
};

const Step1: FC<Step1Props> = ({ onSubmit }) => {
  const isDesktop = useIsDesktop();
  const [showCalendarCheckout, setShowCalendarCheckout] =
    useState<boolean>(false);
  const [showCalendarCheckIn, setShowCalendarCheckIn] =
    useState<boolean>(false);
  const [checkInDay, setCheckInDay] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );
  const [checkOutDay, setCheckOutDay] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );
  const [roomType, setRoomType] = useState<number>(0);
  const [additionalBed, setAdditionalBed] = useState<string>('');
  return (
    <View
      style={[
        {
          borderRadius: 18,
          width: '100%',
        },
        isDesktop()
          ? {
              elevation: 5,
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              paddingTop: 40,
              paddingHorizontal: 25,
              marginBottom: 150,
            }
          : {
              backgroundColor: COLORS.WHITE,
              paddingTop: 30,
              paddingHorizontal: 20,
            },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          gap: 20,
          alignItems: 'center',
          flexWrap: 'wrap',
          marginBottom: 50,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flex: isDesktop() ? 0.48 : 1,
          }}
        >
          <View>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '800',
                fontSize: 19,
                color: COLORS.BLACK,
                textTransform: 'uppercase',
                letterSpacing: 1.5,
                textAlign: 'left',
                marginBottom: 12,
              }}
            >
              {'Overnight camp'}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  paddingHorizontal: 11,
                  paddingVertical: 7,
                  borderRadius: 18,
                  backgroundColor: COLORS.LIGHT_GREY,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 14,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  {'3.00 p.m. ~ 12.30 p.m.'}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <View
                style={{
                  width: 120,
                  height: 2,
                  backgroundColor: COLORS.RED_PRIMARY,
                  marginBottom: 12,
                }}
              />
            </View>
            <Text
              style={{
                fontFamily: FONTS.AvenirRegular,
                fontSize: isDesktop() ? 20 : 18,
                fontWeight: 'normal',
                color: COLORS.BLACK,
                lineHeight: 26,
              }}
            >
              {
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
              }
            </Text>
          </View>
        </View>
        {isDesktop() && (
          <View
            style={{
              flex: 0.48,
            }}
          >
            <Image
              source={require('../../../assets/banner-home/banner-1.jpeg')}
              style={{ width: '100%', height: 267, borderRadius: 18 }}
            />
          </View>
        )}
      </View>
      <View>
        <View style={{ flexDirection: 'row', marginBottom: 20 }}>
          <Image
            source={require('../../../assets/calendar-white.png')}
            style={{
              width: 20,
              height: 21,
              marginRight: 8,
            }}
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
            }}
          >
            Select Booking Date:
          </Text>
        </View>
        <View style={{ flexDirection: 'row', marginBottom: 20, zIndex: 1 }}>
          <View
            style={[
              {
                padding: 5,
                borderWidth: 1,
                borderColor: COLORS.GREY,
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: 16,
                marginBottom: 10,
                backgroundColor: COLORS.WHITE,
                marginEnd: isDesktop() ? 10 : 0,
                zIndex: 1,
              },
              !isDesktop() && {
                width: '100%',
                justifyContent: 'space-between',
              },
            ]}
          >
            <TouchableOpacity
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: isDesktop() ? 170 : '49%',
                  marginLeft: 5,
                },
                isDesktop()
                  ? {}
                  : {
                      paddingVertical: 10,
                    },
              ]}
              onPress={() => {
                setShowCalendarCheckIn(!showCalendarCheckIn);
              }}
            >
              <Image
                source={require('../../../assets/check-in-icon.png')}
                style={[
                  {
                    width: 28,
                    height: 21,
                    resizeMode: 'contain',
                    marginRight: isDesktop() ? 10 : 5,
                  },
                ]}
              />
              <View>
                <Text
                  style={{
                    fontSize: isDesktop() ? 18 : 14,
                    fontFamily: FONTS.AvenirMedium,
                    color: COLORS.RED_PRIMARY,
                    textTransform: 'uppercase',
                  }}
                >
                  {dayjs(new Date(checkInDay)).format('ddd, MMM DD')}
                </Text>
                <Text
                  style={{
                    fontSize: isDesktop() ? 16 : 14,
                    color: COLORS.GREY,
                    fontFamily: FONTS.AvenirMedium,
                  }}
                >
                  Check in
                </Text>
              </View>
              {showCalendarCheckIn && (
                <View
                  style={[
                    {
                      zIndex: 100000,
                      width: 300,
                      position: 'absolute',
                      backgroundColor: COLORS.WHITE,

                      top: 70,
                      shadowColor: '#000000',
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowOpacity: 0.5,
                      shadowRadius: 3,
                      elevation: 3,
                      padding: 20,
                      borderRadius: 8,
                    },
                    isDesktop()
                      ? {
                          right: 0,
                        }
                      : {
                          left: 0,
                        },
                  ]}
                >
                  <Calendar
                    initDate={checkInDay}
                    onChangeDate={(date) => {
                      setCheckInDay(date);
                    }}
                    setShowCalendar={setShowCalendarCheckIn}
                    showCalendar={showCalendarCheckIn}
                  />
                </View>
              )}
            </TouchableOpacity>
            <View
              style={{
                width: 1,
                height: 58,
                backgroundColor: COLORS.BLACK,
              }}
            ></View>
            <TouchableOpacity
              style={[
                {
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: isDesktop() ? 170 : '49%',
                  marginLeft: 5,
                },
                isDesktop()
                  ? {}
                  : {
                      paddingVertical: 10,
                    },
              ]}
              onPress={() => {
                setShowCalendarCheckout(!showCalendarCheckout);
              }}
            >
              <Image
                source={require('../../../assets/check-out-icon.png')}
                style={{
                  width: 28,
                  height: 21,
                  resizeMode: 'contain',
                  marginRight: isDesktop() ? 10 : 5,
                }}
              />
              <View>
                <Text
                  style={{
                    fontSize: isDesktop() ? 18 : 14,
                    fontFamily: FONTS.AvenirMedium,
                    color: COLORS.RED_PRIMARY,
                    textTransform: 'uppercase',
                  }}
                >
                  {dayjs(new Date(checkOutDay)).format('ddd, MMM DD')}
                </Text>
                <Text
                  style={{
                    fontSize: isDesktop() ? 16 : 14,
                    color: COLORS.GREY,
                    fontFamily: FONTS.AvenirMedium,
                  }}
                >
                  Check out
                </Text>
              </View>
              {showCalendarCheckout && (
                <View
                  style={[
                    {
                      zIndex: 100000,
                      width: 300,
                      position: 'absolute',
                      backgroundColor: COLORS.WHITE,
                      top: 70,
                      shadowColor: '#000000',
                      shadowOffset: {
                        width: 0,
                        height: 1,
                      },
                      shadowOpacity: 0.5,
                      shadowRadius: 3,
                      elevation: 3,
                      padding: 20,
                      borderRadius: 8,
                    },
                    isDesktop()
                      ? {
                          left: 0,
                        }
                      : {
                          right: 0,
                        },
                  ]}
                >
                  <Calendar
                    initDate={checkOutDay}
                    onChangeDate={(date) => {
                      setCheckOutDay(date);
                    }}
                    setShowCalendar={setShowCalendarCheckout}
                    showCalendar={showCalendarCheckout}
                  />
                </View>
              )}
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
              marginBottom: 15,
            }}
          >
            Select Room:
          </Text>
          <View style={{ flexDirection: 'row', gap: 20, flexWrap: 'wrap' }}>
            <View
              style={{
                width: 160,
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  color: COLORS.BLACK,
                  textAlign: 'center',
                  marginBottom: 5,
                }}
              >
                4 Persons
              </Text>
              <TouchableOpacity
                style={[
                  {
                    paddingVertical: 20,
                    borderWidth: 2,
                    borderColor: COLORS.LIGHT_GREY,
                    borderRadius: 16,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                  roomType === 4 && {
                    borderWidth: 2,
                    borderColor: COLORS.RED_PRIMARY,
                  },
                ]}
                onPress={() => {
                  setRoomType(4);
                }}
              >
                <Image
                  source={require('../../../assets/four-person.png')}
                  style={{
                    width: 72,
                    height: 38,
                  }}
                />
              </TouchableOpacity>
            </View>
            <View
              style={{
                width: 160,
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  color: COLORS.BLACK,
                  textAlign: 'center',
                  marginBottom: 5,
                }}
              >
                2 Persons
              </Text>
              <TouchableOpacity
                style={[
                  {
                    paddingVertical: 20,
                    borderWidth: 2,
                    borderColor: COLORS.LIGHT_GREY,
                    borderRadius: 16,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                  roomType === 2 && {
                    borderWidth: 2,
                    borderColor: COLORS.RED_PRIMARY,
                  },
                ]}
                onPress={() => {
                  setRoomType(2);
                }}
              >
                <Image
                  source={require('../../../assets/two-person.png')}
                  style={{
                    width: 32,
                    height: 38,
                  }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {roomType == 4 && (
          <View style={{ marginBottom: 20 }}>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: 19,
                fontWeight: '800',
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 20,
              }}
            >
              Do you need an additional bed?:
            </Text>
            <RadioButton
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              selectedOption={additionalBed}
              onSelect={(value) => setAdditionalBed(value.toString())}
              optionStyle={{ marginRight: 100 }}
            />
          </View>
        )}

        <View style={{ marginBottom: 20 }}>
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
              marginBottom: 20,
            }}
          >
            Number of Guests 55 years of age or above, or with disabilities:
          </Text>

          <TextInput
            style={[
              (isDesktop()
                ? STYLE_VARS.textinputweb
                : STYLE_VARS.textinput) as TextStyle,
              ,
              { marginBottom: 20, width: isDesktop() ? 400 : '100%' },
            ]}
            placeholder=""
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirRegular,
              fontSize: 18,
              fontWeight: 'normal',
              color: COLORS.GREY,
              letterSpacing: 1,
            }}
          >
            {
              '(Anyone aged 55 or above, or holding a "Registration Card for People with Disabilities" can apply. Each eligible person can bring up to four relatives, friends or non-disabled persons under the age of 55 to participate. All Campers must be Hong Kong residents. (based on 1:4 ratio)'
            }
          </Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View
            style={{
              flexDirection: isDesktop() ? 'row' : 'column',
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontSize: 19,
                fontWeight: '800',
                color: COLORS.BLACK,
                letterSpacing: 1,
              }}
            >
              {'Number of all other Guests'}
            </Text>
          </View>
          <TextInput
            style={[
              (isDesktop()
                ? STYLE_VARS.textinputweb
                : STYLE_VARS.textinput) as TextStyle,
              ,
              { marginBottom: 20, width: isDesktop() ? 400 : '100%' },
            ]}
            placeholder=""
          />
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 18,
              fontWeight: 'normal',
              color: COLORS.GREY,
              letterSpacing: 1,
            }}
          >
            {' (3 years above and below 55):'}
          </Text>
        </View>
        <View
          style={[
            isDesktop()
              ? {
                  marginBottom: 70,
                }
              : {
                  marginBottom: 20,
                  borderBottomWidth: 1,
                  borderBottomColor: COLORS.LIGHT_LIGHT_GREY,
                },
          ]}
        >
          <Text
            style={{
              fontFamily: FONTS.AvenirMedium,
              fontSize: 19,
              fontWeight: '800',
              color: COLORS.BLACK,
              letterSpacing: 1,
              marginBottom: 20,
            }}
          >
            Total Number Of Guests: 4
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <Button
            style={[
              styles.buttonStyle,
              !isDesktop() && { width: '100%', paddingVertical: 30 },
            ]}
            className=""
            arrowRight={false}
            textStyle={[
              styles.textButtonStyle,
              !isDesktop() && { fontSize: 19 },
            ]}
            text="Next Step"
            onPress={() => {
              if (onSubmit) {
                onSubmit();
              }
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default Step1;

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
