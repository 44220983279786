import { FC, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLORS, FONTS } from "../../../constants";
import { STYLE_VARS } from "../../../constants/style-vars";
import { Category } from "../../../types/woocommerce.types";
import { decode } from "html-entities";

interface FilterCollapseProps {
  selected: Category[],
  handleSetSelected?: (selected: Category) => void,
  filter: Category[],
}

const FilterCollapse:FC<FilterCollapseProps> = ({
  selected,
  filter,
  handleSetSelected,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <View>
      <TouchableOpacity
        onPress={() => {
          setOpen(!open);
        }}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: 15,
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            textTransform: 'uppercase',
            fontWeight: "500",
            color: COLORS.GREEN,
            letterSpacing: 1,
          }}
        >{'Category'}</Text>
        <Image
          source={
            open == true
              ? require('../../../assets/up-arrow.png')
              : require('../../../assets/down-arrow.png')
          }
          style={{
            width: 15,
            height: 8,
          }}
        />
      </TouchableOpacity>
      {(open)
        ? (
          <View style={{
            flexDirection: 'column',
          }}>
            {filter?.map((item, index) => (
              <TouchableOpacity
              style={[styles.selectWrapper, { alignSelf: 'flex-start' }]}
              key={item.id}
              onPress={() => {
                if (handleSetSelected) {
                  handleSetSelected(item);
                }
              }}
              >
                <View style={styles.selectedBox}>
                  {selected.includes(item) && (
                    <View
                      style={{
                        width: '100%',
                        height: '100%',
                        borderColor: STYLE_VARS.primaryColor,
                        borderWidth: 5,
                      }}
                    ></View>
                  )}
                </View>
                <Text style={styles.selectedText}>{decode(item.name)}</Text>
              </TouchableOpacity>
            ))}
          </View>
        )
        : <></>
      }
    </View>
  )
}

export default FilterCollapse;

const styles = StyleSheet.create({
  selectWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  selectedText: {
    fontSize: 15,
    fontFamily: FONTS.AvenirMedium,
    color: STYLE_VARS.textColor,
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  selectedBox: {
    width: 17,
    height: 17,
    borderWidth: 0.5,
    borderColor: STYLE_VARS.fccDarkGrey,
    borderRadius: 2,
    marginRight: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
