import { FC, useCallback, useMemo, useState } from 'react';
import {
  Image,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { Text } from 'react-native';
import { IDish } from '../../../types/dishes.types';
import ExtraDishItem from './ExtraDishItem';
import Swiper from 'react-native-web-swiper';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button';
import DatetimeInfo from './DatetimeInfo';
import { STYLE_VARS } from '../../../constants/style-vars';
import DropDown from '../../../components/DropDown';

type Step2Props = {
  onSubmit?: () => void;
};

const Step2: FC<Step2Props> = ({ onSubmit }) => {
  const isDesktop = useIsDesktop();
  const [dinnerMeal1, setDinnerMeal1] = useState<boolean>(false);
  const [dinnerMeal2, setDinnerMeal2] = useState<boolean>(false);
  const [isVegetarian, setIsVegetarian] = useState<string>('');

  const dishes = useMemo(
    () => [
      {
        id: 1,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 2,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 3,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 4,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 5,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 6,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 7,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
      {
        id: 8,
        title: 'BBQ Pork',
        desc: 'Lorem ipsum dolor sitamet , consectetur',
        img: require('../../../assets/dish-image.png'),
        price: 79,
      },
    ],
    []
  );

  const renderSwiper = useCallback(() => {
    // const resul: React.JSX.Element[] = [];
    // for (let i = 0; i < products.length; i += 2) {}
    var allGroup: React.JSX.Element[] = [];
    var group: React.JSX.Element[] = [];
    dishes.map((dish, index) => {
      group.push(
        <View
          key={index}
          style={{
            flex: 0.25,
            //minWidth: 250,
          }}
        >
          <ExtraDishItem item={dish} />
        </View>
      );
      if (
        ((index + 1) % 4 === 0 && index !== 0) ||
        index === dishes.length - 1
      ) {
        allGroup.push(
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              gap: 20,
            }}
          >
            {group}
          </View>
        );
        group = [];
      }
    });

    return (
      <Swiper
        controlsProps={{
          prevPos: false,
          nextPos: false,
          DotComponent: ({ index, isActive, onPress }) => (
            <>
              <View style={{ marginHorizontal: 2 }}>
                <View
                  style={[
                    {
                      height: 10,
                      borderRadius: 10,
                    },
                    isActive
                      ? {
                          width: 18,
                          backgroundColor: '#5E5D5D',
                        }
                      : {
                          width: 10,

                          backgroundColor: '#C9C9C9',
                        },
                  ]}
                ></View>
              </View>
            </>
          ),
          dotsTouchable: true,
        }}
        timeout={5}
        loop
        from={1}
        //ref={swiperRef}
      >
        {allGroup}
      </Swiper>
    );
  }, [dishes]);

  return (
    <View
      style={[
        {
          borderRadius: 18,
          width: '100%',
        },
        isDesktop()
          ? {
              elevation: 5,
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              paddingTop: 25,
              paddingHorizontal: 25,
              marginBottom: 150,
            }
          : {
              backgroundColor: COLORS.WHITE,
              paddingTop: 30,
              paddingHorizontal: 20,
            },
      ]}
    >
      <DatetimeInfo />
      <View
        style={{
          marginBottom: 10,
          zIndex: 2,
        }}
      >
        <Text style={styles.itemTitle}>Included Standard Meals:</Text>
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          <View
            style={[
              styles.mealContainer,
              {
                zIndex: 5,
              },
              isDesktop()
                ? {
                    width: 320,
                  }
                : {
                    width: '100%',
                  },
            ]}
          >
            <View style={[styles.mealWrapper, { backgroundColor: '#FFF5C7' }]}>
              <Text
                style={[
                  styles.mealText,
                  { color: '#CC8100' },
                  !isDesktop() && { fontSize: 17 },
                ]}
              >
                Breakfast
              </Text>
            </View>
            <View style={{ width: '60%' }}>
              <DropDown
                items={[{ label: 'Standard Meal', value: 'standart' }]}
                dropdownStyle={{
                  borderColor: '#DDDDE3',
                  paddingHorizontal: 25,
                  paddingVertical: 6,
                  borderRadius: 16,
                }}
                customDownButton={require('../../../assets/right-arrow.png')}
                customUpButton={require('../../../assets/down-arrow.png')}
                buttonStyle={{
                  width: 11,
                  height: 11,
                  tintColor: COLORS.DARK_GREY,
                }}
                initValue={{ label: 'Standard Meal', value: 'standart' }}
                textStyle={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 16,
                  color: COLORS.GREY,
                }}
                dropDownContainerStyled={{
                  top: 36,
                  minHeight: 10,
                }}
                dropDownContainerTextStyle={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 14,
                  color: COLORS.GREY,
                }}
              />
            </View>
          </View>
          <View
            style={[
              styles.mealContainer,
              isDesktop()
                ? {
                    width: 320,
                  }
                : {
                    width: '100%',
                  },
              { zIndex: 2 },
            ]}
          >
            <View style={[styles.mealWrapper, { backgroundColor: '#D7FAE0' }]}>
              <Text
                style={[
                  styles.mealText,
                  { color: '#007D3A' },
                  !isDesktop() && { fontSize: 17 },
                ]}
              >
                Lunch
              </Text>
            </View>
            <View style={{ width: '60%' }}>
              <DropDown
                items={[{ label: 'Standard Meal', value: 'standart' }]}
                dropdownStyle={{
                  borderColor: '#DDDDE3',
                  paddingHorizontal: 25,
                  paddingVertical: 6,
                  borderRadius: 16,
                }}
                customDownButton={require('../../../assets/right-arrow.png')}
                customUpButton={require('../../../assets/down-arrow.png')}
                buttonStyle={{
                  width: 11,
                  height: 11,
                  tintColor: COLORS.DARK_GREY,
                }}
                initValue={{ label: 'Standard Meal', value: 'standart' }}
                textStyle={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 16,
                  color: COLORS.GREY,
                }}
                dropDownContainerStyled={{
                  top: 36,
                  minHeight: 10,
                }}
                dropDownContainerTextStyle={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 14,
                  color: COLORS.GREY,
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <Text style={styles.itemTitle}>Select Dinner Meal Option:</Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 20,
              marginRight: isDesktop() ? 20 : 10,
            }}
          >
            <View
              style={[styles.mealWrapper, { backgroundColor: '#1A94FF4F' }]}
            >
              <Text
                style={[
                  styles.mealText,
                  { color: '#1A94FF' },
                  !isDesktop() && { fontSize: 17 },
                ]}
              >
                Dinner
              </Text>
            </View>
            {isDesktop() && (
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 16,
                  color: COLORS.GREY,
                }}
              >
                Select Meal Option:
              </Text>
            )}
          </View>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <View
              style={[
                styles.dinnerOption,
                { marginBottom: 15, gap: isDesktop() ? 20 : 10, zIndex: 2 },
              ]}
            >
              <TouchableOpacity
                style={[styles.checkbox, dinnerMeal1 && styles.checkedCheckbox]}
                onPress={() => {
                  setDinnerMeal1(!dinnerMeal1);
                }}
              >
                {dinnerMeal1 && <Text style={styles.checkIcon}>✓</Text>}
              </TouchableOpacity>
              <View style={{ width: 180 }}>
                <DropDown
                  items={[{ label: 'Standard Meal', value: 'standart' }]}
                  dropdownStyle={{
                    borderColor: '#DDDDE3',
                    paddingHorizontal: 25,
                    paddingVertical: 6,
                    borderRadius: 16,
                  }}
                  customDownButton={require('../../../assets/right-arrow.png')}
                  customUpButton={require('../../../assets/down-arrow.png')}
                  buttonStyle={{
                    width: 11,
                    height: 11,
                    tintColor: COLORS.DARK_GREY,
                  }}
                  initValue={{ label: 'Standard Meal', value: 'standart' }}
                  textStyle={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 16,
                    color: COLORS.GREY,
                  }}
                  dropDownContainerStyled={{
                    top: 36,
                    minHeight: 10,
                  }}
                  dropDownContainerTextStyle={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 14,
                    color: COLORS.GREY,
                  }}
                />
              </View>
            </View>
            <View style={[styles.dinnerOption, { gap: isDesktop() ? 20 : 10 }]}>
              <TouchableOpacity
                style={[styles.checkbox, dinnerMeal2 && styles.checkedCheckbox]}
                onPress={() => {
                  setDinnerMeal2(!dinnerMeal2);
                }}
              >
                {dinnerMeal2 && <Text style={styles.checkIcon}>✓</Text>}
              </TouchableOpacity>
              <View style={{ width: 180 }}>
                <DropDown
                  items={[
                    { label: 'Standard Meal', value: 'standart' },
                    { label: 'BBQ', value: 'bbq' },
                  ]}
                  dropdownStyle={{
                    borderColor: '#DDDDE3',
                    paddingHorizontal: 25,
                    paddingVertical: 6,
                    borderRadius: 16,
                  }}
                  customDownButton={require('../../../assets/right-arrow.png')}
                  customUpButton={require('../../../assets/down-arrow.png')}
                  buttonStyle={{
                    width: 11,
                    height: 11,
                    tintColor: COLORS.DARK_GREY,
                  }}
                  initValue={{ label: 'BBQ', value: 'bbq' }}
                  textStyle={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 16,
                    color: COLORS.GREY,
                  }}
                  dropDownContainerStyled={{
                    top: 36,
                    minHeight: 10,
                  }}
                  dropDownContainerTextStyle={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 14,
                    color: COLORS.GREY,
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <Text style={styles.itemTitle}>Select Extra Dishes</Text>
        {isDesktop() ? (
          <View
            style={{
              height: 400,
            }}
          >
            {renderSwiper()}
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 10,
            }}
          >
            {dishes.map((dish, index) => {
              return (
                <View
                  style={{
                    flex: 0.5,
                    minWidth: 130,
                  }}
                >
                  <ExtraDishItem item={dish} />
                </View>
              );
            })}
          </View>
        )}
      </View>
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <Text style={styles.itemTitle}>Are you a vegetarian?</Text>
        <RadioButton
          options={[
            {
              label: 'Yes',
              value: 'yes',
            },
            {
              label: 'No',
              value: 'no',
            },
          ]}
          selectedOption={isVegetarian}
          onSelect={(value) => setIsVegetarian(value.toString())}
          optionStyle={{ marginRight: isDesktop() ? 200 : 90 }}
        />
      </View>
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <Text style={styles.itemTitle}>Add Emergency Contact</Text>
        <View
          style={[
            isDesktop()
              ? { flexDirection: 'row', flexWrap: 'wrap', gap: 20 }
              : { flexDirection: 'column' },
          ]}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            <Text style={[styles.contactText, { width: '40%' }]}>
              First Name:
            </Text>
            <View style={{ width: '50%' }}>
              <TextInput
                style={[
                  STYLE_VARS.textInputMini as TextStyle,
                  { marginBottom: 20 },
                ]}
                placeholder=""
              />
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            <Text style={[styles.contactText, { width: '40%' }]}>
              Last Name:
            </Text>
            <View style={{ width: '50%' }}>
              <TextInput
                style={[
                  STYLE_VARS.textInputMini as TextStyle,
                  { marginBottom: 20 },
                ]}
              />
            </View>
          </View>
        </View>
        <View
          style={[
            isDesktop()
              ? { flexDirection: 'row', flexWrap: 'wrap', gap: 20 }
              : { flexDirection: 'column' },
          ]}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            <Text style={[styles.contactText, { width: '40%' }]}>
              Phone Number:
            </Text>
            <View style={{ width: '50%' }}>
              <TextInput
                style={[
                  STYLE_VARS.textInputMini as TextStyle,
                  { marginBottom: 20 },
                ]}
              />
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            <Text style={[styles.contactText, { width: '40%' }]}>
              Relationship:
            </Text>
            <View style={{ width: '50%' }}>
              <TextInput
                style={[
                  STYLE_VARS.textInputMini as TextStyle,
                  { marginBottom: 20 },
                ]}
              />
            </View>
          </View>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 30,
        }}
      >
        <Button
          style={[
            styles.buttonStyle,
            !isDesktop() && { width: '100%', paddingVertical: 30 },
          ]}
          className=""
          arrowRight={false}
          textStyle={[styles.textButtonStyle, !isDesktop() && { fontSize: 19 }]}
          text="Next Step"
          onPress={() => {
            if (onSubmit) {
              onSubmit();
            }
          }}
        />
      </View>
    </View>
  );
};
export default Step2;

const styles = StyleSheet.create({
  itemTitle: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.LIGHT_BLACK,
    letterSpacing: 1,
    marginBottom: 20,
  },
  mealContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  mealWrapper: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 16,
  },
  mealText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 20,

    lineHeight: 31,
  },
  typeMealWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 25,
    paddingVertical: 6,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderRadius: 16,
    gap: 10,
  },
  typeMealText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.LIGHT_GREY,
  },
  checkedCheckbox: {
    backgroundColor: COLORS.RED_PRIMARY,
  },
  checkIcon: {
    color: '#fff',
  },
  dinnerOption: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  contactText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  contactInput: {
    marginBottom: 20,
    width: '100%',
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderStyle: 'solid',
    paddingHorizontal: 2,
    letterSpacing: 1.2,
    borderRadius: 4,
    fontSize: 16,
    paddingVertical: 5,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
