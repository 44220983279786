import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useRef, useState } from 'react';
import { EShopSideBarProps } from './EShopSideBar.types';
import { STYLE_VARS } from '../../constants/style-vars';
import { Image } from '../Image';
import { NavigationService } from '../../services/navigation';

const EShopSideBar: FC<EShopSideBarProps> = ({ data, current }) => {
  const [hover, setHover] = useState<string>('');
  const handleMouseEnter = (title: string) => {
    // setHover(true);
    setHover(title);
  };

  const handleMouseLeave = () => {
    setHover('');
  };
  return (
    <View>
      {data.map((item, index, arr) => (
        <div
          onMouseEnter={() => {
            handleMouseEnter(item.name);
          }}
          className={current !== item.id ? 'slidebar-item' : ''}
          onMouseLeave={handleMouseLeave}
          key={item.id}
        >
          <TouchableOpacity
            key={item.id}
            style={[
              {
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor:
                  current === item.id ? STYLE_VARS.primaryColor : undefined,
                padding: 10,
              },
            ]}
            onPress={() => {}}
          >
            {item.image && (
              <Image
                source={{ uri: item.image.src }}
                style={{
                  width: 35,
                  height: 32,
                  tintColor:
                    current === item.id || hover === item.name
                      ? 'white'
                      : STYLE_VARS.textColor,
                  resizeMode: 'contain',
                }}
              />
            )}
            <Text
              style={{
                color:
                  current === item.id || hover === item.name
                    ? 'white'
                    : STYLE_VARS.textColor,
                marginLeft: 20,
                textTransform: 'uppercase',
              }}
            >
              {item.name}
            </Text>
          </TouchableOpacity>
        </div>
      ))}
    </View>
  );
};

export default EShopSideBar;

const styles = StyleSheet.create({});
