import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser, LoginResponse } from '../../../types/auth.types';
import { setHeaderConfigAxios } from '../../api/axios';

export type AuthState = {
  token?: string;
  loggedin: boolean;
  info?: IUser;
  fcmToken?: string;
};

const initialState: AuthState = {
  loggedin: false,
};

//const initialUserState: Partial<IUser> = {};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setCredential: (state, action: PayloadAction<any>) => {
      state.info = action.payload.info;
      state.token = action.payload.token;
      state.loggedin = true;
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    logout: (state, action: PayloadAction) => {
      setHeaderConfigAxios();
      state.info = undefined;
      state.token = undefined;
      state.loggedin = false;
    },
    setFcmToken: (state, action: PayloadAction<string>) => {
      state.fcmToken = action.payload;
    },
  },
});

export const { setCredential, logout, setUserInfo, setFcmToken } =
  authSlice.actions;
export default authSlice.reducer;
