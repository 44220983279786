import React, { FC } from 'react';
import {
  TextStyle,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from 'react-native';
import { authStyles } from '../styles';
import { IAuthProps } from '../types';
import { Text } from '../../../components/Text/Text';
import { TextInput } from '../../../components/TextInput/TextInput';
import { getContentWidth, getHeight } from '../../../constants/platform';
import { STYLE_VARS } from '../../../constants/style-vars';
import { Button } from '../../../components/Button';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';

export const LostPassword: FC<IAuthProps> = ({
  state,
  setState,
  onLoginPress,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <View style={authStyles.container}>
      <Text
        style={[
          authStyles.title,
          { textAlign: isDesktop() ? 'left' : 'center' },
        ]}
      >
        FORGOT PASSWORD
      </Text>
      <TextInput
        style={
          (isDesktop()
            ? STYLE_VARS.textinputweb
            : STYLE_VARS.textinput) as TextStyle
        }
        value={state.login}
        onChangeText={(text) => setState({ login: text })}
        placeholder="ENTER USERNAME"
        placeholderTextColor="grey"
      />
      <View
        style={[
          authStyles.submitwrap,
          { width: isDesktop() ? 300 : undefined },
        ]}
      >
        <Button
          text="SUBMIT"
          style={{
            paddingVertical: 12,
            paddingHorizontal: 24,
            alignSelf: isDesktop() ? 'flex-start' : 'center',
          }}
          onPress={onLoginPress}
          disabled={state.submitDisabled}
        />
      </View>

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({ mode: 'login' });
        }}
      >
        <Text>LOGIN</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          authStyles.link,
          { alignItems: isDesktop() ? 'flex-start' : 'center' },
        ]}
        onPress={() => {
          setState({ mode: 'create' });
        }}
      >
        <Text>SIGN UP</Text>
      </TouchableOpacity>

      {state.submitDisabled && (
        <View
          style={[
            authStyles.activityIndWrap,
            {
              width:
                isDesktop() && getContentWidth() >= 768
                  ? 340
                  : getContentWidth(),
              height:
                isDesktop() && getContentWidth() >= 768 ? 320 : getHeight(),
            },
          ]}
        >
          <ActivityIndicator size="large" />
        </View>
      )}
    </View>
  );
};
