import { FC, useCallback, useMemo, useState } from 'react';
import { useIsDesktop } from '../../../hooks/useIsDeskTop';
import { StyleSheet, Text, TextStyle, View } from 'react-native';
import { COLORS, FONTS } from '../../../constants';
import RadioButton from '../../../components/RadioButton/RadioButton';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button';
import DatetimeInfo from './DatetimeInfo';
import ProductTable from './ProductTable';
import { STYLE_VARS } from '../../../constants/style-vars';

type Step3Props = {
  onSubmit?: () => void;
};

const Step3: FC<Step3Props> = ({ onSubmit }) => {
  const isDesktop = useIsDesktop();
  const [paymentMethod, setPaymentMethod] = useState<string>('');

  return (
    <View
      style={[
        {
          borderRadius: 18,
          width: '100%',
        },
        isDesktop()
          ? {
              elevation: 5,
              shadowColor: '#F0F0F0',
              shadowOffset: { width: 0, height: 12 },
              shadowOpacity: 1,
              shadowRadius: 32,
              paddingTop: 25,
              paddingHorizontal: 25,
              marginBottom: 150,
            }
          : {
              backgroundColor: COLORS.WHITE,
              paddingTop: 30,
              paddingHorizontal: 20,
            },
      ]}
    >
      <DatetimeInfo />
      <ProductTable />
      <View
        style={{
          backgroundColor: '#f9f2f1',
          padding: 16,
          borderRadius: 18,
          flexDirection: 'column',
          marginBottom: 25,
        }}
      >
        <Text style={[styles.tableHeaderText, { marginBottom: 17 }]}>
          Emergency Contact
        </Text>
        <View
          style={[
            isDesktop()
              ? { flexDirection: 'row' }
              : { flexDirection: 'column' },
          ]}
        >
          <View
            style={[
              isDesktop()
                ? { width: '30%' }
                : { width: '100%', marginBottom: 15 },
            ]}
          >
            <Text style={styles.tableContentText}>{'Name: Lorem Ipsum'}</Text>
          </View>
          <View
            style={[
              isDesktop()
                ? { width: '45%' }
                : { width: '100%', marginBottom: 15 },
            ]}
          >
            <Text style={styles.tableContentText}>
              {'Phone Number: +852 XXXX XXXX'}
            </Text>
          </View>
          <View style={[isDesktop() ? { width: '25%' } : { width: '100%' }]}>
            <Text style={styles.tableContentText}>{'Relationship: Wife'}</Text>
          </View>
        </View>
      </View>
      <View>
        <Text style={[styles.tableHeaderText, { marginBottom: 10 }]}>
          Payment
        </Text>
        <RadioButton
          options={[
            {
              label: 'Visa',
              value: 'visa',
            },
            {
              label: 'MasterCard',
              value: 'masterCard',
            },
            {
              label: 'Bank Transfer',
              value: 'bankTransfer',
            },
          ]}
          selectedOption={paymentMethod}
          onSelect={(value) => setPaymentMethod(value.toString())}
          optionStyle={{
            marginRight: isDesktop() ? 100 : 0,
            marginBottom: isDesktop() ? 0 : 5,
          }}
          radioStyle={{
            marginBottom: isDesktop() ? 20 : 5,
            flexDirection: isDesktop() ? 'row' : 'column',
            alignItems: isDesktop() ? 'center' : 'flex-start',
          }}
        />
        <View
          style={[
            { marginBottom: 15 },
            isDesktop()
              ? { flexDirection: 'row', flexWrap: 'wrap', gap: 20 }
              : { flexDirection: 'column' },
          ]}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              marginBottom: isDesktop() ? 0 : 15,
            }}
          >
            <Text
              style={[styles.inputText, { width: isDesktop() ? '40%' : '50%' }]}
            >
              Name of card:
            </Text>
            <View style={{ width: '50%' }}>
              <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            <Text
              style={[styles.inputText, { width: isDesktop() ? '40%' : '50%' }]}
            >
              Card Number:
            </Text>
            <View style={{ width: '50%' }}>
              <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 40 }}>
          <View
            style={{
              flexDirection: 'row',
              width: isDesktop() ? '50%' : '100%',
            }}
          >
            <View
              style={{
                width: '50%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={[styles.inputText, { width: '30%' }]}>CSV:</Text>
              <View style={{ width: '40%' }}>
                <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
              </View>
            </View>
            <View
              style={{
                width: '50%',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={[styles.inputText, { width: '25%' }]}>EXP:</Text>
              <View style={{ width: '25%' }}>
                <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
              </View>
              <Text
                style={{
                  fontSize: 20,
                  marginHorizontal: 5,
                  fontWeight: '500',
                  color: COLORS.GREY,
                }}
              >
                {'/'}
              </Text>
              <View style={{ width: '25%' }}>
                <TextInput style={STYLE_VARS.textInputMini as TextStyle} />
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <Button
            style={[
              styles.buttonStyle,
              !isDesktop() && { width: '100%', paddingVertical: 30 },
            ]}
            className=""
            arrowRight={false}
            textStyle={[
              styles.textButtonStyle,
              !isDesktop() && { fontSize: 19 },
            ]}
            text="Pay now"
            onPress={() => {
              if (onSubmit) {
                onSubmit();
              }
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default Step3;

const styles = StyleSheet.create({
  tableHeaderText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    color: COLORS.BLACK,
  },
  tableContentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
  inputText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  input: {
    width: '100%',
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderStyle: 'solid',
    paddingHorizontal: 0,
    letterSpacing: 1.2,
    borderRadius: 4,
    fontSize: 16,
    paddingVertical: 5,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
