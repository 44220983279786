import React, { FC } from 'react';
import { Image, useWindowDimensions, View } from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { addBase64Prefix } from '../../services/addBase64Prefix';
import { Text } from '../Text';
import { logo_hh } from '../../assets/static/base64-new';
import { useIsDesktop } from '../../hooks/useIsDeskTop';

export const Footer: FC = () => {
  const isDesktop = useIsDesktop();
  return (
    <View
      style={{
        backgroundColor: COLORS.LIGHT_GREY,
        flexDirection: 'column',
        alignItems: 'center',
        paddingHorizontal: isDesktop() ? 100 : 0,
        paddingTop: isDesktop() ? 30 : 60,
      }}
    >
      <View
        style={[
          {
            flexDirection: isDesktop() ? 'row' : 'column',
            width: '100%',
            marginBottom: 80,
          },
          !isDesktop() && { alignItems: 'center', justifyContent: 'center' },
        ]}
      >
        <View
          style={[
            {
              flexDirection: 'row',
              justifyContent: 'center',
            },
            isDesktop()
              ? {
                  width: '30%',
                }
              : {
                  marginBottom: 50,
                },
          ]}
        >
          <Image
            source={{ uri: addBase64Prefix(logo_hh) }}
            style={[
              isDesktop()
                ? {
                    width: 104,
                    height: 72,
                  }
                : {
                    width: 130,
                    height: 90,
                  },
            ]}
          />
        </View>
        {isDesktop() ? (
          <View
            style={{
              width: '35%',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.Arial,
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  fontSize: 20,
                  color: COLORS.BLACK,
                  letterSpacing: 1,
                  marginBottom: 8,
                }}
              >
                Contact Us
              </Text>
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  color: COLORS.BLACK,
                }}
              >
                Nin Wah Road, Ma On Shan,
              </Text>
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  color: COLORS.BLACK,
                }}
              >
                (852) 2522 4494 / (852) 2526 3233
              </Text>
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  color: COLORS.BLACK,
                }}
              >
                Fax：(852) 2840 1278
              </Text>
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  color: COLORS.BLACK,
                }}
              >
                admin@helpinghand.org.hk
              </Text>
            </View>
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: 30,
            }}
          >
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                color: COLORS.BLACK,
                marginBottom: 15,
              }}
            >
              Contact Us
            </Text>
            <View style={{ flexDirection: 'row', marginBottom: 13 }}>
              <Image
                source={require('../../assets/address-white.png')}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.BLACK,
                  marginRight: 5,
                }}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: COLORS.BLACK,
                }}
              >
                {'1/F, 12 Borrett Road,\nMid-Levels, Hong Kong'}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 13 }}>
              <Image
                source={require('../../assets/phone-white.png')}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.BLACK,
                  marginRight: 5,
                }}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: COLORS.BLACK,
                }}
              >
                {'(852) 2522 4494'}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 13 }}>
              <Image
                source={require('../../assets/fax-white.png')}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.BLACK,
                  marginRight: 5,
                }}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: COLORS.BLACK,
                }}
              >
                {'(852) 2840 1278'}
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 13 }}>
              <Image
                source={require('../../assets/email-white.png')}
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.BLACK,
                  marginRight: 5,
                }}
              />
              <Text
                style={{
                  fontSize: 12,
                  color: COLORS.BLACK,
                }}
              >
                {'admin@helpinghand.org.hk'}
              </Text>
            </View>
          </View>
        )}

        {isDesktop() ? (
          <View
            style={{
              width: '35%',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.Arial,
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  fontSize: 20,
                  color: COLORS.BLACK,
                  letterSpacing: 1,
                  marginBottom: 8,
                }}
              >
                About Us
              </Text>
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontSize: 16,
                  color: COLORS.BLACK,
                }}
              >
                About Helping Hand Holiday Centre
              </Text>
            </View>
          </View>
        ) : (
          <View style={{ flexDirection: 'column', alignItems: 'center' }}>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 12,
              }}
            >
              About Helping Hand
            </Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 12,
              }}
            >
              SERVICE
            </Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 12,
              }}
            >
              ACTIVITIES
            </Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 12,
              }}
            >
              GIVE YOUR HELP
            </Text>
            <Text
              style={{
                textTransform: 'uppercase',
                fontSize: 18,
                color: COLORS.BLACK,
                letterSpacing: 1,
                marginBottom: 12,
              }}
            >
              NEWS & MEDIA
            </Text>
          </View>
        )}
      </View>
      {isDesktop() ? (
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontSize: 16,
            color: COLORS.BLACK,
            marginBottom: 70,
          }}
        >
          Copyright 2024 © Helping Hand. All rights reserved.
        </Text>
      ) : (
        <Text
          style={{
            textAlign: 'center',
            fontSize: 12,
            color: COLORS.BLACK,
            marginBottom: 20,
          }}
        >
          {
            'Term & Conditions | Privacy Policy\nCopyright © 2023 Helping Hand.\nAll right reserved'
          }
        </Text>
      )}
    </View>
  );
};
