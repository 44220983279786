import { Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { EShopLayoutProps } from './EShopLayout.types';
import PagePath from '../../components/PagePath';
import UserWebInfo from '../../components/UserWebInfo';
import { COLORS, FONTS } from '../../constants';
import { ESHOPTABS } from './EShopLayout.constants';
import Sidebar from '../../components/Sidebar';
import { Footer } from '../../components/Footer';
import { STYLE_VARS } from '../../constants/style-vars';
import { IS_WEB } from '../../constants/platform';
import { addBase64Prefix } from '../../services/addBase64Prefix';
import {
  cart,
  filter_button,
  reset_icon,
  search_white,
  shopping_cart,
} from '../../assets/base64';
import { SHARE_STYLE } from '../../constants/share-style';
import { TextInput } from '../../components/TextInput/TextInput';
import FilterCollapse from './component/FilterCollapse';
import { Range, getTrackBackground } from 'react-range';
import { NavigationService } from '../../services/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/redux';
import EShopSideBar from '../../components/EShopSideBar';
import { Loading } from '../../components';

const EShopLayout: FC<EShopLayoutProps> = ({
  title,
  children,
  current = -1,
  description,
  icon,
  havePromotion = true,
  haveFilters = false,
  haveGoBackButton = false,
  childCategories,
  category,
  searchRef,
  handleSearch,
  selectedFilters,
  handleSelectFilter,
  handleFilterReset,
}) => {
  const pathSteps = useMemo(() => {
    const steps = [
      {
        name: 'Member area',
        onPress: () => {
          NavigationService.push('Root', {
            screen: 'HomeTab',
          });
        },
      },
      {
        name: 'KCC E-shop',
      },
    ];
    if (current !== -1)
      steps.push({
        name: title,
      });
    return steps;
  }, [title, current]);
  //const [priceRange, setPriceRange] = useState<number[]>([0, 7599]);

  const renderFilter = useMemo(() => {
    const propRange = {
      min: 0,
      max: 7599,
      step: 1,
    };

    return (
      <View style={{ marginTop: 40 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomWidth: 0.5,
            borderBottomColor: STYLE_VARS.fccDarkGrey,
            paddingBottom: 15,
            marginBottom: 15,
          }}
        >
          <Image
            source={
              IS_WEB
                ? addBase64Prefix(filter_button)
                : require('../../assets/filter-button.png')
            }
            style={{ width: 24, height: 24, marginRight: 8 }}
          />
          <Text
            style={{
              fontSize: 16,
              letterSpacing: 2,
              fontFamily: FONTS.IowanOldStyleBTRoman,
              color: STYLE_VARS.textColor,
              textTransform: 'uppercase',
            }}
          >
            Filter {title}
          </Text>
        </View>
        <View style={{ flexDirection: 'column' }}>
          <FilterCollapse
            selected={selectedFilters ?? []}
            handleSetSelected={handleSelectFilter}
            filter={childCategories ?? []}
          />
          {/* <View>
            <Text style={{
              fontFamily: FONTS.AvenirMedium,
              color: COLORS.GREEN,
              textTransform: 'uppercase',
              fontSize: 16,
              marginBottom: 5,
            }}>PRICE</Text>
            <View style={{
              paddingVertical: 15,
              paddingHorizontal: 8,
            }}>
              <Range
                step={1}
                min={0}
                max={7599}
                values={priceRange}
                onChange={(values) => setPriceRange(values)}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: 4,
                      width: '100%',
                      background: getTrackBackground({
                        values: priceRange,
                        colors: [COLORS.DARK_GREY, COLORS.GREEN, COLORS.DARK_GREY ],
                        min: 0,
                        max: 7599,
                      }),
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: 10,
                      width: 10,
                      backgroundColor: COLORS.GREEN,
                      borderRadius: 50,
                      padding: 4,
                    }}
                  >
                    <div style={{
                      backgroundColor: COLORS.WHITE,
                      borderRadius: 50,
                      height: 10,
                      width: 10,
                    }}/>
                  </div>
                )}
              />
            </View>
            <Text style={{
              fontFamily: FONTS.AvenirMedium,
              color: COLORS.GREEN,
              textTransform: 'uppercase',
              fontSize: 14,
              marginBottom: 10,
              letterSpacing: 1,
            }}>RANGE: HK${priceRange[0]}.00 - HK${priceRange[1]}.00</Text>
          </View> */}
          <TouchableOpacity
            style={[
              {
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 20,
              },
              { alignSelf: 'flex-start' },
            ]}
            onPress={handleFilterReset}
          >
            <Image
              source={
                IS_WEB
                  ? addBase64Prefix(reset_icon)
                  : require('../../assets/reset-icon.png')
              }
              style={{
                width: 17,
                height: 17,
                marginRight: 8,
              }}
            />
            <Text
              style={{
                fontSize: 15,
                fontFamily: FONTS.AvenirMedium,
                color: STYLE_VARS.textColor,
                fontWeight: '500',
                textTransform: 'uppercase',
              }}
            >
              RESET FILTER
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }, [
    title,
    selectedFilters,
    // priceRange,
    // setPriceRange,
    childCategories,
    handleSelectFilter,
    handleFilterReset,
  ]);

  const { categories, isLoading, error } = useSelector(
    (state: RootState) => state.eShopCategoryReducer
  );

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View
        style={{
          maxWidth: 1250,
          width: '90%',
          marginHorizontal: 'auto',
          marginTop: 10,
          minHeight: 500,
        }}
      >
        <PagePath pathSteps={pathSteps} />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            marginVertical: 30,
          }}
        >
          <View style={{ width: '20%', minWidth: 230, marginRight: 20 }}>
            <UserWebInfo />
            <View style={{ marginTop: 30 }}>
              {isLoading === true ? (
                <Loading />
              ) : (
                <View
                  style={{
                    backgroundColor: COLORS.LIGHT_GREY,
                  }}
                >
                  <EShopSideBar data={categories} current={current} />
                </View>
              )}
            </View>
            {haveFilters && renderFilter}
          </View>
          <View style={{ width: '75%' }}>
            {/* E-shop title */}
            <View
              style={{
                borderBottomWidth: 0.5,
                borderBottomColor: STYLE_VARS.fccDarkGrey,
                flexDirection: 'column',
                paddingBottom: 20,
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={icon}
                    style={{
                      height: 25,
                      width: 25,
                      marginRight: 10,
                      resizeMode: 'contain',
                      tintColor: STYLE_VARS.primaryColor,
                    }}
                  />
                  <Text
                    style={[SHARE_STYLE.titleText, { textAlign: 'center' }]}
                  >
                    {title}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {haveGoBackButton && (
                    <TouchableOpacity
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: 30,
                      }}
                      onPress={() => {
                        NavigationService.pop();
                      }}
                    >
                      <Image
                        source={require('../../assets/sport-back-button.png')}
                        style={{
                          width: 20,
                          height: 19,
                          marginRight: 5,
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: FONTS.AvenirMedium,
                          fontSize: 14,
                          color: COLORS.DARKER_GREY,
                        }}
                      >
                        BACK
                      </Text>
                    </TouchableOpacity>
                  )}
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      source={
                        IS_WEB
                          ? addBase64Prefix(cart)
                          : require('../../assets/cart.png')
                      }
                      style={{
                        height: 25,
                        width: 25,
                        marginRight: 10,
                        resizeMode: 'contain',
                        tintColor: STYLE_VARS.primaryColor,
                      }}
                    />
                    <TextInput
                      placeholder="SEARCH PRODUCTS"
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 14,
                        borderBottomColor: STYLE_VARS.primaryColor,
                        borderBottomWidth: 0.5,
                        width: 150,
                        paddingLeft: 10,
                        paddingVertical: 3,
                        color: STYLE_VARS.primaryColor,
                      }}
                      placeholderTextColor={STYLE_VARS.primaryColor}
                      onChangeText={handleSearch}
                    />
                    <Image
                      source={
                        IS_WEB
                          ? addBase64Prefix(search_white)
                          : require('../../assets/search_white.png')
                      }
                      style={{
                        height: 15,
                        width: 15,
                        resizeMode: 'contain',
                        tintColor: STYLE_VARS.primaryColor,
                        marginLeft: 5,
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
            {children}
          </View>
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default EShopLayout;
