import { PAGE_ITEMS } from '../../constants';import { API_URI } from '../../constants/env';
import { INotice, INoticeCategory } from '../../types/notices.types';
import { NoticeFilter } from '../redux/reducers/NoticeSlice';
``
import instance from './axios';

type NoticesProps = {
  page?: number;
  limit?: number;
  search?: string;
  cats?: string;
  type?: 'new' | 'past' | 'all';
};

type NoticesCategoryProps = {
  search?: string;
  parent?: number;
};

const ENDPOINTS = {
  GETALLNOTICES: '/member-area-notices',
  NOTICES: '/notices',
  NOTICE_CATEGORIES: '/notice-categories',
};

const getAllNotices = () => {
  return instance.get(ENDPOINTS.GETALLNOTICES);
};

const getNotices = ({ limit = PAGE_ITEMS, page = 1, ...props }: NoticeFilter) => {
  return instance.get<{
    total: number;
    last_page: number;
    notices: INotice[];
  }>(ENDPOINTS.NOTICES, {
    baseURL: API_URI + '/wp-json/kcc/v1',
    params: {
      limit,
      page,
      ...props,
    },
  });
};

const getNotice = (id: number) => {
  return instance.get<INotice>(ENDPOINTS.NOTICES + '/' + id);
}

const getNoticeCategories = ({ search, parent }: NoticesCategoryProps) => {
  return instance.get<INoticeCategory[]>(ENDPOINTS.NOTICE_CATEGORIES, {
    baseURL: API_URI + '/wp-json/kcc/v1',
    params: { search, parent },
  });
};

const NoticesApi = {
  getAllNotices,
  getNotices,
  getNotice,
  getNoticeCategories,
};

export default NoticesApi;
