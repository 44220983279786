import { StyleSheet } from "react-native";
import { COLORS, FONTS } from "../../../../constants";
import { STYLE_VARS } from "../../../../constants/style-vars";

export const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.DARK_GREY,
    marginTop: 20,
  },
  titleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  title: {
    fontFamily: FONTS.AvenirMedium,
    color: STYLE_VARS.primaryColor,
    textTransform: 'uppercase',
    fontWeight: '500',
    fontSize: 16,
  },
  titleIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  price: {
    textAlign: 'right',
    fontFamily: FONTS.AvenirMedium,
    color: STYLE_VARS.primaryColor,
    fontWeight: '500',
    fontSize: 16,
  },
  description: {
    textTransform: 'uppercase',
    fontFamily: FONTS.AvenirMedium,
    color: STYLE_VARS.fccDarkerGrey,
    fontWeight: '500',
    fontSize: 11,
  },
  countWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  count: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  countBtn: {
    width: 27,
    height: 27,
    borderWidth: 1,
    borderColor: STYLE_VARS.fccDarkGrey,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  countBtnText: {
    color: COLORS.DARKER_GREY,
    textAlign: 'center',
    fontSize: 17,
  },
  countText: {
    fontSize: 15,
    paddingHorizontal: 16,
    color: COLORS.DARKER_GREY,
    fontFamily: STYLE_VARS.avenirMediumFont,
  },
  addToCardBtn: {
    backgroundColor: STYLE_VARS.primaryColor,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 18,
    alignItems: 'center',
    gap: 8,
    borderRadius: 8,
  },
  addToCardText: {
    fontSize: 14,
    fontWeight: '800',
    fontFamily: STYLE_VARS.avenirMediumFont,
    letterSpacing: 1,
    color: STYLE_VARS.fccWhite,
    textTransform: 'uppercase',
  },
  addToCardIcon: {
    width: 16,
    height: 16,
  },
});