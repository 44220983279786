import { Dimensions, Platform } from 'react-native';
import jQuery from 'jquery';

export const IS_IOS = Platform.OS === 'ios';
export const IS_ANDROID = Platform.OS === 'android';
export const IS_WEB = Platform.OS === 'web';

export const SCREEN_WITDH = Dimensions.get('screen').width;
export const SCREEN_HEIGHT = Dimensions.get('screen').height;

export const getWidth = () => {
  return Dimensions.get('window').width;
};

export const getContentWidth = () => {
  const width = Dimensions.get('window').width,
    menuWidth = width > MIN_DESKTOP_WIDTH ? 250 : 0,
    padding = width > MIN_DESKTOP_WIDTH ? 30 : 0;
  if (!IS_WEB) {
    return width;
  } else {
    if (width <= MIN_DESKTOP_WIDTH) {
      return width;
    } else {
      return Math.min(1100, width - padding) - menuWidth - padding;
    }
  }
};

export const getHeight = () => {
  if (IS_WEB) {
    return Dimensions.get('window').height;
  } else {
    return Dimensions.get('window').height - 56;
  }
};

export const getContentHeight = () => {
  if (IS_WEB) {
    return jQuery('.app-container').height();
  } else {
    return Dimensions.get('window').height - 56;
  }
};

export const isWide = () => {
  return getContentWidth() >= MIN_DESKTOP_WIDTH;
};

export const isMobileWeb = () => {
  return IS_WEB && Dimensions.get('window').width <= MIN_DESKTOP_WIDTH;
};

export const HAS_NOTCH =
  IS_IOS &&
  (Dimensions.get('window').height === 812 ||
    Dimensions.get('window').height === 896);

export const MAX_WIDTH = 1250;
export const MIN_DESKTOP_WIDTH = 992;
