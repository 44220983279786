import { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { User } from '../Home/components/User';
import { COLORS, FONTS } from '../../constants';
import { Footer } from '../../components/Footer';
import Step1 from './components/Step1';
import StepBar from './components/StepBar';
import Swiper from 'react-native-web-swiper';
import { Button } from '../../components/Button';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import { NavigationService } from '../../services/navigation';

const BookingDetail: FC = () => {
  const isDesktop = useIsDesktop();
  const [step, setStep] = useState<number>(1);
  const scrollViewRef = useRef<ScrollView>(null);

  const renderSwiper = useMemo(() => {
    const sliders = [
      {
        id: 1,
        img: require('../../assets/banner-home/banner-1.jpeg'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
      {
        id: 2,
        img: require('../../assets/banner-home/banner-2.png'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
      {
        id: 3,
        img: require('../../assets/banner-home/banner-3.jpeg'),
        title: 'Overnight Camp',
        desc: 'Weekdays (Sunday to Friday Evening) \n$133 Per Person \nHolidays (Saturday Evening & Holidays Eve \nof Public Holidays) $160 per person',
      },
    ];

    return (
      <Swiper
        controlsProps={{
          prevPos: false,
          nextPos: false,
          DotComponent: ({ index, isActive, onPress }) => (
            <>
              {isDesktop() && (
                <View style={{ marginHorizontal: 2 }}>
                  <View
                    style={[
                      {
                        height: 10,
                        borderRadius: 10,
                      },
                      isActive
                        ? {
                            width: 18,
                            backgroundColor: '#5E5D5D',
                          }
                        : {
                            width: 10,

                            backgroundColor: '#C9C9C9',
                          },
                    ]}
                  ></View>
                </View>
              )}
            </>
          ),
          dotsTouchable: true,
        }}
        timeout={5}
        loop
      >
        {sliders.map((item) => (
          <View key={item.id} style={{ flex: 1, position: 'relative' }}>
            <View>
              <Image
                source={item.img}
                style={{
                  width: '100%',
                  height: isDesktop() ? 460 : 240,
                  resizeMode: 'cover',
                }}
              />
            </View>
            <Text
              style={[
                {
                  color: COLORS.WHITE,
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  fontFamily: FONTS.Arial,
                  textAlign: 'center',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  letterSpacing: 1.5,
                  top: 15,
                },
                isDesktop()
                  ? {
                      fontSize: 30,
                    }
                  : {
                      fontSize: 20,
                    },
              ]}
            >
              {item.title}
            </Text>
            <Text
              style={[
                {
                  color: COLORS.WHITE,

                  fontFamily: FONTS.AvenirMedium,
                  textAlign: 'center',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                },
                isDesktop()
                  ? {
                      top: 100,
                      fontSize: 24,
                      fontWeight: '800',
                    }
                  : {
                      top: 60,
                      fontSize: 16,
                      fontWeight: '500',
                    },
              ]}
            >
              {item.desc}
            </Text>
            {isDesktop() && (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: 300,
                  left: 0,
                  right: 0,
                }}
              >
                <Button
                  style={{
                    backgroundColor: COLORS.RED_PRIMARY,
                    paddingVertical: 15,
                    borderRadius: 13,
                    width: 270,
                    marginBottom: 20,
                    overflow: 'hidden',
                  }}
                  className=""
                  textStyle={{
                    color: COLORS.WHITE,
                    fontSize: 19,
                    fontWeight: '800',
                    fontFamily: FONTS.AvenirMedium,
                    textTransform: 'uppercase',
                  }}
                  text="Book Now"
                />
              </View>
            )}
          </View>
        ))}
      </Swiper>
    );
  }, [isDesktop]);

  const handleSetStep = useCallback((step: number) => {
    setStep(step);
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }, []);

  if (isDesktop()) {
    return (
      <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '20%', minWidth: 230, marginRight: 20 }}>
              <User />
            </View>
            <View style={{ width: '70%' }}>
              <Text
                style={{
                  textTransform: 'uppercase',
                  fontSize: 26,
                  fontFamily: FONTS.Arial,
                  fontWeight: '700',
                  letterSpacing: 3,
                  marginBottom: 30,
                }}
              >
                Overnight Camp
              </Text>
              <StepBar step={step} setStep={setStep} />
              {step === 1 && (
                <Step1
                  onSubmit={() => {
                    setStep(2);
                    if (scrollViewRef.current) {
                      scrollViewRef.current.scrollTo({
                        y: 0,
                        animated: true,
                      });
                    }
                  }}
                />
              )}
              {step === 2 && (
                <Step2
                  onSubmit={() => {
                    setStep(3);
                    if (scrollViewRef.current) {
                      scrollViewRef.current.scrollTo({
                        y: 0,
                        animated: true,
                      });
                    }
                  }}
                />
              )}
              {step === 3 && (
                <Step3
                  onSubmit={() => {
                    NavigationService.push('OrderConfirmation');
                  }}
                />
              )}
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
      <View
        style={{
          paddingHorizontal: 20,
          paddingTop: 20,
          backgroundColor: '#fdfdfd',
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 20,
            color: COLORS.BLACK,
            textTransform: 'uppercase',
            letterSpacing: 1,
            lineHeight: 23,
            marginBottom: 20,
          }}
        >
          Overnight Camp Booking
        </Text>
        <StepBar step={step} setStep={setStep} />
      </View>
      <View style={{ width: '100%', height: 240 }}>{renderSwiper}</View>
      <View
        style={{
          paddingHorizontal: 20,
          paddingTop: 20,
          backgroundColor: COLORS.LIGHT_LIGHT_GREY,
          paddingBottom: 20,
        }}
      >
        {step === 1 && (
          <Step1
            onSubmit={() => {
              handleSetStep(2);
            }}
          />
        )}
        {step === 2 && (
          <Step2
            onSubmit={() => {
              setStep(3);
              if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({
                  y: 0,
                  animated: true,
                });
              }
            }}
          />
        )}
        {step === 3 && (
          <Step3
            onSubmit={() => {
              NavigationService.push('OrderConfirmation');
            }}
          />
        )}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default BookingDetail;

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
