import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SwitchECardState = {
  isShowStart?: boolean;
};

const initialState: SwitchECardState = {
  isShowStart: false,
};

const switchECardSlice = createSlice({
  name: 'switchECard',
  initialState: initialState,
  reducers: {
    setIsShowStart: (state, action: PayloadAction<boolean>) => {
      state.isShowStart = action.payload;
    },
  },
});

export const { setIsShowStart } = switchECardSlice.actions;
export default switchECardSlice.reducer;
