import { FC } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { User } from '../Home/components/User';
import { COLORS, FONTS } from '../../constants';
import DatetimeInfo from '../BookingDetail/components/DatetimeInfo';
import ProductTable from '../BookingDetail/components/ProductTable';
import { Button } from '../../components/Button';
import { Footer } from '../../components/Footer';
import { NavigationService } from '../../services/navigation';

const OrderConfirmation: FC = () => {
  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '20%', minWidth: 230, marginRight: 20 }}>
              <User />
            </View>
            <View style={{ width: '70%' }}>
              <View>
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 30,
                    color: COLORS.BLACK,
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    marginBottom: 20,
                  }}
                >
                  Thanks for your camp booking
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 20,
                    color: COLORS.BLACK,
                    textAlign: 'center',
                    marginBottom: 30,
                  }}
                >
                  Your transaction was successful!
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 30,
                  }}
                >
                  <View style={{ flexDirection: 'column' }}>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 20,
                        fontWeight: '500',
                        color: COLORS.BLACK,
                      }}
                    >
                      Date of Payment
                    </Text>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 20,
                        fontWeight: '500',
                        color: COLORS.BLACK,
                      }}
                    >
                      03/12/2024
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'column', width: '35%' }}>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 20,
                        fontWeight: '500',
                        color: COLORS.BLACK,
                      }}
                    >
                      Order #
                    </Text>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontSize: 20,
                        fontWeight: '500',
                        color: COLORS.BLACK,
                      }}
                    >
                      12345
                    </Text>
                  </View>
                </View>
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 26,
                    textTransform: 'uppercase',
                    color: COLORS.BLACK,
                    letterSpacing: 2,
                    marginBottom: 15,
                  }}
                >
                  Overnight Camp
                </Text>
                <View
                  style={[
                    {
                      borderRadius: 18,
                      width: '100%',
                    },
                    isDesktop()
                      ? {
                          elevation: 5,
                          shadowColor: '#F0F0F0',
                          shadowOffset: { width: 0, height: 12 },
                          shadowOpacity: 1,
                          shadowRadius: 32,
                          paddingTop: 25,
                          paddingHorizontal: 25,
                          marginBottom: 150,
                        }
                      : {
                          backgroundColor: COLORS.WHITE,
                          paddingTop: 30,
                          paddingHorizontal: 20,
                        },
                  ]}
                >
                  <DatetimeInfo />
                  <ProductTable />
                  <View
                    style={[
                      {
                        backgroundColor: '#f9f2f1',
                        borderRadius: 18,
                        marginBottom: 30,
                      },
                      isDesktop()
                        ? {
                            flexDirection: 'row',
                            padding: 20,
                            flexWrap: 'wrap',
                            gap: 15,
                          }
                        : {
                            flexDirection: 'column',
                            gap: 20,
                            padding: 16,
                          },
                    ]}
                  >
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Emergency Contact</Text>
                      <Text style={styles.contentText}>
                        lorem.ipsum@gmail.com
                      </Text>
                    </View>
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Contact Number:</Text>
                      <Text style={styles.contentText}>+852 2307-1322</Text>
                    </View>
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Time of Payment</Text>
                      <Text style={styles.contentText}>11:30 AM</Text>
                    </View>
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Date of Payment</Text>
                      <Text style={styles.contentText}>03/12/2024</Text>
                    </View>
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Amount Paid</Text>
                      <Text style={styles.contentText}>$688 HKD</Text>
                    </View>
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Booking ID</Text>
                      <Text style={styles.contentText}>aBCdEfgH</Text>
                    </View>
                    <View
                      style={[
                        isDesktop() && {
                          flex: 0.333333,
                          minWidth: 200,
                        },
                      ]}
                    >
                      <Text style={styles.headerText}>Payment Method</Text>
                      <Text style={styles.contentText}>
                        VISA Credit Card **49
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginBottom: 30,
                    }}
                  >
                    <Button
                      style={[
                        styles.buttonStyle,
                        !isDesktop() && { width: '100%', paddingVertical: 30 },
                      ]}
                      className=""
                      arrowRight={false}
                      textStyle={[
                        styles.textButtonStyle,
                        !isDesktop() && { fontSize: 19 },
                      ]}
                      text="Continue to home"
                      onPress={() => {
                        NavigationService.push('Root', {
                          screen: 'MenuTab',
                          params: {
                            screen: 'HomePage',
                          },
                        });
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View
        style={{
          paddingHorizontal: 40,
          paddingTop: 20,
          backgroundColor: '#fdfdfd',
        }}
      >
        <View>
          <View
            style={{
              borderBottomColor: '#EBEBF0',
              borderBottomWidth: 1,
              paddingBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 20,
                color: COLORS.BLACK,
                textTransform: 'uppercase',
                textAlign: 'center',
                marginBottom: 10,
                letterSpacing: 2,
              }}
            >
              Thanks for your camp booking
            </Text>
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 20,
                color: COLORS.BLACK,
                textAlign: 'center',
                marginBottom: 30,
              }}
            >
              Your transaction was successful!
            </Text>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 30,
              }}
            >
              <View style={{ flexDirection: 'column' }}>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  Date of Payment
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  03/12/2024
                </Text>
              </View>
              <View style={{ flexDirection: 'column' }}>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  Order #
                </Text>
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontSize: 20,
                    fontWeight: '500',
                    color: COLORS.BLACK,
                  }}
                >
                  12345
                </Text>
              </View>
            </View>
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 20,
                textTransform: 'uppercase',
                color: COLORS.BLACK,
                letterSpacing: 2,
                marginBottom: 15,
              }}
            >
              Overnight Camp
            </Text>
          </View>
          <View
            style={[
              {
                borderRadius: 18,
                width: '100%',
              },
              isDesktop()
                ? {
                    elevation: 5,
                    shadowColor: '#F0F0F0',
                    shadowOffset: { width: 0, height: 12 },
                    shadowOpacity: 1,
                    shadowRadius: 32,
                    paddingTop: 25,
                    paddingHorizontal: 25,
                    marginBottom: 150,
                  }
                : {
                    backgroundColor: COLORS.WHITE,
                    paddingTop: 20,
                  },
            ]}
          >
            <DatetimeInfo />
            <ProductTable />
            <View
              style={[
                {
                  backgroundColor: '#f9f2f1',
                  borderRadius: 18,
                  marginBottom: 30,
                },
                isDesktop()
                  ? {
                      flexDirection: 'row',
                      padding: 20,
                      flexWrap: 'wrap',
                      gap: 15,
                    }
                  : {
                      flexDirection: 'column',
                      gap: 20,
                      padding: 16,
                    },
              ]}
            >
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Emergency Contact</Text>
                <Text style={styles.contentText}>lorem.ipsum@gmail.com</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Contact Number:</Text>
                <Text style={styles.contentText}>+852 2307-1322</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Time of Payment</Text>
                <Text style={styles.contentText}>11:30 AM</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Date of Payment</Text>
                <Text style={styles.contentText}>03/12/2024</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Amount Paid</Text>
                <Text style={styles.contentText}>$688 HKD</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Booking ID</Text>
                <Text style={styles.contentText}>aBCdEfgH</Text>
              </View>
              <View
                style={[
                  isDesktop() && {
                    flex: 0.333333,
                    minWidth: 200,
                  },
                ]}
              >
                <Text style={styles.headerText}>Payment Method</Text>
                <Text style={styles.contentText}>VISA Credit Card **49</Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 30,
              }}
            >
              <Button
                style={[
                  styles.buttonStyle,
                  !isDesktop() && { width: '100%', paddingVertical: 30 },
                ]}
                className=""
                arrowRight={false}
                textStyle={[
                  styles.textButtonStyle,
                  !isDesktop() && { fontSize: 19 },
                ]}
                text="Continue to home"
                onPress={() => {
                  NavigationService.push('Root', {
                    screen: 'MenuTab',
                    params: {
                      screen: 'HomePage',
                    },
                  });
                }}
              />
            </View>
          </View>
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default OrderConfirmation;

const styles = StyleSheet.create({
  headerText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '800',
    fontSize: 19,
    letterSpacing: 1,
    marginBottom: 15,
  },
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
  },
  buttonStyle: {
    backgroundColor: COLORS.RED_PRIMARY,
    paddingVertical: 23,
    borderRadius: 13,
    width: 320,
    marginBottom: 20,
    overflow: 'hidden',
  },
  textButtonStyle: {
    color: COLORS.WHITE,
    fontSize: 17,
    fontFamily: FONTS.AvenirMedium,
    textTransform: 'uppercase',
    fontWeight: '500',
    letterSpacing: 1,
  },
});
