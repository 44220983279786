import { FC, useCallback, useMemo, useState } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import {
  Image,
  ScrollView,
  StyleSheet,
  Switch,
  Text,
  View,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { TouchableOpacity } from 'react-native';
import { STYLE_VARS } from '../../constants/style-vars';
import PageTitle from '../../components/PageTitle';
import { Footer } from '../../components/Footer';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import { NavigationService } from '../../services/navigation';

const MyProfile: FC = () => {
  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: 1250,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="My Profile" haveGoBackButton={true} />
              <View
                style={{
                  paddingHorizontal: 25,
                  paddingTop: 25,
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 0.5,
                  shadowRadius: 32,
                  elevation: 24,
                  borderRadius: 18,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
              >
                <View
                  style={{
                    backgroundColor: COLORS.LIGHT_RED,
                    padding: 25,
                    width: '100%',
                    borderRadius: 18,
                    flexDirection: 'column',
                    marginBottom: 25,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.Arial,
                        fontWeight: '700',
                        fontSize: 18,
                        letterSpacing: 1.5,
                        textTransform: 'uppercase',
                      }}
                    >
                      My Details
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        NavigationService.push('Root', {
                          screen: 'MenuTab',
                          params: {
                            screen: 'EditProfile',
                          },
                        });
                      }}
                    >
                      <Image
                        source={require('../../assets/edit-icon.png')}
                        style={{
                          width: 22,
                          height: 22,
                          resizeMode: 'contain',
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <View
                      style={{
                        width: '50%',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ width: '40%' }}>
                        <Text style={styles.contentText}>Username:</Text>
                      </View>
                      <View style={{ width: '60%' }}>
                        <Text style={styles.contentText}>linh.cheng</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        width: '50%',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ width: '40%' }}>
                        <Text style={styles.contentText}>Password:</Text>
                      </View>
                      <View style={{ width: '60%' }}>
                        <Text style={styles.contentText}>************</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: COLORS.LIGHT_RED,
                    padding: 25,
                    width: '100%',
                    borderRadius: 18,
                    flexDirection: 'column',
                    marginBottom: 25,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.Arial,
                        fontWeight: '700',
                        fontSize: 18,
                        letterSpacing: 1.5,
                        textTransform: 'uppercase',
                      }}
                    >
                      My Profile
                    </Text>
                    <TouchableOpacity
                      onPress={() => {
                        NavigationService.push('Root', {
                          screen: 'MenuTab',
                          params: {
                            screen: 'EditProfile',
                          },
                        });
                      }}
                    >
                      <Image
                        source={require('../../assets/edit-icon.png')}
                        style={{
                          width: 22,
                          height: 22,
                          resizeMode: 'contain',
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        First Name:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Ling
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Last Name:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Cheng
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Email:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        ling.cheng@gmail.com
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Phone:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        98765432
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Mobile Phone:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        98765432
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        HKID Card No.:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        C9876**(*)
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Date of Birth:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        21/10/1987
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Disabled:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        No
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Address:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Bldg 12 Lockhart St Wan Chai
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Unit No.:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        N/A
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Floor:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        N/A
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Building Name and Number:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Bldg 12
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Street Name and Number:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Lockhart St
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        District:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Whanchai
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Region:
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Whanchai
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Emergency Contact (First Name):
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Han
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Emergency Contact (Last Name):
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        Ling
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Emergency Contact (Phone):
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        78699833
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                      <Text style={[styles.contentText, { width: '40%' }]}>
                        Emergency Contact (Email):
                      </Text>
                      <Text style={[styles.contentText, { width: '60%' }]}>
                        han.cheng@gmail.com
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'MY PROFILE'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: '#F9F1F1',
        }}
      >
        <View
          style={{
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.GREY,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontFamily: FONTS.Arial,
              fontWeight: '700',
              fontSize: 20,
              color: COLORS.BLACK,
              textTransform: 'uppercase',
            }}
          >
            LING CHENG
          </Text>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 18,
                letterSpacing: 1.5,
              }}
            >
              MY DETAILS
            </Text>
            <TouchableOpacity
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'EditProfile',
                  },
                });
              }}
            >
              <Image
                source={require('../../assets/edit-icon.png')}
                style={{
                  width: 22,
                  height: 22,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <View style={{ flexDirection: 'row', marginBottom: 8 }}>
              <Text style={[styles.contentText, { width: '40%' }]}>
                Username:
              </Text>
              <Text style={[styles.contentText, { width: '60%' }]}>
                ling.cheng
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 8 }}>
              <Text style={[styles.contentText, { width: '40%' }]}>
                Password:
              </Text>
              <Text style={[styles.contentText, { width: '60%' }]}>
                **********
              </Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 20 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                fontFamily: FONTS.Arial,
                fontWeight: '700',
                fontSize: 18,
                letterSpacing: 1.5,
              }}
            >
              MY PROFILE
            </Text>
            <TouchableOpacity
              onPress={() => {
                NavigationService.push('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'EditProfile',
                  },
                });
              }}
            >
              <Image
                source={require('../../assets/edit-icon.png')}
                style={{
                  width: 22,
                  height: 22,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                First Name:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>Ling</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Last Name:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>Cheng</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>Email:</Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                ling.cheng@gmail.com
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>Phone:</Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                98765432
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Mobile Phone:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                98765432
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                HKID Card No.:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                C9876**(*)
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Date of Birth:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                21/10/1987
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Disabled:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>No</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Address:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                Bldg 12 Lockhart St Wan Chai
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Unit No.:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>N/A</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>Floor:</Text>
              <Text style={[styles.contentText, { width: '62%' }]}>N/A</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Building Name and Number:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                Bldg 12
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Street Name and Number:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                Lockhart St
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                District:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                Whanchai
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Region:
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                Whanchai
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Emergency Contact (First Name):
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>Han</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Emergency Contact (Last Name):
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>Ling</Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Emergency Contact (Phone):
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                78699833
              </Text>
            </View>
            <View style={{ flexDirection: 'row', marginBottom: 15 }}>
              <Text style={[styles.contentText, { width: '38%' }]}>
                Emergency Contact (Email):
              </Text>
              <Text style={[styles.contentText, { width: '62%' }]}>
                han.cheng@gmail.com
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default MyProfile;

const styles = StyleSheet.create({
  headerText: {
    fontFamily: FONTS.Arial,
    fontWeight: '600',
    fontSize: 19,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    borderBottomWidth: 1,
    paddingBottom: 15,
    marginBottom: 12,
  },
  contentText: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    fontSize: 16,
    color: COLORS.GREY,
    paddingRight: 5,
  },
});
