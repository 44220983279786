import { FC, useMemo } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { Image, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { TextInput } from '../../components/TextInput/TextInput';
import { Button } from '../../components/Button';
import { Footer } from '../../components/Footer';
import { NavigationService } from '../../services/navigation';

const Messages: FC = () => {
  const isDesktop = useIsDesktop();

  const renderMessagesList = useMemo(() => {
    const messages = [
      {
        id: 1,
        avatar: require('../../assets/users/image-1.png'),
        name: 'Jane',
        lastestMessage: 'Hello are you there?',
        lastOnline: '5mins',
        unread: 2,
      },
      {
        id: 2,
        avatar: require('../../assets/users/image-2.png'),
        name: 'Leslie🦋',
        lastestMessage: 'Yes, i will be available tomorrow...',
        lastOnline: '3:00PM',
        unread: 3,
      },
      {
        id: 3,
        avatar: require('../../assets/users/image-3.png'),
        name: 'Dianne😍',
        lastestMessage: 'Nice performance today dear!',
        lastOnline: '1:35PM',
      },
      {
        id: 4,
        avatar: require('../../assets/users/image-4.png'),
        name: 'Hawkins',
        lastestMessage: 'Can we talk now?',
        lastOnline: 'Yesterday',
      },
      {
        id: 5,
        avatar: require('../../assets/users/image-5.png'),
        name: 'Williamson',
        lastestMessage: 'It’s my pleasure',
        lastOnline: 'Monday',
      },
    ];

    return (
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        {messages.map((item, index) => {
          return (
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                paddingLeft: 20,
                paddingVertical: 20,
                borderBottomWidth: 1,
                borderBottomColor: '#BCBCBC2E',
                justifyContent: 'space-between',
                paddingRight: 20,
                alignItems: 'center',
              }}
              onPress={() => {
                NavigationService.push('MessagesUser');
              }}
            >
              <View
                style={{
                  width: isDesktop() ? '50%' : '70%',
                  flexDirection: 'row',
                  gap: 15,
                  alignItems: 'center',
                }}
              >
                <Image
                  source={item.avatar}
                  style={{
                    width: 55,
                    height: 55,
                    borderRadius: 55,
                  }}
                />
                <View
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '700',
                      fontSize: 19,
                      color: COLORS.BLACK,
                      letterSpacing: 1,
                    }}
                  >
                    {item.name}
                  </Text>
                  <View style={{ width: isDesktop() ? 350 : 200 }}>
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '500',
                        fontSize: 16,
                        color: '#A5A5A5',
                      }}
                      numberOfLines={1}
                    >
                      {item.lastestMessage}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '500',
                    fontSize: 16,
                    color: '#A5A5A5',
                    marginBottom: 5,
                  }}
                >
                  {item.lastOnline}
                </Text>
                {item.unread && (
                  <View
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      backgroundColor: COLORS.RED_PRIMARY,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: FONTS.AvenirMedium,
                        fontWeight: '600',
                        fontSize: 12,
                        color: COLORS.WHITE,
                      }}
                    >
                      {item.unread}
                    </Text>
                  </View>
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }, [isDesktop]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 200,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="YOUR MESSAGES" haveGoBackButton={true} />
              <View
                style={{
                  flexDirection: 'column',
                  backgroundColor: COLORS.WHITE,
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  borderRadius: 18,
                  padding: 20,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.Arial,
                    fontWeight: '700',
                    fontSize: 20,
                    letterSpacing: 2,
                    textTransform: 'uppercase',
                    marginBottom: 15,
                  }}
                >
                  Chats
                </Text>
                <View
                  style={{
                    position: 'relative',
                    marginBottom: 20,
                  }}
                >
                  <TextInput
                    placeholder="Search"
                    placeholderTextColor={'#888888'}
                    style={{
                      borderRadius: 5,
                      backgroundColor: '#F0F0F0',
                      paddingLeft: 45,
                      paddingVertical: 10,
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 16,
                    }}
                  />
                  <Image
                    source={require('../../assets/search_white.png')}
                    style={{
                      width: 18,
                      height: 18,
                      position: 'absolute',
                      tintColor: '#888888',
                      top: 11,
                      left: 20,
                    }}
                  />
                </View>
                <View
                  style={{
                    marginBottom: 40,
                  }}
                >
                  {renderMessagesList}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginBottom: 30,
                  }}
                >
                  <Button
                    arrowRight={false}
                    text="MESSAGE HELPING HAND SUPPORT STAFF"
                    style={{
                      paddingVertical: 13,
                      paddingHorizontal: 120,
                      backgroundColor: COLORS.LIGHT_RED,
                      borderRadius: 16,
                    }}
                    className=""
                    textStyle={{
                      fontFamily: FONTS.AvenirMedium,
                      fontWeight: '500',
                      fontSize: 12,
                      color: COLORS.RED_PRIMARY,
                      letterSpacing: 1.8,
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'YOUR MESSAGES'} haveGoBackButton={true} />
      <View style={{ paddingTop: 20, paddingHorizontal: 20 }}>
        <Text
          style={{
            fontFamily: FONTS.Arial,
            fontWeight: '700',
            fontSize: 20,
            letterSpacing: 2,
            textTransform: 'uppercase',
            marginBottom: 15,
          }}
        >
          Chats
        </Text>
        <View
          style={{
            position: 'relative',
          }}
        >
          <TextInput
            placeholder="Search"
            placeholderTextColor={'#888888'}
            style={{
              borderRadius: 5,
              backgroundColor: '#F0F0F0',
              paddingLeft: 45,
              height: 46,
              fontFamily: FONTS.AvenirMedium,
              fontWeight: '500',
              fontSize: 16,
            }}
          />
          <Image
            source={require('../../assets/search_white.png')}
            style={{
              width: 18,
              height: 18,
              position: 'absolute',
              tintColor: '#888888',
              top: 13,
              left: 20,
            }}
          />
        </View>
      </View>
      <View
        style={{
          marginBottom: 20,
        }}
      >
        {renderMessagesList}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: 30,
        }}
      >
        <Button
          arrowRight={false}
          text="MESSAGE HELPING HAND SUPPORT STAFF"
          style={{
            paddingVertical: 13,
            paddingHorizontal: 30,
            backgroundColor: COLORS.LIGHT_RED,
            borderRadius: 16,
          }}
          className=""
          textStyle={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '500',
            fontSize: 11,
            color: COLORS.RED_PRIMARY,
            letterSpacing: 1.8,
          }}
        />
      </View>
      <Footer />
    </ScrollView>
  );
};

export default Messages;
