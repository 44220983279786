import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/redux/store';
import { STYLE_VARS } from '../../constants/style-vars';
import { Image } from '../Image';
import { FONTS } from '../../constants';
import { NavigationService } from '../../services/navigation';

const UserWebInfo: FC = () => {
  const { info } = useSelector((redux: RootState) => redux.authReducer);
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <TouchableOpacity
        onPress={() => {
          NavigationService.push('Root', {
            screen: 'MyAccountTab',
          });
        }}
      >
        <Image
          source={
            info?.avatar
              ? { uri: info?.avatar }
              : require('../../assets/avatar.png')
          }
          style={{ width: 80, height: 80, borderRadius: 100 }}
        />
      </TouchableOpacity>

      <View style={{ paddingLeft: 10 }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 4,
          }}
        >
          <View
            style={{
              width: 20,
              height: 1,
              backgroundColor: STYLE_VARS.primaryColor,
            }}
          />
          <Text
            style={{
              paddingHorizontal: 2,
              fontFamily: FONTS.IowanOldStyleBTRoman,
              color: STYLE_VARS.primaryColor,
            }}
          >
            WELCOME
          </Text>
          <View
            style={{
              width: 20,
              height: 1,
              backgroundColor: STYLE_VARS.primaryColor,
            }}
          />
        </View>

        <Text
          style={{
            fontFamily: FONTS.IowanOldStyleBTRoman,
            color: STYLE_VARS.primaryColor,
            fontSize: 16,
            marginBottom: 2,
          }}
        >
          {info?.display_name && info.display_name.length > 0
            ? info.display_name.toUpperCase()
            : info?.user_login.toUpperCase()}
        </Text>
        <Text
          style={{
            fontFamily: FONTS.IowanOldStyleBTRoman,
            color: STYLE_VARS.primaryColor,
            fontSize: 16,
            marginBottom: 4,
          }}
        >
          #{info?.mbnum}
        </Text>
        <View
          style={{
            backgroundColor: STYLE_VARS.primaryColor,
            padding: 3,
            borderRadius: 5,
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              color: 'white',
              fontFamily: FONTS.IowanOldStyleBTRoman,
              fontStyle: 'italic',
              fontSize: 12,
              textTransform: 'uppercase',
            }}
          >
            {info?.member_type}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default UserWebInfo;

const styles = StyleSheet.create({});
