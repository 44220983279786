import { FC, useMemo, useState } from 'react';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { ScrollView, Text } from 'react-native';
import { View } from 'react-native';
import { MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { COLORS, FONTS } from '../../constants';
import { Footer } from '../../components/Footer';
import Checkbox from '../../components/Checkbox/Checkbox';
import { Button } from '../../components/Button';

const DeleteAccount: FC = () => {
  const isDesktop = useIsDesktop();
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const renderContent = useMemo(() => {
    return (
      <View>
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            color: COLORS.LIGHT_BLACK,
            marginBottom: 15,
          }}
        >
          Delete Account
        </Text>
        <Text
          style={{
            fontFamily: FONTS.AvenirRegular,
            fontWeight: '500',
            fontSize: 16,
            color: COLORS.GREY,
            marginBottom: 15,
          }}
        >
          Are you sure you want to delete the account linked to
          Ling.cheng@gmail.com?
        </Text>
        <View style={{ marginBottom: 20 }}>
          <Checkbox
            label={"I understand that I won't be able to recover my account."}
            isChecked={isAgree}
            onChange={() => {
              setIsAgree(!isAgree);
            }}
            checkBoxContainerStyle={{
              alignItems: 'flex-start',
            }}
          />
        </View>
        <Button
          style={{
            backgroundColor: COLORS.RED_PRIMARY,
            paddingVertical: 12,
            borderRadius: 13,
            width: isDesktop() ? 280 : '100%',
            marginBottom: 40,
            overflow: 'hidden',
          }}
          className=""
          textStyle={{
            color: COLORS.WHITE,
            fontSize: 19,
            fontWeight: '800',
            fontFamily: FONTS.AvenirMedium,
            textTransform: 'uppercase',
          }}
          text="Delete"
          arrowRight={false}
        />
      </View>
    );
  }, [isAgree, isDesktop]);

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Delete My Account" haveGoBackButton />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  paddingBottom: 5,
                  height: 650,
                }}
              >
                {renderContent}
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'Delete My Account'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: COLORS.LIGHT_RED,
          height: 800,
        }}
      >
        {renderContent}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default DeleteAccount;
