import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPromotions } from './ActionCreators';
import { PAGE_ITEMS } from '../../../constants';
import { IPromotion } from '../../../types/promotions.types';

export type PromotionFilter = {
  page?: number;
  limit?: number;
  search?: string;
  cats?: string;
};

interface PromotionState {
  promotions: IPromotion[];
  isLoading: boolean;
  error: string;
  promotionFilter: PromotionFilter;
  total: number;
}

const initialState: PromotionState = {
  promotions: [],
  isLoading: false,
  error: '',
  promotionFilter: {
    page: 1,
    limit: PAGE_ITEMS,
    cats: '',
  },
  total: 0,
};

export const promotionSlice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    setPromotionFilter: (state, action: PayloadAction<PromotionFilter>) => {
      state.promotionFilter = action.payload;
    },
    setPromotions: (state, action: PayloadAction<IPromotion[]>) => {
      state.promotions = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoadingPromotion: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearPromotionFilter: (state, action: PayloadAction) => {
      state.promotions = [];
      state.total = 0;
      state.promotionFilter = {
        page: 1,
        limit: PAGE_ITEMS,
        cats: '',
      };
    },
  },
  extraReducers: {
    [fetchPromotions.fulfilled.type]: (
      state,
      action: PayloadAction<IPromotion[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.promotions = action.payload;
    },
    [fetchPromotions.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchPromotions.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setPromotionFilter,
  clearPromotionFilter,
  setPromotions,
  setTotal,
  setLoadingPromotion,
} = promotionSlice.actions;
export default promotionSlice.reducer;
