import { FC, useEffect, useMemo, useState } from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Modal,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';
import { Button } from '../../components/Button';
import { STYLE_VARS } from '../../constants/style-vars';
import { IS_IOS, IS_WEB, MAX_WIDTH } from '../../constants/platform';
import MyAccountMenu from '../../components/MyAccountMenu/MyAccountMenu';
import PageTitle from '../../components/PageTitle';
import { useIsDesktop } from '../../hooks/useIsDeskTop';
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils';
import { addBase64Prefix } from '../../services/addBase64Prefix';
import { close_icon } from '../../assets/base64';
import DropDown from '../../components/DropDown';
import { Footer } from '../../components/Footer';

export type LanguageDropDownItem = {
  img: any;
  label: string;
  value: string | number;
};

interface LanguageDropDownProps extends ViewProps {
  placeholder?: string;
  title?: string;
  items: LanguageDropDownItem[];
  setValues?: (value: LanguageDropDownItem) => void;
  initValue?: LanguageDropDownItem;
}

const LanguageDropDown: FC<LanguageDropDownProps> = ({
  placeholder,
  title,
  items,
  setValues,
  initValue,
}) => {
  const isDesktop = useIsDesktop();
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<LanguageDropDownItem>();

  useEffect(() => {
    if (initValue) {
      setValue(initValue);
    }
  }, [setValue, initValue]);

  const renderDropDown = useMemo(() => {
    return (
      <TouchableOpacity
        style={[styles.dropdown]}
        onPress={() => {
          setOpen(!open);
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {value && (
            <Image
              source={value.img}
              style={{
                width: 24,
                height: 24,
                marginRight: 10,
                borderRadius: 24,
              }}
            />
          )}

          <View style={{ marginRight: 10 }}>
            <Text style={[styles.textStyled]}>
              {value?.label || placeholder}
            </Text>
          </View>
        </View>
        <Image
          source={
            open == true
              ? require('../../assets/up-arrow.png')
              : require('../../assets/down-arrow.png')
          }
          style={[
            {
              width: 15,
              height: 8,
            },
          ]}
        />
      </TouchableOpacity>
    );
  }, [open, placeholder, value]);

  const renderPicker = useMemo(() => {
    if (isDesktop()) {
      if (open) {
        return (
          <View style={[styles.dropDownContainer]}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              {items.map((item) => (
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={item.label}
                  onPress={() => {
                    setValue(item);
                    if (setValues) setValues(item);
                    setOpen(false);
                  }}
                >
                  <Image
                    source={item.img}
                    style={{
                      width: 24,
                      height: 24,
                      marginRight: 10,
                      borderRadius: 24,
                    }}
                  />
                  <Text style={[styles.textStyled]}>{item.label}</Text>
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        );
      }
    } else {
      return (
        <Modal animationType="slide" visible={open}>
          <View
            style={[styles.headerModalWrapper, IS_IOS && { paddingTop: 60 }]}
          >
            <Text style={[styles.textStyled, { fontSize: 20 }]}>{title}</Text>
            <TouchableOpacity
              onPress={() => {
                setOpen(false);
              }}
            >
              <Image
                source={
                  IS_WEB
                    ? addBase64Prefix(close_icon)
                    : require('../../assets/close-icon.png')
                }
                style={{
                  width: 20,
                  height: 20,
                  tintColor: COLORS.RED_PRIMARY,
                }}
              />
            </TouchableOpacity>
          </View>
          <ScrollView
            contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
          >
            {items.map((item) => (
              <TouchableOpacity
                style={styles.itemWrapper}
                key={item.label}
                onPress={() => {
                  setValue(item);
                  if (setValues) setValues(item);
                  setOpen(false);
                }}
              >
                <Text style={styles.textStyled}>{item.label}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </Modal>
      );
    }
  }, [open, items, isDesktop, title, setValues]);

  return (
    <View>
      {renderDropDown}
      {renderPicker}
    </View>
  );
};

const ChangeLanguage: FC = () => {
  const isDesktop = useIsDesktop();

  if (isDesktop()) {
    return (
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            maxWidth: MAX_WIDTH,
            width: '90%',
            marginHorizontal: 'auto',
            paddingTop: 40,
            marginBottom: 40,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <View style={{ width: '22%', minWidth: 230, marginRight: 20 }}>
              <MyAccountMenu />
            </View>
            <View style={{ width: '70%' }}>
              <PageTitle title="Language" haveGoBackButton />
              <View
                style={{
                  paddingHorizontal: 40,
                  paddingTop: 40,
                  backgroundColor: COLORS.LIGHT_RED,
                  borderRadius: 18,
                  flexDirection: 'column',
                  shadowColor: '#F0F0F0',
                  shadowOffset: { width: 0, height: 12 },
                  shadowOpacity: 1,
                  shadowRadius: 32,
                  elevation: 20,
                  paddingBottom: 5,
                  height: 650,
                }}
              >
                <Text
                  style={{
                    fontFamily: FONTS.AvenirMedium,
                    fontWeight: '800',
                    fontSize: 19,
                    color: COLORS.LIGHT_BLACK,
                    marginBottom: 20,
                  }}
                >
                  Select your preferred laguage:
                </Text>
                <View style={{ marginBottom: 30, zIndex: 2 }}>
                  <LanguageDropDown
                    items={[
                      {
                        img: require('../../assets/language/english.png'),
                        label: 'Engligh',
                        value: 'english',
                      },
                    ]}
                    initValue={{
                      img: require('../../assets/language/english.png'),
                      label: 'Engligh',
                      value: 'english',
                    }}
                  />
                </View>
                <View
                  style={{ flexDirection: 'row', justifyContent: 'center' }}
                >
                  <Button
                    style={{
                      backgroundColor: COLORS.RED_PRIMARY,
                      paddingVertical: 12,
                      borderRadius: 13,
                      width: 280,
                      marginBottom: 40,
                      overflow: 'hidden',
                    }}
                    className=""
                    textStyle={{
                      color: COLORS.WHITE,
                      fontSize: 19,
                      fontWeight: '800',
                      fontFamily: FONTS.AvenirMedium,
                      textTransform: 'uppercase',
                    }}
                    text="Save"
                    arrowRight={false}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <PageTitle title={'LANGUAGE'} haveGoBackButton={true} />
      <View
        style={{
          paddingHorizontal: 30,
          paddingVertical: 30,
          backgroundColor: COLORS.LIGHT_RED,
          height: 800,
        }}
      >
        <Text
          style={{
            fontFamily: FONTS.AvenirMedium,
            fontWeight: '800',
            fontSize: 19,
            color: COLORS.LIGHT_BLACK,
            marginBottom: 20,
          }}
        >
          Select your preferred laguage:
        </Text>
        <View style={{ marginBottom: 30, zIndex: 2 }}>
          <LanguageDropDown
            items={[
              {
                img: require('../../assets/language/english.png'),
                label: 'Engligh',
                value: 'english',
              },
            ]}
            initValue={{
              img: require('../../assets/language/english.png'),
              label: 'Engligh',
              value: 'english',
            }}
            title="Select language"
          />
        </View>
        <Button
          style={{
            backgroundColor: COLORS.RED_PRIMARY,
            paddingVertical: 12,
            borderRadius: 13,
            width: '100%',
            marginBottom: 40,
            overflow: 'hidden',
          }}
          className=""
          textStyle={{
            color: COLORS.WHITE,
            fontSize: 19,
            fontWeight: '800',
            fontFamily: FONTS.AvenirMedium,
            textTransform: 'uppercase',
          }}
          text="Save"
          arrowRight={false}
        />
      </View>
      <Footer />
    </ScrollView>
  );
};

export default ChangeLanguage;

export const styles = StyleSheet.create({
  dropdown: {
    backgroundColor: 'white',
    flexDirection: 'row',
    borderColor: COLORS.LGREY,
    borderWidth: 1,
    borderRadius: 6,
    paddingTop: 12,
    paddingBottom: 12,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  textStyled: {
    color: COLORS.GREY,
    fontFamily: FONTS.AvenirMedium,
    fontSize: 14,
    fontWeight: '500',
  },
  dropDownContainer: {
    flex: 1,
    position: 'absolute',
    top: 50,
    maxHeight: 150,
    backgroundColor: 'white',
    minHeight: 40,
    width: '100%',
    borderWidth: 1,
    borderColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  headerModalWrapper: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginBottom: 20,
    borderBottomColor: COLORS.DARK_GREY,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemWrapper: {
    paddingVertical: 20,
    borderBottomColor: COLORS.DARK_GREY,
    borderBottomWidth: 0.5,
  },
});
