import {
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { FC, LegacyRef, RefObject } from 'react';
import { styles } from './EShopHeader.styled';
import { STYLE_VARS } from '../../../../constants/style-vars';
import { NavigationService } from '../../../../services/navigation';

interface EShopHeaderProps {
  handleSearch?: (text: string) => void;
  searchRef?: RefObject<TextInput>;
  isHaveSearch?: boolean;
}

const EShopHeader: FC<EShopHeaderProps> = ({
  handleSearch,
  searchRef,
  isHaveSearch = true,
}) => {
  return (
    <View style={styles.wrapper}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TouchableOpacity
          style={{
            padding: 5,
            paddingRight: 20,
          }}
          onPress={() => {
            NavigationService.pop();
          }}
        >
          <Image
            source={require('../../../../assets/left-arrow.png')}
            style={{
              height: 14,
              width: 10,
              resizeMode: 'contain',
              tintColor: STYLE_VARS.primaryColor,
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity style={styles.container} onPress={() => {}}>
          <Image
            source={require('../../../../assets/shopping-cart.png')}
            style={styles.headerCart}
          />
          <Text style={styles.textCart}>E-Shop</Text>
        </TouchableOpacity>
      </View>
      {isHaveSearch && (
        <View
          style={[
            styles.container,
            {
              width: '40%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            },
          ]}
        >
          <Image
            source={require('../../../../assets/search_white.png')}
            style={styles.searchIcon}
          />
          <View style={styles.inputSearchWrapper}>
            <TextInput
              placeholder="SEARCH"
              placeholderTextColor={STYLE_VARS.primaryColor}
              style={styles.searchText}
              onChangeText={(text) => {
                if (handleSearch) {
                  handleSearch(text);
                }
              }}
              ref={searchRef}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default EShopHeader;
