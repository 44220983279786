import React, { FC } from 'react';
import { useIsDesktop } from '../hooks/useIsDeskTop';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { RootStackParamList, RootTabParamList } from './types';
import { STYLE_VARS } from '../constants/style-vars';
import { Image } from '../components/Image';
import { MyBookings, MyAccount, Home, BookingList } from '../screens';
import { IS_WEB } from '../constants/platform';
import { addBase64Prefix } from '../services/addBase64Prefix';
import { orders_white, account_white } from '../assets/base64';
import { Text, View } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import MenuStack from './menu-stack';
import { useSelector } from 'react-redux';
import { RootState } from '../services/redux';
import { COLORS, FONTS } from '../constants';
import Messages from '../screens/Messages/Messages';
import MyActivities from '../screens/MyActivities/MyActivities';

const BottomTab = createBottomTabNavigator<RootTabParamList>();

interface TabItemProps {
  props: {
    focused: boolean;
    color: string;
    size: number;
  };
  icon: any;
}

const TabItem: FC<TabItemProps> = ({ props, icon }) => {
  return (
    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Image
        source={icon}
        style={{ width: 28, height: 28, resizeMode: 'contain' }}
      />
    </View>
  );
};

type BottomTabsNavigationProp = NativeStackNavigationProp<
  RootStackParamList,
  'Root'
>;

export interface MyBookingsProps {
  navigation: BottomTabsNavigationProp;
}

const BottomTabs: FC<MyBookingsProps> = ({ navigation }) => {
  const isDesktop = useIsDesktop();
  return (
    <BottomTab.Navigator
      sceneContainerStyle={[
        {
          backgroundColor: 'white',
        },
      ]}
      screenOptions={{
        tabBarStyle: {
          backgroundColor: COLORS.RED_PRIMARY,
          display: isDesktop() ? 'none' : 'flex',
          height: 90,
          paddingBottom: 10,
          shadowOffset: { width: 0, height: -4 },
          shadowOpacity: 0.6,
          shadowRadius: 6,
          shadowColor: '#000000',
          elevation: 3,
        },
      }}
      initialRouteName="MyActivities"
    >
      <BottomTab.Screen
        name="Messages"
        component={Messages}
        options={{
          tabBarInactiveTintColor: 'white',
          tabBarActiveTintColor: 'white',
          tabBarLabel: (props) => (
            <Text
              style={{
                fontFamily: FONTS.AvenirMedium,
                fontWeight: '500',
                fontSize: 12,
                color: COLORS.WHITE,
                textAlign: 'center',
              }}
            >
              {'MESSAGES'}
            </Text>
          ),
          headerShown: false,
          tabBarIcon: (props) => (
            <TabItem
              props={props}
              icon={require('../assets/messages-icon.png')}
            />
          ),
        }}
      />
      <>
        <BottomTab.Screen
          name="MyActivities"
          component={MyActivities}
          options={{
            tabBarInactiveTintColor: 'white',
            tabBarActiveTintColor: 'white',
            tabBarLabel: (props) => (
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 12,
                  color: COLORS.WHITE,
                  textAlign: 'center',
                }}
              >
                {'ACTIVITIES'}
              </Text>
            ),
            headerShown: false,
            tabBarIcon: (props) => (
              <TabItem
                props={props}
                icon={require('../assets/activities-icon.png')}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name="BookingList"
          component={BookingList}
          options={{
            tabBarInactiveTintColor: 'white',
            tabBarActiveTintColor: 'white',
            tabBarLabel: (props) => (
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 12,
                  color: COLORS.WHITE,
                  textAlign: 'center',
                }}
              >
                {'MAKE BOOKING'}
              </Text>
            ),
            headerShown: false,
            tabBarIcon: (props) => (
              <View
                style={{
                  position: 'relative',
                }}
              >
                <View
                  style={{
                    backgroundColor: COLORS.WHITE,
                    padding: 20,
                    borderRadius: 50,
                    marginTop: -40,
                    shadowOffset: { width: 0, height: -4 },
                    shadowOpacity: 0.6,
                    shadowRadius: 6,
                    shadowColor: '#000000',
                    elevation: 3,
                  }}
                >
                  <Image
                    source={require('../assets/events.png')}
                    style={{
                      width: 40,
                      height: 40,
                      resizeMode: 'contain',
                      tintColor: COLORS.RED_PRIMARY,
                    }}
                  />
                </View>
              </View>
            ),
          }}
        />
        <BottomTab.Screen
          name="MyAccountTab"
          component={MyAccount}
          options={{
            tabBarInactiveTintColor: 'white',
            tabBarActiveTintColor: 'white',
            tabBarLabel: (props) => (
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 12,
                  color: COLORS.WHITE,
                  textAlign: 'center',
                }}
              >
                {'MY \nACCOUNT'}
              </Text>
            ),
            headerShown: false,
            tabBarIcon: (props) => (
              <TabItem
                props={props}
                icon={require('../assets/account-icon.png')}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name="MenuTab"
          component={MenuStack}
          listeners={{
            tabPress: () => {
              if (navigation.canGoBack()) {
                navigation.popToTop();
                navigation.navigate('Root', {
                  screen: 'MenuTab',
                  params: {
                    screen: 'Menu',
                  },
                });
              }
            },
          }}
          options={{
            tabBarInactiveTintColor: 'white',
            tabBarActiveTintColor: 'white',
            tabBarLabel: (props) => (
              <Text
                style={{
                  fontFamily: FONTS.AvenirMedium,
                  fontWeight: '500',
                  fontSize: 12,
                  color: COLORS.WHITE,
                  textAlign: 'center',
                }}
              >
                {'MENU'}
              </Text>
            ),
            headerShown: false,
            tabBarIcon: (props) => (
              <TabItem
                props={props}
                icon={require('../assets/menu-icon.png')}
              />
            ),
          }}
        />
      </>
    </BottomTab.Navigator>
  );
};

export default BottomTabs;
