import { get, isFunction, isString } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  GestureResponderEvent,
  Image as NativeImage,
  ImageProps,
  Pressable,
  StyleSheet,
} from 'react-native';

const isAutoHeight = (width?: string | number, height?: string | number) =>
  !!width && height === 'auto';

export interface CustomImageProps extends ImageProps {
  onPress?: (event: GestureResponderEvent) => void;
}

export const Image: FC<CustomImageProps> = ({
  source,
  style = {},
  onPress,
  ...props
}) => {
  const [aspectRatio, setAspectRatio] = useState<number>();

  const flattenStyle = useMemo(() => StyleSheet.flatten(style), [style]);

  // image source could be base64 data uri
  const uri = useMemo(
    () => get(source, 'uri', isString(source) ? source : null),
    [source]
  );
  const fixed = !isAutoHeight(flattenStyle.width, flattenStyle.height);

  const imageStyle = useMemo(
    () =>
      fixed
        ? flattenStyle
        : {
            ...flattenStyle,
            aspectRatio,
          },
    [fixed, flattenStyle, aspectRatio]
  );

  useEffect(() => {
    const onImageSize = (width: number, height: number) =>
      setAspectRatio(width / height);

    if (!uri || fixed) {
      return;
    }

    NativeImage.getSize(uri, onImageSize);
  }, [uri, fixed]);

  if (!aspectRatio && !fixed) {
    return null;
  }

  return (
    <Pressable disabled={!isFunction(onPress)} onPress={onPress}>
      <NativeImage
        alt="GoodDollar"
        {...props}
        source={source}
        style={imageStyle}
      />
    </Pressable>
  );
};
