import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchNotices } from './ActionCreators';
import { PAGE_ITEMS } from '../../../constants';
import { INotice } from '../../../types/notices.types';

export type NoticeFilter = {
  page?: number;
  limit?: number;
  search?: string;
  cats?: string;
  type?: 'new' | 'past' | 'all';
};
interface NoticeState {
  noticesData: INotice[];
  noticeFilter: NoticeFilter;
  isLoading: boolean;
  error: string;
  total: number;
}

const initialState: NoticeState = {
  noticesData: [],
  isLoading: false,
  error: '',
  noticeFilter: {
    page: 1,
    limit: PAGE_ITEMS,
    cats: '',
  },
  total: 0,
};

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    setNoticeFilter: (state, action: PayloadAction<NoticeFilter>) => {
      state.noticeFilter = action.payload;
    },
    setNotices: (state, action: PayloadAction<INotice[]>) => {
      state.noticesData = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setLoadingNotice: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    clearNoticeFilter: (state, action: PayloadAction) => {
      state.noticesData = [];
      state.total = 0;
      state.noticeFilter = {
        page: 1,
        limit: PAGE_ITEMS,
        cats: '',
      };
    },
  },
  extraReducers: {
    [fetchNotices.fulfilled.type]: (
      state,
      action: PayloadAction<INotice[]>
    ) => {
      state.isLoading = false;
      state.error = '';
      state.noticesData = action.payload;
    },
    [fetchNotices.pending.type]: (state) => {
      state.isLoading = true;
    },
    [fetchNotices.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setNoticeFilter,
  clearNoticeFilter,
  setNotices,
  setTotal,
  setLoadingNotice,
} = noticeSlice.actions;

export default noticeSlice.reducer;
