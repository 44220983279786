import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';
import { COLORS, FONTS } from '../../constants';

interface CheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  textStyle?: TextStyle;
  checkBoxContainerStyle?: ViewStyle;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  isChecked,
  onChange,
  textStyle,
  checkBoxContainerStyle,
}) => {
  const toggleCheckbox = () => {
    onChange(!isChecked);
  };

  return (
    <TouchableOpacity
      style={[styles.checkboxContainer, checkBoxContainerStyle]}
      onPress={toggleCheckbox}
    >
      <View style={[styles.checkbox, isChecked && styles.checkedCheckbox]}>
        {isChecked && <Text style={styles.checkIcon}>✓</Text>}
      </View>
      <Text style={[styles.label, textStyle]}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  checkbox: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderColor: '#DDDDE3',
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.LIGHT_GREY,
  },
  checkedCheckbox: {
    backgroundColor: COLORS.RED_PRIMARY,
  },
  checkIcon: {
    color: '#fff',
  },
  label: {
    fontFamily: FONTS.AvenirMedium,
    fontWeight: '500',
    color: COLORS.GREY,
    marginLeft: 12,
    fontSize: 16,
  },
});

export default Checkbox;
